import * as React from 'react'
function SvgPlayVideo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 18.25V5.698a1 1 0 011.485-.874l10.984 6.086a1 1 0 01.023 1.737L6.507 19.113A1 1 0 015 18.25z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlayVideo
