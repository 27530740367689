import * as React from 'react'
function SvgProblemLink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.375 3.5c2.5 1.667 3.75 4.167 3.75 7.5C19.375 11 20 10.167 20 8.5c.833.833 1.25 2.292 1.25 4.375 0 3.091-1.835 5.571-5.504 5.624a6.35 6.35 0 00.504-.061c1.25-.417 1.875-1.646 1.875-3.688-1.974 1.184-3.012.125-3.116-3.179L15 11.195 15 11c-2.035 1.69-2.44 3.987-1.214 6.892.211.28.521.465.93.556-2.342-.28-3.813-1.65-4.41-3.515l2.567-3.267-1.677-2.134c.126-.157.257-.31.39-.46l.238-.26.485-.52.242-.265c.96-1.07 1.824-2.305 1.824-4.527zm-5.582 7.812L6.334 8.854l.707-.708 3.666 3.666-3.666 3.666-.707-.707 2.459-2.459H2v-1h6.793z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgProblemLink
