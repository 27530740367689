import * as React from 'react'
function SvgStrikethrough() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.773 11.5h5.805c-.21-.14-.46-.273-.742-.406a11.316 11.316 0 00-1.352-.516c-1.109-.32-1.836-.656-2.18-1.008-.343-.351-.515-.71-.515-1.093 0-.454.172-.836.524-1.141.351-.305.828-.461 1.421-.461.625 0 1.18.234 1.641.71.266.282.54.813.805 1.603l.11.015.655.047.094-.023c.024-.125.04-.243.04-.352 0-.336-.04-.813-.11-1.43a8.006 8.006 0 00-.164-.922 11.34 11.34 0 00-1.383-.375C12.844 6.055 12.414 6 12.117 6c-1.351 0-2.375.328-3.07.992C8.352 7.656 8 8.453 8 9.406c0 .469.125.961.375 1.469.117.227.25.438.398.625zm9.977.5H5.25c-.14 0-.25.11-.25.25v.5c0 .14.11.25.25.25h13.5c.14 0 .25-.11.25-.25v-.5c0-.14-.11-.25-.25-.25zm-4.945 2.055c-.172-.149-.532-.336-1.07-.555h3.21c.04.219.055.46.055.719 0 .562-.101 1.117-.32 1.656-.117.29-.297.555-.555.813a5.56 5.56 0 01-.852.632c-.39.235-.78.414-1.195.516-.414.11-.937.164-1.586.164-.43 0-.945-.016-1.523-.18l-1.094-.312c-.305-.086-.484-.156-.563-.219a.235.235 0 01-.062-.172v-.101-.047c.003-.137.011-.528-.016-1.172-.01-.265 0-.476.009-.633.004-.075.007-.137.007-.187v-.344l.797-.016c.136.313.238.567.316.763.088.22.146.366.192.44.187.305.398.547.625.735.226.187.5.336.82.445.313.117.664.172 1.031.172.328 0 .696-.07 1.086-.211.399-.133.719-.36.953-.672.242-.312.367-.648.367-1.008 0-.437-.21-.844-.632-1.226z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgStrikethrough
