import placeholderPrefixer from 'fela-plugin-placeholder-prefixer'
import { createRenderer, TEnhancer, TPlugin } from 'fela'
import fallbackValue from 'fela-plugin-fallback-value'
import monolithic from 'fela-monolithic'
import unit from 'fela-plugin-unit'
import customProperty from 'fela-plugin-custom-property'
import typescript from 'fela-plugin-typescript'
import createIdentifier from 'fela-identifier'
import { isUnitlessProperty as defaultIsUnitlessProperty } from 'css-in-js-utils'
import focusSelector from '@packages/common/src/themes/focus-selector'

const resetStyles = `
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  textarea {
    overflow: auto;
    resize: none;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  [role="button"] {
    cursor: pointer;
    outline: none;
  }
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  button:disabled {
    pointer-events: none
  }

  :focus {
    outline: none;
  }

  ${focusSelector}:not([tabindex="-1"]) {
    box-shadow: 0 0 0 1px var(--focus-focus-base-outline-1),
      0 0 0 5px var(--focus-focus-base-outline-2)
  }

  input {
    /* fix ios */
    -webkit-appearance: none;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

const isUnitlessProperty = (prop: string) =>
  prop.startsWith('--') || defaultIsUnitlessProperty(prop)

export type { IRenderer as Renderer } from 'fela'

export const identifier = createIdentifier()

const configureRenderer = () => {
  const plugins: TPlugin[] = [
    customProperty({
      paddingVertical: size => ({ paddingTop: size, paddingBottom: size }),
      paddingHorizontal: size => ({ paddingLeft: size, paddingRight: size }),
      marginVertical: size => ({ marginTop: size, marginBottom: size }),
      marginHorizontal: size => ({ marginLeft: size, marginRight: size }),
    }),
    unit(undefined, undefined, isUnitlessProperty),
    placeholderPrefixer(),
    fallbackValue(),
    // prefixer(), // TODO enable only for old browsers
    typescript(),
  ]

  const enhancers: TEnhancer[] = [identifier]
  const devMode = process.env.NODE_ENV !== 'production'

  if (devMode) {
    enhancers.unshift(monolithic({ prettySelectors: false }))
  }

  const renderer = createRenderer({
    plugins,
    enhancers,
    devMode,
    selectorPrefix: '_',
  })

  renderer.renderStatic(resetStyles)

  return renderer
}

export default configureRenderer
