import * as React from 'react'
function SvgSharingAccessLink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.68 5.07a4.427 4.427 0 00-6.26 0L9.566 8.924c-2.273 2.272-.705 6.16 2.508 6.22l.028-1.499c-1.89-.036-2.813-2.323-1.476-3.66L14.48 6.13a2.927 2.927 0 014.14 4.14l-1.573 1.572a.75.75 0 001.06 1.061l1.573-1.573a4.427 4.427 0 000-6.26zM4.32 20.43a4.427 4.427 0 006.26 0l3.556-3.555c2.302-2.302.581-6.232-2.671-6.102l.06 1.498c1.888-.075 2.887 2.206 1.55 3.543L9.52 19.37a2.927 2.927 0 01-4.14-4.14l1.573-1.573a.75.75 0 00-1.06-1.06L4.32 14.169a4.427 4.427 0 000 6.261z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSharingAccessLink
