import {
  useComingFromEmail,
  useOrigin,
} from '@apps/accounts/src/features/Workspace/SignInPage/query-hooks'
import useAccountSelectorWorkspaces from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector/use-account-selector-workspaces'

const useAccountSelectorFeasibility = () => {
  const origin = useOrigin()
  const comingFromEmail = useComingFromEmail()
  const workspaces = useAccountSelectorWorkspaces(comingFromEmail)
  return origin === 'desktop' && workspaces.length > 0
}

export default useAccountSelectorFeasibility
