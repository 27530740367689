import * as React from 'react'
function SvgScale() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.74 15.4l1.205-1.205c.162-.173.357-.212.586-.117a.5.5 0 01.326.493v3.75c0 .145-.053.271-.159.377a.515.515 0 01-.377.16h-3.75c-.234 0-.399-.113-.493-.336-.095-.217-.056-.41.117-.577l1.205-1.206-7.282-7.282-1.206 1.205a.515.515 0 01-.577.118c-.223-.095-.335-.26-.335-.494v-3.75c0-.145.053-.27.159-.377A.515.515 0 016.536 6h3.75c.234 0 .399.112.494.335.094.217.055.41-.118.577L9.457 8.118l7.282 7.282z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgScale
