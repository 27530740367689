import * as React from 'react'
function SvgImport() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4h2v8h1.97l-3 4.358-3-4.358H11V4zM6 8H4v12h16V8h-4v2h2v8H6v-8h2V8H6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgImport
