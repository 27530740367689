import * as React from 'react'
function SvgChevronSmallUpBold2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.717 8.354a.5.5 0 00-.707 0l-.707.707-3.535 3.535a.5.5 0 000 .707l.707.707a.5.5 0 00.707 0l3.182-3.182 3.182 3.182a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.707l-3.535-3.535-.708-.707z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallUpBold2
