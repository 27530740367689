import * as React from 'react'
function SvgFilesTasks() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.5h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2v-10a2 2 0 012-2zm-3 2a3 3 0 013-3h10a3 3 0 013 3v10a3 3 0 01-3 3H7a3 3 0 01-3-3v-10zm11.93 2.844a.55.55 0 00-.86-.688l-3.616 4.52-1.565-1.565a.55.55 0 10-.778.778l2 2 .435.434.384-.48 4-5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesTasks
