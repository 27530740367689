import * as React from 'react'
function SvgPageSharedLockedTemplateInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.333V4h1v6h-7V4h1v-.667C13 2.053 14.25 2 15.5 2s2.5.052 2.5 1.333zM14 4h3v-.667C17 3 16.603 3 15.501 3 14.4 3 14 3 14 3.333V4zM8 6h3v5h6v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 19.25V11h2V9.5l-.5-1V8H8V6zm6 9h1v1h-1v2h-2v-2h-1v2H9v-2H8v-1h6zm0-3v2h-2v-2h2zm2-4V6h-1v2h1zm-7 4h2v2H9v-2zM2 5v2l1-1h1v3l-1 1h3L5 9V6h1l1 1V5H2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedLockedTemplateInverted
