import mixpanel from 'mixpanel-browser'

export const configureMixPanel = () => {
  if (
    !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ||
    typeof window === 'undefined'
  ) {
    return
  }

  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === 'development',
    persistence: 'localStorage',
  })
}
export const mixPanel = () => mixpanel
