import * as React from 'react'
function SvgCommentInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.5A1.5 1.5 0 004.5 7v7A1.5 1.5 0 006 15.5h1.5V19l4.667-3.5H17a1.5 1.5 0 001.5-1.5V7A1.5 1.5 0 0017 5.5H6zM11 8h1v2h2v1h-2v2h-1v-2H9v-1h2V8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCommentInverted
