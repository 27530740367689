import * as React from 'react'
import View, { Props as ViewProps } from '@packages/common/src/components/View'

type Props = ViewProps & {
  src: string
  alt?: string
  title?: string
}

const Image = ({ src, alt, title, ...props }: Props) => (
  <View
    as="img"
    nativeProps={{ src, alt, title, ...props.nativeProps }}
    {...props}
  />
)

export default Image
