import * as React from 'react'
function SvgTable() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 15.75c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm0-3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm4 3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm-4-6c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm4 3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm4 3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm-4-6c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm4 3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm0-3c0 .14-.11.25-.25.25h-2.5c-.14 0-.25-.11-.25-.25v-1.5c0-.14.11-.25.25-.25h2.5c.14 0 .25.11.25.25v1.5zm1-2.5c0-.688-.563-1.25-1.25-1.25H6.75c-.688 0-1.25.563-1.25 1.25v8.5c0 .688.563 1.25 1.25 1.25h10.5c.688 0 1.25-.563 1.25-1.25v-8.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTable
