import * as React from 'react'
function SvgArchive() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 11.5c0 .273-.227.5-.5.5h-2a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h2c.273 0 .5.227.5.5zM18 10c0-.273-.227-.5-.5-.5h-11c-.273 0-.5.227-.5.5v7.5c0 .273.227.5.5.5h11c.273 0 .5-.227.5-.5V10zm.5-3.5c0-.273-.227-.5-.5-.5H6c-.273 0-.5.227-.5.5v2c0 .273.227.5.5.5h12c.273 0 .5-.227.5-.5v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArchive
