import * as React from 'react'
function SvgRestriction() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2h1v10h3V8h1v-.667C12 6.053 13.25 6 14.5 6s2.5.052 2.5 1.333V8h1v4h4v1h-4v2h-7v-2H7V2zm9 6v-.667C16 7 15.603 7 14.501 7 13.4 7 13 7 13 7.333V8h3zm-4 1v5h5V9h-5zm3 2h-1v2h1v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgRestriction
