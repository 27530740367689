import * as React from 'react'
function SvgPageSharedLockedAliasTemplateInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.333V4h1v6h-7V4h1v-.667C13 2.053 14.25 2 15.5 2s2.5.052 2.5 1.333zM14 4h3v-.667C17 3 16.603 3 15.501 3 14.4 3 14 3 14 3.333V4zM9 6h2v5h6v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219.739.739 0 01-.164-.24L7 19v-5H5v-3h3V9.5l-.5-1V8H9V6zm6 9H8v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-1v-2h-2v2h2zm2-8v2h-1V6h1zm-5 6H9v2h2v-2zm-8.658 9.492c.305.306.837.604 1.596.895a10.235 10.235 0 00.216.083.441.441 0 00.096.025c.054.009.095-.001.125-.03a.114.114 0 00.037-.093.146.146 0 00-.046-.093.804.804 0 00-.08-.066 6.594 6.594 0 01-.425-.384 3.321 3.321 0 01-.452-.548 2.122 2.122 0 01-.248-.517 1.36 1.36 0 01-.058-.5c.012-.168.045-.33.099-.483.054-.154.138-.315.25-.485.112-.17.234-.33.366-.485.132-.153.288-.32.468-.5l.619-.62.707.707a.24.24 0 00.177.072.24.24 0 00.177-.072.24.24 0 00.071-.176v-2.829a.24.24 0 00-.071-.177.24.24 0 00-.177-.071H2.96a.24.24 0 00-.176.072.24.24 0 00-.072.176c0 .07.024.13.072.177l.707.707-.62.619c-1.312 1.313-1.747 2.49-1.303 3.53.15.344.407.7.774 1.066zM3 5v2l1-1h1v3l-1 1h3L6 9V6h1l1 1V5H3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedLockedAliasTemplateInverted
