import * as React from 'react'
import AvatarFallback, { getFirstLetters } from './AvatarFallback'
import { AvatarStatusProvider } from './image-ctx'
import AvatarImage from './AvatarImage'
import { AvatarProps, AvatarWithImageProps } from './types'
export { calcAvatarOrderFromUuid } from './helpers'

const Avatar = ({
  children,
  initState,
}: {
  children?: React.ReactNode
  initState?: 'idle' | 'loading'
}) => {
  return (
    <AvatarStatusProvider initState={initState}>
      {children}
    </AvatarStatusProvider>
  )
}

export const UserAvatar = ({
  children,
  src,
  ...props
}: AvatarWithImageProps) => {
  const name = getFirstLetters(children, 2)

  return (
    <Avatar>
      <AvatarImage src={src} {...props} />
      <AvatarFallback type="user" {...props} length={name.length}>
        {name.join('')}
      </AvatarFallback>
    </Avatar>
  )
}

export const RemoteUserAvatar = ({
  children,
  src,
  ...props
}: AvatarWithImageProps) => {
  const name = getFirstLetters(children, 2)

  return (
    <Avatar>
      <AvatarImage src={src} {...props} />
      <AvatarFallback type="remoteUser" length={name.length} {...props}>
        {name.join('')}
      </AvatarFallback>
    </Avatar>
  )
}

export const CompanyAvatar = ({
  children,
  src,
  ...props
}: AvatarWithImageProps) => {
  const name = getFirstLetters(children, 1)

  return (
    <Avatar initState={src ? 'loading' : 'idle'}>
      <AvatarImage variant="rounded" src={src} {...props} />
      <AvatarFallback
        type="company"
        variant="rounded"
        length={name.length}
        {...props}>
        {name.join('')}
      </AvatarFallback>
    </Avatar>
  )
}

export const CounterAvatar = ({ children, ...props }: AvatarProps) => {
  const name = getFirstLetters(children, 2)

  return (
    <Avatar>
      <AvatarFallback type="counter" length={name.length} {...props}>
        {name.join('')}
      </AvatarFallback>
    </Avatar>
  )
}
