import * as React from 'react'
function SvgBrandFront() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.908 3.58a2.803 2.803 0 00-2.803 2.802v14.04h3.989c.773 0 1.4-.628 1.4-1.402v-5.304a3.639 3.639 0 114.748-4.747h.252c.773 0 1.4-.628 1.4-1.402V3.58H8.909z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.242 8.968h-3.4c-.744 0-1.347.604-1.347 1.348v3.4a3.639 3.639 0 114.747-4.748z"
        fill="currentColor"
      />
      <path
        opacity={0.7}
        d="M16.514 10.35a3.638 3.638 0 10-7.276 0 3.638 3.638 0 007.276 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandFront
