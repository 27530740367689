import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import resources from './resources'

const configureI18n = (language = 'en') => {
  i18n
    .use(ICU)
    .use(initReactI18next)
    .init({
      resources,
      // debug: process.env.NODE_ENV === 'development',
      fallbackLng: 'en',
      keySeparator: false,
      lng: language,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

  return i18n
}

export default configureI18n
