import * as React from 'react'
function SvgArrowBendedBottomRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10.25c0-.865.33-2.039.992-3.523a22.236 22.236 0 01.188-.422.88.88 0 01.101-.172C6.344 6.044 6.417 6 6.5 6c.078 0 .14.026.184.078a.291.291 0 01.066.195c0 .047-.007.116-.02.207-.013.092-.02.153-.02.184-.025.354-.038.675-.038.961 0 .526.045.997.137 1.414.09.417.217.777.378 1.082.162.305.37.568.625.79.256.22.53.402.825.542.294.14.64.251 1.039.332.398.08.8.137 1.203.168.404.031.86.047 1.371.047H14v-2a.48.48 0 01.148-.352.48.48 0 01.352-.148.48.48 0 01.352.148l4 4A.48.48 0 0119 14a.48.48 0 01-.148.352l-4 4a.48.48 0 01-.352.148.48.48 0 01-.352-.148A.48.48 0 0114 18v-2h-1.75c-3.714 0-5.992-1.05-6.836-3.148C5.138 12.154 5 11.286 5 10.25z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowBendedBottomRight
