import * as React from 'react'
function SvgBrandTwitter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.93 8.062c.026.105.039.274.039.508 0 1.875-.456 3.685-1.367 5.43-.938 1.849-2.253 3.32-3.946 4.414-1.849 1.224-3.971 1.836-6.367 1.836-2.292 0-4.388-.612-6.289-1.836.286.026.612.04.977.04 1.9 0 3.606-.587 5.117-1.759-.912 0-1.712-.267-2.403-.8a4.08 4.08 0 01-1.425-2.012c.26.026.507.039.742.039.364 0 .729-.04 1.094-.117a4.133 4.133 0 01-2.344-1.446 3.933 3.933 0 01-.938-2.578v-.078c.573.339 1.185.52 1.836.547a4.55 4.55 0 01-1.328-1.484 3.94 3.94 0 01-.508-1.973c0-.716.196-1.4.586-2.05a11.31 11.31 0 003.73 3.046 11.354 11.354 0 004.708 1.25 5.692 5.692 0 01-.078-.937c0-.73.182-1.413.546-2.051.365-.638.86-1.14 1.485-1.504A3.962 3.962 0 0115.828 4c.599 0 1.152.117 1.66.352.508.234.957.546 1.348.937a8.345 8.345 0 002.617-.976c-.312.963-.911 1.718-1.797 2.265A8.811 8.811 0 0022 5.914a9.018 9.018 0 01-2.07 2.148z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandTwitter
