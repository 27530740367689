import * as React from 'react'
function SvgCopyToClipboard() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.844 7.563a1.819 1.819 0 00-.375-.594L17.03 4.53a1.817 1.817 0 00-.593-.375A1.804 1.804 0 0015.75 4h-7a.723.723 0 00-.531.219A.723.723 0 008 4.75V7H4.75a.723.723 0 00-.531.219A.723.723 0 004 7.75v12.5c0 .208.073.385.219.531A.723.723 0 004.75 21h10.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V18h3.25a.723.723 0 00.531-.219.723.723 0 00.219-.531v-9c0-.208-.052-.438-.156-.688zM15 18H8.75a.723.723 0 01-.531-.219A.723.723 0 018 17.25V8H5v12h10v-2zm1-10V5.062c.151.053.258.11.32.172l2.446 2.446c.062.062.12.169.172.32H16zm3 1v8H9V5h6v3.25c0 .208.073.385.219.531A.723.723 0 0015.75 9H19z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCopyToClipboard
