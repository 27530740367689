import * as React from 'react'
function SvgItemTag() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.982 6.406l3.408 4.216a2 2 0 010 2.514l-3.408 4.217a2 2 0 01-1.555.743H6.267a2 2 0 01-2-2V7.663a2 2 0 012-2h10.16a2 2 0 011.555.743zm2.63 4.845a1 1 0 010 1.257l-3.408 4.216a1 1 0 01-.777.372H6.267a1 1 0 01-1-1V7.663a1 1 0 011-1h10.16a1 1 0 01.777.371l3.409 4.217zm-4.116 1.655a1.047 1.047 0 01-1.054-1.04c0-.575.472-1.04 1.054-1.04.582 0 1.054.465 1.054 1.04 0 .574-.472 1.04-1.054 1.04z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemTag
