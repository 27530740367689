import * as React from 'react'
function SvgAlertInfoBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm2 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-9.595-5.757c0-.688-.441-1.288-1.323-1.288-1.16 0-1.74.945-1.74 1.631-.023.816.464 1.31 1.3 1.31.997 0 1.763-.795 1.763-1.653zm-4.756 9.554c-.487 1.89.023 2.32.72 2.32.694 0 2.493-.646 4.14-2.322l-.395-.536c-.673.537-1.357.795-1.566.795-.162 0-.232-.215-.07-.837l.916-3.522c.347-1.287.231-2.125-.464-2.125-.836 0-2.783.843-4.476 2.281l.348.58c.535-.366 1.438-.736 1.646-.736.162 0 .139.216 0 .751l-.799 3.351z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertInfoBig
