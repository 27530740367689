import React from 'react'
import {
  RendererProvider,
  ThemeProvider as FelaThemeProvider,
} from 'react-fela'
import themeSchema from '@packages/common/src/themes/ui'
import { Renderer } from '@packages/common/src/providers/style/configure-renderer'

type Props = {
  renderer: Renderer
}

const ThemeProvider = ({
  children,
  renderer,
}: React.PropsWithChildren<Props>) => (
  <RendererProvider renderer={renderer}>
    <FelaThemeProvider theme={themeSchema}>{children}</FelaThemeProvider>
  </RendererProvider>
)

export default ThemeProvider
