import * as React from 'react'
function SvgCollapseBigDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.898 7.5c.152 0 .286.068.387.169l.842.841a.58.58 0 01.168.387.58.58 0 01-.168.387l-7.843 7.843a.58.58 0 01-.387.168.58.58 0 01-.387-.168L3.668 9.284a.58.58 0 01-.168-.387.58.58 0 01.168-.387l.841-.841a.58.58 0 01.387-.168.58.58 0 01.387.168l6.614 6.614 6.614-6.614a.58.58 0 01.387-.168z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCollapseBigDown
