import * as React from 'react'
function SvgFolder() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10v7.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V10zm-1 0v7.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 17.25V10h14zm0-1v-.25a.723.723 0 00-.219-.531A.723.723 0 0018.25 8h-8.5a.723.723 0 01-.531-.219A.723.723 0 019 7.25v-.5a.723.723 0 00-.219-.531A.723.723 0 008.25 6h-2.5a.723.723 0 00-.531.219A.723.723 0 005 6.75V9h14z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolder
