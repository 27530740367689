import * as React from 'react'
function SvgFilesNotificationsInbox() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.197 15.5c.51-.882.803-1.907.803-3V10a3.5 3.5 0 117 0v2.5c0 1.093.292 2.118.802 3H8.197zm10.802 1a5.028 5.028 0 01-1-1 4.978 4.978 0 01-1-3V10a4.5 4.5 0 10-9 0v2.5a4.978 4.978 0 01-1 3 5.029 5.029 0 01-.999 1h4.05a2.5 2.5 0 104.9 0h4.049zm-5.085 0h-2.83a1.5 1.5 0 102.83 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesNotificationsInbox
