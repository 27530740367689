import * as React from 'react'
function SvgPageLocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5.333V6h1v6h-2v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 20.25V7.75c0-.208.073-.385.219-.531A.723.723 0 015.75 7H12V6h1v-.667C13 4.053 14.25 4 15.5 4s2.5.052 2.5 1.333zM14 6h3v-.667C17 5 16.603 5 15.501 5 14.4 5 14 5 14 5.333V6zm-2 2H6v12h10v-8h-4V8zm-4 5h6v1H8v-1zm6 2H8v1h6v-1zm-6 2h5v1H8v-1zm5-10v4h5V7h-5zm3 3V8h-1v2h1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageLocked
