import { StandardError } from '@packages/common/src/errors/standard-error'

export abstract class ClientNetworkError extends StandardError {
  toString() {
    return this.message.replace(/^Error: /, '')
  }
}

export class ApiError extends ClientNetworkError {
  status: number
  body: any
  requestHeaders: any
  url: string

  constructor(status: number, url: string, body = {}, requestHeaders = {}) {
    super()
    this.name = `ApiError ${status} (${url})`
    this.status = +status
    this.url = url
    this.body = body
    this.requestHeaders = requestHeaders
  }

  toString() {
    return `${this.status}: ${JSON.stringify(this.body.error)}`
  }
}

export class ConnectionError extends ClientNetworkError {
  constructor() {
    super('Cannot connect to the server')
  }
}

export class IncompatibleVersionError extends ClientNetworkError {
  tooOldSide: 'server' | 'client'

  constructor(tooOldSide: 'server' | 'client') {
    super()
    this.tooOldSide = tooOldSide
  }
}
