import * as React from 'react'
function SvgErrorExclamation() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 1a8 8 0 100-16 8 8 0 000 16z"
        fill="currentColor"
      />
      <path
        d="M12.896 7H11l.25 6h1.5l.146-6zM12 16.5c.546 0 1.018-.456 1.023-1.023A1.035 1.035 0 0012 14.463a1.018 1.018 0 100 2.037z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgErrorExclamation
