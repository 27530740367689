import * as React from 'react'
function SvgTabPanelTabClose() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={8} cy={8} r={8} fill="currentColor" fillOpacity={0.08} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.636 4.364c.201.2.201.526 0 .727l-2.909 2.91 2.91 2.908a.514.514 0 01-.728.727L8 8.727l-2.91 2.91a.514.514 0 01-.726-.728L7.273 8l-2.91-2.909a.514.514 0 11.728-.727L8 7.273l2.91-2.91c.2-.2.525-.2.726 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTabPanelTabClose
