import * as React from 'react'
function SvgCaption() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10h-4v3h4v-3zM4 9h16v9H4V9zm15 8v-3h-4v3h4zm-5-7h-4v3h4v-3zm0 7v-3h-4v3h4zm-5-7H5v3h4v-3zm0 4H5v3h4v-3zM6 5h12v3H6V5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCaption
