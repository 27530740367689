import * as React from 'react'
function SvgInputFieldSuccess() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 13.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L11 13.586z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInputFieldSuccess
