import * as React from 'react'
function SvgChevronBigRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.397c0-.152.067-.286.168-.387l.842-.842A.58.58 0 018.397 4a.58.58 0 01.387.168l7.842 7.843a.58.58 0 01.169.387.58.58 0 01-.169.387l-7.842 7.842a.58.58 0 01-.387.169.58.58 0 01-.387-.169l-.842-.841A.58.58 0 017 19.399a.58.58 0 01.168-.387l6.614-6.614-6.614-6.614A.58.58 0 017 5.397z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronBigRight
