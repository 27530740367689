import * as React from 'react'
function SvgCommentCountMulti() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15 5.5a.35.35 0 01.35-.35H20a.35.35 0 01.35.35v6a.35.35 0 01-.35.35h-1.35V8.5A1.65 1.65 0 0017 6.85H8.15V5.5zM20 13.15h-1.35v1.35A1.65 1.65 0 0117 16.15h-1.35v1.35A1.65 1.65 0 0114 19.15H9.217L4.35 22.8v-3.65H3a1.65 1.65 0 01-1.65-1.65v-6c0-.911.739-1.65 1.65-1.65h.85V8.5c0-.911.739-1.65 1.65-1.65h1.35V5.5c0-.911.739-1.65 1.65-1.65H20c.911 0 1.65.739 1.65 1.65v6A1.65 1.65 0 0120 13.15zM5.15 9.85H14c.911 0 1.65.739 1.65 1.65v3.35H17a.35.35 0 00.35-.35v-6a.35.35 0 00-.35-.35H5.5a.35.35 0 00-.35.35v1.35zm-2.5 1.65a.35.35 0 01.35-.35h11a.35.35 0 01.35.35v6a.35.35 0 01-.35.35H8.783L5.65 20.2v-2.35H3a.35.35 0 01-.35-.35v-6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCommentCountMulti
