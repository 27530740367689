import * as React from 'react'
import { stringToGraphemes } from '../../utils/strings'
import Text from '@packages/common/src/components/Text'
import { useAvatarStatus } from './image-ctx'
import { AvatarFallbackProps } from './types'
import cmpStyles from '../../providers/style/cmp-styles'

const fontSize: Record<
  NonNullable<AvatarFallbackProps['size']>,
  { single: number; pair: number }
> = {
  20: {
    single: 13,
    pair: 11,
  },
  24: {
    single: 15,
    pair: 11,
  },
  30: {
    single: 18,
    pair: 13,
  },
  40: {
    single: 22,
    pair: 17,
  },
  48: {
    single: 30,
    pair: 30,
  },
}

const styles = cmpStyles({
  box: ({
    length,
    order,
    size,
    type,
    styleVariant,
    theme: { components, borderRadius },
  }) => ({
    // @ts-ignore
    ...components.Avatar[type][order],
    minHeight: size,
    maxHeight: size,
    minWidth: size,
    maxWidth: size,
    lineHeight: `${size}px`,
    // @ts-ignore
    fontSize: fontSize[size][length <= 1 ? 'single' : 'pair'],
    textAlign: 'center',
    borderRadius: styleVariant === 'circled' ? '50%' : borderRadius.large,
    userSelect: 'none',
  }),
})

export const getFirstLetters = (value: string, max: number) =>
  value
    ? value
        .split(' ')
        .slice(0, max)
        .map(name => stringToGraphemes(name)[0]?.toLocaleUpperCase() ?? '')
    : []

const AvatarFallback = ({
  children,
  length,
  type = 'user',
  size = 40,
  order = 0,
  variant = 'circled',
  style,
  testID,
}: AvatarFallbackProps) => {
  const status = useAvatarStatus()

  if (['loading', 'loaded'].includes(status)) {
    return null
  }

  return (
    <Text
      testID={testID}
      weight="black"
      style={style ? [styles.box, style] : styles.box}
      length={length}
      size={size}
      styleVariant={variant}
      order={order % 8}
      type={type}>
      {children}
    </Text>
  )
}

export default AvatarFallback
