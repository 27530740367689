import * as React from 'react'
function SvgHelpPanelInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.531 11.266c0 1.308-.327 2.52-.981 3.632a7.342 7.342 0 01-2.652 2.652 7.043 7.043 0 01-3.632.981 7.043 7.043 0 01-3.633-.981 7.342 7.342 0 01-2.652-2.652A7.043 7.043 0 015 11.266c0-1.309.327-2.52.981-3.633A7.342 7.342 0 018.633 4.98 7.043 7.043 0 0112.266 4c1.308 0 2.52.327 3.632.981a7.342 7.342 0 012.652 2.652 7.043 7.043 0 01.981 3.633zm-7.06-4.864c-.782 0-1.455.166-2.022.498-.527.293-.996.752-1.406 1.377a.293.293 0 00-.059.25.37.37 0 00.147.22l1.025.76a.275.275 0 00.235.074.32.32 0 00.234-.132c.313-.37.557-.625.732-.762.274-.195.586-.292.938-.292.332 0 .64.092.923.278.283.185.425.415.425.688 0 .215-.088.4-.264.557a5.788 5.788 0 01-.674.44l-.059.029c-.488.273-.83.517-1.025.732-.351.371-.527.81-.527 1.319v.117c0 .097.034.18.102.249a.339.339 0 00.25.102h1.64c.098 0 .18-.034.249-.102a.339.339 0 00.102-.25v-.029c0-.156.079-.293.235-.41.098-.078.283-.195.556-.351.508-.313.87-.586 1.085-.82.37-.41.556-.928.556-1.553 0-.547-.166-1.05-.498-1.51a3.488 3.488 0 00-1.289-1.083 3.567 3.567 0 00-1.611-.396zm-.205 7.266c-.371 0-.689.132-.953.396a1.298 1.298 0 00-.395.952c0 .37.132.688.396.952.263.263.58.395.952.395.37 0 .688-.132.952-.395.263-.264.395-.581.395-.952s-.132-.689-.395-.953a1.298 1.298 0 00-.952-.395z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHelpPanelInverted
