import * as React from 'react'
function SvgGear() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.143 11.857a2.29 2.29 0 01-2.286 2.286 2.29 2.29 0 01-2.286-2.286 2.29 2.29 0 012.286-2.286 2.29 2.29 0 012.286 2.286zm4.571-.973a.326.326 0 00-.259-.322l-1.634-.25a4.912 4.912 0 00-.366-.874c.304-.42.634-.813.938-1.224a.337.337 0 00.009-.402c-.375-.526-1-1.08-1.473-1.517a.352.352 0 00-.224-.09c-.08 0-.16.027-.214.08l-1.268.956a5.244 5.244 0 00-.803-.33l-.25-1.643c-.018-.152-.161-.268-.322-.268h-1.982a.327.327 0 00-.321.25c-.143.536-.197 1.116-.26 1.66-.276.09-.553.206-.812.34l-1.232-.955a.397.397 0 00-.232-.09c-.304 0-1.509 1.304-1.723 1.599a.346.346 0 00-.08.205c0 .08.035.152.089.214.33.402.651.804.955 1.232a4.724 4.724 0 00-.348.822l-1.66.25c-.135.027-.242.187-.242.321v1.982c0 .152.107.295.259.322l1.634.24c.09.304.214.6.366.885-.304.42-.634.812-.938 1.223a.347.347 0 00-.009.41c.375.519 1 1.072 1.474 1.5a.32.32 0 00.223.099c.08 0 .16-.027.223-.08l1.26-.956c.258.134.526.241.803.33l.25 1.643c.018.152.16.268.321.268h1.982c.16 0 .286-.107.322-.25.143-.535.196-1.116.259-1.66.276-.09.553-.206.812-.34l1.232.965c.072.044.152.08.232.08.304 0 1.51-1.313 1.724-1.598a.298.298 0 00.08-.206c0-.08-.036-.16-.09-.223a18.83 18.83 0 01-.955-1.232c.143-.259.25-.527.349-.813l1.651-.25c.143-.026.25-.187.25-.32v-1.983z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgGear
