import * as React from 'react'
import Icon, { IconName } from '@packages/common/src/components/Icon'
import View from '@packages/common/src/components/View'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'

type Props = {
  children: React.ReactNode | IconName
  position: 'before' | 'after'
  distance?: number
  enablePointerEvents?: boolean
}

const styles = cmpStyles({
  box: ({ position, theme, distance, enablePointerEvents }) => ({
    ...theme.components.TextInput.adornment,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 3,
    [position === 'before' ? 'left' : 'right']: distance,
    ...(enablePointerEvents ? {} : { pointerEvents: 'none' }),
  }),
  icon: {
    alignItems: 'center',
  },
})

const Adornment = ({
  children,
  position,
  distance = 5,
  enablePointerEvents = false,
}: Props) => {
  if (!children) return null

  if (typeof children === 'string') {
    return (
      <View
        style={[styles.box, styles.icon]}
        position={position}
        distance={distance}
        enablePointerEvents={enablePointerEvents}>
        {/* @ts-ignore I'll risk it*/}
        <Icon name={children} />
      </View>
    )
  }

  return (
    <View
      style={styles.box}
      position={position}
      distance={distance}
      enablePointerEvents={enablePointerEvents}>
      {children}
    </View>
  )
}

export default React.memo(Adornment)
