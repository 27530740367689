import * as React from 'react'
function SvgModeDark() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.082 17.687c-.34.244-.701.458-1.082.64A6.95 6.95 0 0010 19 7 7 0 107 5.674 6.99 6.99 0 0111 12a6.99 6.99 0 01-2.918 5.687zm1.25.276a6 6 0 100-11.927A7.98 7.98 0 0112 12a7.98 7.98 0 01-2.667 5.963z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgModeDark
