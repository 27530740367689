import * as React from 'react'
function SvgFilesAttributes() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9a3.5 3.5 0 013.5-3.5h14A3.5 3.5 0 0122 9v9a3.5 3.5 0 01-3.5 3.5h-14A3.5 3.5 0 011 18V9zm3.5-2.5A2.5 2.5 0 002 9v9a2.5 2.5 0 002.5 2.5h14A2.5 2.5 0 0021 18V9a2.5 2.5 0 00-2.5-2.5h-14zM7.1 10a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zm1.648 3.611a2.6 2.6 0 10-3.31-.012c-.04.024-.08.048-.118.074-.464.308-.764.733-.955 1.187C4 15.722 4 16.757 4 17.46v.039a.5.5 0 001 0c0-.75.007-1.593.285-2.25.134-.315.321-.567.588-.744.267-.177.653-.306 1.227-.306.532 0 .882.11 1.12.26.233.149.399.363.518.65.253.611.261 1.45.261 2.39a.5.5 0 001 0v-.058c0-.866 0-1.9-.338-2.716-.18-.436-.465-.83-.906-1.11l-.007-.005zM12 10.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesAttributes
