import * as React from 'react'
function SvgMoveVertically() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 16h1a.48.48 0 01.352.148.48.48 0 01.148.352.48.48 0 01-.148.352l-2 2A.48.48 0 0112.5 19a.48.48 0 01-.352-.148l-2-2A.48.48 0 0110 16.5a.48.48 0 01.148-.352A.48.48 0 0110.5 16h1V8h-1a.48.48 0 01-.352-.148A.48.48 0 0110 7.5a.48.48 0 01.148-.352l2-2A.48.48 0 0112.5 5a.48.48 0 01.352.148l2 2A.48.48 0 0115 7.5a.48.48 0 01-.148.352A.48.48 0 0114.5 8h-1v8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMoveVertically
