import * as React from 'react'
function SvgInsightUnprocessed() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.5a3 3 0 100-6 3 3 0 000 6zm0 1a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
      <circle cx={12} cy={12.5} r={5.5} stroke="currentColor" />
    </svg>
  )
}
export default SvgInsightUnprocessed
