import * as React from 'react'
import View from '@packages/common/src/components/View'
import Text from '@packages/common/src/components/Text'
import Separator from '@packages/common/src/components/Separator'
import ClickableOpacity from '@packages/common/src/components/ClickableOpacity'
import Icon from '@packages/common/src/components/Icon'
import { CompanyAvatar } from '@packages/common/src/components/Avatar'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import Spinner from '@packages/common/src/components/Spinner'

type Props = {
  accounts: Array<{ id: string; name: string; email: string }>
  onSelect: (id: string) => void
  fallbackText?: string
  onFallback?: () => void
  selectedId?: string | undefined
}

const styles = cmpStyles({
  separator: {
    marginVertical: 20,
  },
  account: ({ theme: { components, borderRadius } }) => ({
    display: 'grid',
    paddingVertical: 6,
    paddingLeft: 15,
    paddingRight: 10,
    gridTemplateColumns: '30px 1fr max-content',
    gap: 15,
    alignItems: 'center',
    borderRadius: borderRadius.large,
    ...components.AccountsList.Item,
  }),
  items: {
    display: 'grid',
    gap: 10,
    paddingVertical: 5,
    marginVertical: -5,
  },
  info: {
    flexDirection: 'column',
    overflow: 'hidden',
  },
  fallback: ({ theme: { components, borderRadius } }) => ({
    display: 'grid',
    paddingVertical: 9,
    paddingHorizontal: 15,
    gridTemplateColumns: '30px 1fr',
    gap: 13,
    alignItems: 'center',
    borderRadius: borderRadius.large,
    ...components.AccountsList.Item,
  }),
  iconFallback: {
    justifySelf: 'center',
  },
})

const AccountsList = ({
  accounts,
  selectedId,
  onSelect,
  fallbackText,
  onFallback,
}: Props) => {
  const loading = !!selectedId

  return (
    <View isVertical>
      <View isVertical>
        <View style={styles.separator}>
          <Separator />
        </View>
        <View style={styles.items}>
          {accounts.map(({ id, name, email }) => (
            <ClickableOpacity
              key={id}
              style={styles.account}
              isDisabled={loading}
              onClick={() => {
                onSelect(id)
              }}
              cursor={loading ? 'default' : undefined}>
              <CompanyAvatar size={30}>{name}</CompanyAvatar>
              <View style={styles.info}>
                <Text showEllipsis>{name}</Text>
                <Text scale={-1} color="secondary" showEllipsis>
                  {email}
                </Text>
              </View>
              {selectedId === id ? (
                <Spinner color="primary" />
              ) : (
                <Icon name="chevron-small-right" color="primary" />
              )}
            </ClickableOpacity>
          ))}
        </View>
        <View style={styles.separator}>
          <Separator />
        </View>
      </View>

      {onFallback ? (
        <View style={styles.items}>
          <ClickableOpacity
            onClick={onFallback}
            style={styles.fallback}
            isDisabled={loading}
            cursor={loading ? 'default' : undefined}>
            <Icon
              name="plus-circle-thin"
              style={styles.iconFallback}
              color="primary"
            />
            <Text>{fallbackText}</Text>
          </ClickableOpacity>
        </View>
      ) : null}
    </View>
  )
}

export default AccountsList
