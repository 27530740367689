import * as React from 'react'
function SvgInsightAll() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.5l9 4.5 9-4.5-8.807-4L3 9.5zm2.362.063L12 12.882l6.677-3.339-6.493-2.949-6.822 2.969zM21 12.53l-9.012 4.529L3 12.5v-1.02l9.012 4.46L21 11.5v1.03zm-9.012 7.55L21 15.55v-1.03l-8.988 4.44L3 14.5v1.02l8.988 4.56z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInsightAll
