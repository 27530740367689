import * as React from 'react'
function SvgBrandChrome() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.965 5.961A10.039 10.039 0 0112 1.948c4.098 0 7.625 2.455 9.19 5.974.02.037.037.076.05.117.523 1.215.812 2.555.812 3.961 0 5.55-4.503 10.052-10.052 10.052a10.172 10.172 0 01-1.182-.068c-4.993-.586-8.87-4.835-8.87-9.984 0-2.203.71-4.242 1.913-5.899a.752.752 0 01.104-.14zm.391 2.201A8.511 8.511 0 003.448 12c0 4.266 3.13 7.807 7.216 8.448a19.304 19.304 0 002.797-4.221c-.458.158-.95.244-1.461.244a4.468 4.468 0 01-3.846-2.19 23.177 23.177 0 01-3.798-6.119zm3.265 2.933a21.777 21.777 0 01-2.264-4.48A8.537 8.537 0 0112 3.448c2.97 0 5.59 1.517 7.122 3.818a20.735 20.735 0 00-7.206.264 4.471 4.471 0 00-4.295 3.565zm1.842 2.453a.747.747 0 00-.07-.122A2.97 2.97 0 0112 9.029a2.97 2.97 0 012.608 4.396.742.742 0 00-.07.122A2.968 2.968 0 0112 14.97a2.968 2.968 0 01-2.537-1.423zm6.43.653a20.803 20.803 0 01-3.359 6.335c4.472-.276 8.018-3.995 8.018-8.536a8.525 8.525 0 00-.567-3.068 19.235 19.235 0 00-5.063-.316A4.46 4.46 0 0116.471 12c0 .8-.21 1.551-.578 2.2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandChrome
