import { Auth } from '@apps/accounts/src/features/Workspace/auth'

const createSignInUrl = (auth: Auth, backUrl?: string) => {
  const redirectToUrl = new URL(
    '',
    backUrl || process.env.NEXT_PUBLIC_SIGN_IN_CALLBACK
  )
  redirectToUrl.searchParams.set('token', auth.token)
  redirectToUrl.searchParams.set('workspace', auth.workspaceId)
  redirectToUrl.searchParams.set('user', auth.userId)

  return redirectToUrl.toString()
}

export default createSignInUrl
