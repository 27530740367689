import * as React from 'react'
function SvgPlusCircleThin() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.5a7 7 0 100-14 7 7 0 000 14zm0 1a8 8 0 100-16 8 8 0 000 16zM12 8a.5.5 0 01.5.5V12H16a.5.5 0 010 1h-3.5v3.5a.5.5 0 01-1 0V13H8a.5.5 0 010-1h3.5V8.5A.5.5 0 0112 8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusCircleThin
