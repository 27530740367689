import * as React from 'react'
function SvgExport() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7h-2.03v6h-2V7H9l2.97-4L15 7zM6 8H4v12h16V8h-4v2h2v8H6v-8h2V8H6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgExport
