import * as React from 'react'
function SvgSharingAccessLock() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8.5v2h-6v-2a3 3 0 016 0zm-7.5 2v-2a4.5 4.5 0 019 0v2h1a1 1 0 011 1v7a1 1 0 01-1 1H6a1 1 0 01-1-1v-7a1 1 0 011-1h1zm9 1.5H6.5v6h10v-6H16z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSharingAccessLock
