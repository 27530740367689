import * as React from 'react'
function SvgPlusCircleBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.129 11.984v1.032a.373.373 0 01-.113.274.373.373 0 01-.274.113h-2.839v2.839a.373.373 0 01-.113.274.373.373 0 01-.274.113h-1.032a.373.373 0 01-.274-.113.373.373 0 01-.113-.274v-2.839H8.258a.373.373 0 01-.274-.113.373.373 0 01-.113-.274v-1.032c0-.108.038-.2.113-.274a.373.373 0 01.274-.113h2.839V8.758c0-.107.037-.199.113-.274a.373.373 0 01.274-.113h1.032c.108 0 .2.038.274.113a.373.373 0 01.113.274v2.839h2.839c.107 0 .199.037.274.113a.373.373 0 01.113.274zM20 12.5c0 1.44-.36 2.774-1.08 4A8.085 8.085 0 0116 19.42a7.755 7.755 0 01-4 1.08c-1.44 0-2.774-.36-4-1.08a8.084 8.084 0 01-2.92-2.92 7.755 7.755 0 01-1.08-4c0-1.44.36-2.774 1.08-4A8.084 8.084 0 018 5.58a7.755 7.755 0 014-1.08c1.44 0 2.774.36 4 1.08a8.084 8.084 0 012.92 2.92 7.755 7.755 0 011.08 4zm-1.548 0a6.26 6.26 0 00-.871-3.226 6.505 6.505 0 00-2.355-2.355A6.26 6.26 0 0012 6.05a6.26 6.26 0 00-3.226.87A6.506 6.506 0 006.42 9.274a6.26 6.26 0 00-.87 3.226c0 1.161.29 2.237.87 3.226a6.505 6.505 0 002.355 2.355 6.26 6.26 0 003.226.87 6.26 6.26 0 003.226-.87 6.504 6.504 0 002.355-2.355 6.26 6.26 0 00.87-3.226z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusCircleBold
