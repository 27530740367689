import * as React from 'react'
import View from './View'
import Text from './Text'
import { CmpStyles, Style } from '../providers/style/types'
import { TestableProps } from './Stylo'

type Props = TestableProps & {
  children: string | string[]
  style?: Style
}

const styles: CmpStyles = {
  box: ({ theme: { components } }) => ({
    ...components.Error,
    flexDirection: 'column',
  }),
  forInput: {
    marginTop: 5,
  },
}

const Error = ({ testID, children, style }: Props) => {
  let error
  if (Array.isArray(children)) {
    error = (
      <>
        {children.map((errorMsg: string, i) => (
          <Text color="inherit" key={i}>
            {errorMsg}
          </Text>
        ))}
      </>
    )
  } else {
    error = <Text color="inherit">{children}</Text>
  }

  return (
    <View style={style ? [styles.box, style] : styles.box} testID={testID}>
      {error}
    </View>
  )
}

const InputError = ({ children, ...props }: Props) => (
  <Error {...props} style={styles.forInput}>
    {children}
  </Error>
)

export default InputError
