import * as React from 'react'
function SvgArrowAlias() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.464 18.614c-.732-.733-1.248-1.444-1.546-2.132-.888-2.081-.019-4.434 2.607-7.06l1.238-1.238L9.348 6.77a.48.48 0 01-.143-.354.48.48 0 01.143-.353.48.48 0 01.354-.144h5.657a.48.48 0 01.353.144.48.48 0 01.144.353v5.657a.48.48 0 01-.144.354.48.48 0 01-.353.143.48.48 0 01-.354-.143l-1.414-1.414-1.237 1.237c-.361.36-.673.695-.937 1.003-.263.307-.507.63-.732.97a4.471 4.471 0 00-.5.969c-.108.307-.175.63-.199.966-.024.337.015.67.116 1 .102.33.267.674.498 1.033.23.36.53.725.903 1.097.202.203.438.42.707.652.022.022.07.06.144.116.073.055.127.1.16.132a.29.29 0 01.09.185.228.228 0 01-.074.185c-.059.06-.141.08-.248.061a.887.887 0 01-.194-.05 15.294 15.294 0 01-.43-.165c-1.518-.582-2.582-1.179-3.194-1.79z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowAlias
