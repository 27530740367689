import * as React from 'react'
function SvgUploadDisc() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 13c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v4c0 .417-.146.77-.438 1.063A1.447 1.447 0 0119.5 20h-15c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 013 18.5v-4c0-.417.146-.77.438-1.063A1.446 1.446 0 014.5 13H9v-1H7c-.438 0-.797-.146-1.078-.438a1.514 1.514 0 01-.438-1.046c-.01-.407.141-.766.453-1.079l5-5A1.446 1.446 0 0112 4c.417 0 .77.146 1.063.438l5 5c.312.312.463.671.453 1.078-.01.406-.157.755-.438 1.046-.281.292-.64.438-1.078.438h-2v1h4.5zM7 10.5h3.5v5h3v-5H17l-5-5-5 5zm12.5 8v-4H15v1c0 .417-.146.77-.438 1.063A1.447 1.447 0 0113.5 17h-3c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 019 15.5v-1H4.5v4h15zm-1.25-2a.723.723 0 01-.219.531.723.723 0 01-.531.219.723.723 0 01-.531-.219.723.723 0 01-.219-.531c0-.208.073-.385.219-.531a.723.723 0 01.531-.219c.208 0 .385.073.531.219a.724.724 0 01.219.531z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUploadDisc
