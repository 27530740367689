import * as React from 'react'
function SvgFilesUserInsights() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 5.5A.5.5 0 008 6v1h9a1.5 1.5 0 011.5 1.5v4h1a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5h-11zm10 8h1A1.5 1.5 0 0021 12V6a1.5 1.5 0 00-1.5-1.5h-11A1.5 1.5 0 007 6v1H5a1.5 1.5 0 00-1.5 1.5v6A1.5 1.5 0 005 16h2.5v4.123L12.08 16H17a1.5 1.5 0 001.5-1.5v-1zM5 8a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h3.5v2.877L11.697 15H17a.5.5 0 00.5-.5v-6A.5.5 0 0017 8H5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesUserInsights
