import * as React from 'react'
function SvgUnsupportedFormat() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.963 18.773c.063-.343.01-.682-.156-1.015l-7.5-13a1.401 1.401 0 00-.797-.672 1.59 1.59 0 00-1.031 0c-.344.115-.61.338-.797.672l-7.5 13a1.585 1.585 0 00-.156 1.015c.062.344.229.636.5.875.27.24.593.36.968.36h15c.375 0 .698-.12.969-.36.27-.24.438-.53.5-.875zM12.432 5.258l7.5 13a.505.505 0 01-.016.5.455.455 0 01-.422.25h-15a.455.455 0 01-.422-.25.504.504 0 01-.015-.5l7.5-13a.49.49 0 01.437-.25.49.49 0 01.438.25zm.203 3.86a.401.401 0 00-.297-.11h-.688a.4.4 0 00-.296.11.277.277 0 00-.079.265l.22 5.281c0 .083.036.162.109.234.073.073.161.11.265.11h.25a.362.362 0 00.266-.11.362.362 0 00.11-.265l.218-5.25a.277.277 0 00-.078-.266zm.234 7.39a.848.848 0 00-.25-.625.848.848 0 00-.625-.25.848.848 0 00-.625.25.848.848 0 00-.25.625c0 .25.084.458.25.625.167.166.375.25.625.25s.459-.084.625-.25a.848.848 0 00.25-.625z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUnsupportedFormat
