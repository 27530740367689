import { FormikBag, FormikProps, withFormik } from 'formik'
import { compose } from 'recompose'
import * as yup from 'yup'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { submissionErrorsHandler } from '@apps/accounts/src/utils/forms'
import FormPage from './FormPage'
import fetchApi from '@apps/accounts/src/utils/fetch-api'
import { Response } from 'src/pages/api/login.api'
import { requestOpenApi } from '@packages/common/src/providers/network/client'
import { withToken as loginTwiceQuery } from '@packages/common/src/providers/network/queries/auth-tokens/login'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import {
  WithAlertActions,
  withAlertActions,
} from '@apps/accounts/src/components/AlertProvider'
import errorToAlert from '@apps/accounts/src/features/Workspace/SignInPage/error-to-alert'
import createSignInUrl from '@apps/accounts/src/utils/create-sign-in-url'
import withAbortSignalOnUnmount from '@packages/common/src/utils/hoc/with-abort-signal-on-unmount'
import { pathConstructor } from '@apps/accounts/src/pages/launch.helpers'

export type FormValues = {
  email: string
  password: string
}

type OuterProps = {
  autofillEmail?: string
  backUrl: string
  origin: string
}

type InnerProps = FormikProps<FormValues> &
  WithAlertActions &
  WithRouterProps & {
    t: TFunction
    signal: AbortSignal
  }

type Props = InnerProps & OuterProps

const handleSubmit = async (
  { email, password }: FormValues,
  { props: { backUrl, router, origin, signal } }: FormikBag<Props, FormValues>
) => {
  const res = await fetchApi<Response>('/api/login', {
    method: 'post',
    body: { email, password },
  })

  if (origin === 'desktop') {
    const { token } = await requestOpenApi(loginTwiceQuery(res.token), {
      signal,
    })

    const auth = {
      token,
      workspaceId: res.user.workspaceId,
      userId: res.user.id,
    }
    const fallbackAuth = {
      token: auth.token,
      workspaceId: res.user.workspaceId,
      userId: res.user.id,
    }
    router.push(pathConstructor.index(auth, fallbackAuth, backUrl))
  } else {
    window.location.href = createSignInUrl(
      {
        token: res.token,
        workspaceId: res.user.workspaceId,
        userId: res.user.id,
      },
      backUrl
    )
  }
}

const handleSubmissionErrors = submissionErrorsHandler<Props>(
  {},
  (error, { props: { setAlert } }) => {
    setAlert(errorToAlert(error))
  }
)

const validationSchema = ({ t }: InnerProps) =>
  yup.object().shape<Partial<FormValues>>({
    password: yup
      .string()
      .required(t('sign-in.form.validations.password.required')),
    email: yup
      .string()
      .required(t('sign-in.form.validations.email.required'))
      .email(t('sign-in.form.validations.email.email')),
  })

const initialValues: FormValues = {
  email: '',
  password: '',
}

export default compose<Props, OuterProps>(
  withRouter,
  withAlertActions,
  withAbortSignalOnUnmount,
  withTranslation(),
  withFormik({
    validationSchema,
    handleSubmit: handleSubmissionErrors(handleSubmit),
    mapPropsToValues: ({ autofillEmail }) => ({
      ...initialValues,
      email: autofillEmail || initialValues.email,
    }),
    validateOnBlur: false,
    validateOnChange: false,
  })
)(FormPage)
