import * as React from 'react'
function SvgPanelCommandPaletteInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.943 12.236a.286.286 0 000-.411L7.496 7.087a.286.286 0 00-.411 0l-.998 1.024a.286.286 0 000 .41l3.509 3.51-3.509 3.508a.286.286 0 000 .411l.998.938a.286.286 0 00.41 0l4.448-4.652zm5.796 3.46c0-.161-.084-.286-.192-.286h-5.759c-.108 0-.192.125-.192.285v1.192c0 .161.084.286.192.286h5.759c.108 0 .192-.125.192-.285v-1.193z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelCommandPaletteInverted
