import * as React from 'react'
function SvgFilesIntegrationsPuzzle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10a2 2 0 100-4 2 2 0 000 4zm0 1a3 3 0 10-2.318-1.096l-3.778 3.778a3 3 0 10.658.757l3.876-3.877c.455.278.99.438 1.562.438zm-8 7a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesIntegrationsPuzzle
