import * as React from 'react'
function SvgSearch() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-.787 4.713a6 6 0 111.053-1.068l3.295 3.294A.75.75 0 1118 19l-3.287-3.287z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSearch
