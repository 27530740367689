import * as React from 'react'
function SvgFolderDrafts() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.54 5.554a1.5 1.5 0 00-2.176-.239l-5.173 4.434-.005.004a2.5 2.5 0 00-.868 1.105l-1.125 2.813 2.9-.58a2.5 2.5 0 001.124-.542l5.386-4.558a1.5 1.5 0 00.231-2.045l-.294-.392zm-7.293 5.676c.053-.133.124-.256.211-.367l.913 1.065c-.146.087-.307.15-.475.183l-1.089.218.44-1.1zm6.71-4.003l-4.81 4.07-.942-1.1 4.81-4.123a.5.5 0 01.725.08l.294.392a.5.5 0 01-.077.681zM10 7h5.813l-1.167 1H9.75a.723.723 0 01-.531-.219A.723.723 0 019 7.25v-.5a.723.723 0 00-.219-.531A.723.723 0 008.25 6h-2.5a.723.723 0 00-.531.219A.723.723 0 005 6.75V9h8.48l-1.168 1H5v7.25c0 .208.073.385.219.531A.723.723 0 005.75 18h12.5a.723.723 0 00.531-.219.723.723 0 00.219-.531v-5.625l1-.938v6.563c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderDrafts
