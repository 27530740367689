import * as React from 'react'
function SvgEmbedZoomIn() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18.77c0-.328-.135-.645-.356-.866l-3.298-3.298A6.767 6.767 0 0010.77 4 6.767 6.767 0 004 10.77a6.767 6.767 0 0010.606 5.576l3.298 3.289c.221.23.538.365.865.365A1.24 1.24 0 0020 18.77zm-4.438-8a4.798 4.798 0 01-4.793 4.792 4.798 4.798 0 01-4.792-4.793 4.798 4.798 0 014.792-4.792 4.798 4.798 0 014.793 4.792zM10.7 7.816c.528 0 .957.428.957.957v.982h1a.939.939 0 110 1.877h-1v.982a.957.957 0 01-1.914 0v-.982H8.743a.939.939 0 110-1.877h1v-.982c0-.529.43-.957.958-.957z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgEmbedZoomIn
