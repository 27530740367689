import * as React from 'react'
import { TestableProps } from './Stylo'
import { useFormikContext } from 'formik'
import ButtonFacade, {
  Props as ButtonFacadeProps,
} from '@packages/common/src/components/ButtonFacade'
import { Style } from '../providers/style/types'
import { Props as TextProps } from './Text'

export type Props = TestableProps & {
  onClick?: React.MouseEventHandler
  onMouseDown?: React.MouseEventHandler
  onMouseUp?: React.MouseEventHandler
  onAuxClick?: React.MouseEventHandler
  scale?: -1 | 0
  isReadOnly?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  children: ButtonFacadeProps['children']
  type?: 'submit' | 'button'
  accent?: 'primary' | 'secondary' | 'error' | 'warning'
  autoFocus?: boolean
  focusIntent?: 'keyboard' | 'any'
  style?: Style
  align?: 'left' | 'center' | 'right'
  borderStyle?: 'dashed'
  fontWeight?: TextProps['weight']
  nativeRef?: React.Ref<any>
}

const Button = ({
  children,
  style,
  accent,
  isLoading,
  isReadOnly,
  isDisabled,
  scale,
  testID,
  type = 'button',
  focusIntent,
  align,
  borderStyle,
  nativeRef,
  fontWeight,
  ...nativeProps
}: Props) => (
  <ButtonFacade
    as="button"
    isLoading={isLoading}
    isReadOnly={isReadOnly}
    scale={scale}
    accent={accent}
    style={style}
    focusIntent={focusIntent}
    align={align}
    borderStyle={borderStyle}
    fontWeight={fontWeight}
    nativeProps={{
      ...nativeProps,
      type,
      readOnly: isReadOnly,
      disabled: isDisabled,
      // @ts-ignore
      ref: nativeRef,
    }}
    testID={testID}>
    {children}
  </ButtonFacade>
)

type SubmitProps = Props & {
  canSubmitPristine?: boolean
}

export const SubmitButton = ({
  canSubmitPristine = true,
  testID = 'submit-button',
  ...props
}: SubmitProps) => {
  const { isSubmitting, dirty } = useFormikContext()

  return (
    <Button
      isLoading={isSubmitting}
      isDisabled={!dirty && !canSubmitPristine}
      isReadOnly={isSubmitting}
      type="submit"
      testID={testID}
      {...props}
    />
  )
}

export default Button
