import * as React from 'react'
function SvgCloudTick2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.064 6.717a3.846 3.846 0 011.905-.498c.761 0 1.465.234 2.11.644.614.44 1.054.996 1.347 1.67.351-.293.761-.44 1.23-.44.528 0 .967.206 1.319.557.351.352.556.791.556 1.319 0 .351-.088.644-.263.937h.263c.762 0 1.436.293 1.992.82.528.557.82 1.231.82 1.993a2.74 2.74 0 01-.82 1.992 2.72 2.72 0 01-1.992.82H7.688a3.202 3.202 0 01-1.641-.44 2.964 2.964 0 01-1.201-1.2 3.202 3.202 0 01-.44-1.641c0-.82.264-1.523.791-2.139A3.182 3.182 0 017.22 9.998v-.03c0-.673.146-1.288.498-1.874a3.577 3.577 0 011.347-1.377zm4.16-1.377a5.005 5.005 0 00-2.255-.527c-1.23 0-2.344.41-3.281 1.171a4.933 4.933 0 00-1.758 2.93 4.584 4.584 0 00-2.139 1.729A4.625 4.625 0 003 13.25c0 .85.205 1.64.615 2.344.41.732.996 1.318 1.729 1.728.703.41 1.494.616 2.343.616h9.844a4.06 4.06 0 002.11-.557 4.3 4.3 0 001.523-1.553c.38-.644.586-1.348.586-2.11 0-.878-.264-1.698-.791-2.43-.527-.733-1.201-1.26-2.021-1.554a3.137 3.137 0 00-1.055-2.168c-.645-.586-1.377-.878-2.227-.878-.234 0-.44.029-.644.058a5.6 5.6 0 00-1.787-1.406zm2.787 5.71a.75.75 0 00-1.022-1.099l-3.863 3.595-1.008-1.47a.75.75 0 10-1.237.848l1.5 2.188a.75.75 0 001.13.125l4.5-4.188z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudTick2
