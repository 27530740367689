import { buildQuery } from '@packages/common/src/providers/network/open-api-query'

const validator = (tokens: string[]) =>
  buildQuery('/auth_tokens/validator', 'post', 200, {
    data: {
      authTokens: tokens,
    },
  })

export default validator
