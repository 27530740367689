import * as React from 'react'
function SvgFolderAliasInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 0019.25 7H11v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 009.25 5h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 005 6.75V9h16zm0 1H5v3h2v5l-.819.909c.178.06.367.091.569.091h12.5c.48 0 .89-.172 1.234-.516.344-.343.516-.755.516-1.234V10zM3.935 21.387c-.76-.29-1.291-.59-1.597-.895-.366-.366-.624-.722-.773-1.066-.444-1.04-.01-2.217 1.303-3.53l.62-.619-.708-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.177-.071h2.828a.24.24 0 01.177.072.24.24 0 01.072.176v2.829a.24.24 0 01-.072.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.618.62c-.18.18-.337.347-.469.5a4.944 4.944 0 00-.366.485c-.112.17-.195.331-.25.485a1.834 1.834 0 00-.099.483 1.36 1.36 0 00.058.5c.05.165.134.337.249.517.115.18.265.362.451.548a6.594 6.594 0 00.426.384c.036.028.063.05.08.066a.146.146 0 01.045.093.114.114 0 01-.037.092c-.03.03-.07.04-.124.03a.441.441 0 01-.097-.024 7.724 7.724 0 01-.215-.083z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderAliasInverted
