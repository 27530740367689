import * as React from 'react'
function SvgClose() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.094 11.988l3.135 3.164a.39.39 0 01.146.322.39.39 0 01-.146.322l-.733.733a.39.39 0 01-.322.146.39.39 0 01-.322-.146l-3.165-3.135-3.164 3.135a.39.39 0 01-.322.146.39.39 0 01-.322-.146l-.733-.733A.39.39 0 017 15.474a.39.39 0 01.146-.322l3.135-3.164-3.135-3.165A.39.39 0 017 8.501a.39.39 0 01.146-.322l.733-.733A.39.39 0 018.2 7.3a.39.39 0 01.322.146l3.165 3.135 3.163-3.135a.39.39 0 01.323-.146.39.39 0 01.322.146l.732.733a.39.39 0 01.147.322.39.39 0 01-.146.322l-3.135 3.165z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgClose
