import * as React from 'react'
import InputSingleLine, {
  Props as SingleLineLineProps,
} from '@packages/common/src/components/TextInput/pure/InputSingleLine'
import useFormikFieldWithCustomEvents from './use-formik-field-with-custom-events'

type Props = Omit<SingleLineLineProps, 'value'>

const FormSingleLine = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, onBlur, ...props }, ref) => {
    const field = useFormikFieldWithCustomEvents({ onChange, onBlur })

    return <InputSingleLine {...props} {...field} ref={ref} />
  }
)

export default FormSingleLine
