import * as React from 'react'
function SvgPageSharedLockedTemplate() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4.333V5h1v6h-2v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 19.25V11h1v8h10v-8h-4V7H8V6h4V5h1v-.667C13 3.053 14.25 3 15.5 3s2.5.052 2.5 1.333zM14 5h3v-.667C17 4 16.603 4 15.501 4 14.4 4 14 4 14 4.333V5zm0 10h1v1h-1v2h-2v-2h-1v2H9v-2H8v-1h6zm-2-1v-2h2v2h-2zm-3-2h2v2H9v-2zm4-6v4h5V6h-5zm3 1v2h-1V7h1zM2 5v2l1-1h1v3l-1 1h3L5 9V6h1l1 1V5H2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedLockedTemplate
