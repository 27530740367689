import * as React from 'react'
function SvgPageSharedLockedAliasInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.333V4h1v6h-7V4h1v-.667C13 2.053 14.25 2 15.5 2s2.5.052 2.5 1.333zM14 4h3v-.667C17 3 16.603 3 15.501 3 14.4 3 14 3 14 3.333V4zM5.75 6H11v5h6v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219.739.739 0 01-.164-.24L7 19v-5H5V6.75c0-.208.073-.385.219-.531A.723.723 0 015.75 6zM16 6v2h-1V6h1zm-1 9H8v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-1v-2h-2v2h2zm-3-2H9v2h2v-2zM3.938 22.387c-.759-.29-1.29-.59-1.596-.895-.367-.366-.625-.722-.774-1.066-.444-1.04-.009-2.217 1.304-3.53l.619-.619-.707-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.176-.071H5.79a.24.24 0 01.177.072.24.24 0 01.071.176v2.829a.24.24 0 01-.071.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.619.62c-.18.18-.336.347-.468.5a4.944 4.944 0 00-.366.485c-.112.17-.196.331-.25.485a1.834 1.834 0 00-.1.483 1.36 1.36 0 00.059.5c.05.165.133.337.248.517.115.18.266.362.452.548a6.594 6.594 0 00.425.384c.037.028.064.05.08.066a.146.146 0 01.046.093.114.114 0 01-.037.092c-.03.03-.071.04-.125.03a.441.441 0 01-.096-.024 7.724 7.724 0 01-.216-.083z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedLockedAliasInverted
