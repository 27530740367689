import * as React from 'react'
function SvgUserFeedbackMessage() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 6h-13A1.5 1.5 0 004 7.5v9A1.5 1.5 0 005.5 18h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0018.5 6zm-13 1h13c.275 0 .5.225.5.5v.294c-.684.578-1.663 1.375-4.706 3.79-.528.42-1.569 1.428-2.294 1.416-.725.012-1.769-.997-2.294-1.416C6.663 9.17 5.684 8.372 5 7.794V7.5c0-.275.225-.5.5-.5zm13 10h-13a.501.501 0 01-.5-.5V9.094c.713.584 1.838 1.487 4.084 3.272.641.512 1.772 1.64 2.916 1.634 1.137.01 2.26-1.11 2.916-1.634A369.164 369.164 0 0019 9.094V16.5c0 .275-.225.5-.5.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUserFeedbackMessage
