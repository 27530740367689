import * as React from 'react'
function SvgHighlight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.979 15.75l-1.17 1.17H5.053l1.964-1.898V12.5l.36-.353 3.253 3.305-.304.298H7.98zm3.375-1.009l-3.253-3.305L13.636 6 20 6.25l-8.646 8.491zM5 17h2l12 2H5v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHighlight
