import * as React from 'react'
function SvgFilesReviewRequestsV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.5A.5.5 0 015 6h14a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-6.376L9.5 19.863V17H5a.5.5 0 01-.5-.5v-10zM5 5a1.5 1.5 0 00-1.5 1.5v10A1.5 1.5 0 005 18h3.5v4.137L13.013 18H19a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0019 5H5zm11.354 4.854a.5.5 0 00-.708-.708L11.5 13.293l-2.146-2.147a.5.5 0 00-.708.708l2.854 2.853 4.854-4.853z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesReviewRequestsV2
