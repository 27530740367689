import * as React from 'react'
function SvgArrowBendedLeftUp() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 19c.865 0 2.04-.33 3.523-.992a22.123 22.123 0 00.422-.188.882.882 0 00.172-.101c.089-.063.133-.136.133-.219a.228.228 0 00-.078-.184.291.291 0 00-.195-.066c-.047 0-.116.006-.207.02a1.6 1.6 0 01-.184.02c-.354.025-.674.038-.961.038-.526 0-.997-.045-1.414-.137a4.243 4.243 0 01-1.082-.378 2.722 2.722 0 01-.79-.625 3.669 3.669 0 01-.542-.825 4.471 4.471 0 01-.332-1.039c-.08-.398-.137-.8-.168-1.203-.031-.404-.047-.86-.047-1.371V10h2a.48.48 0 00.352-.148A.48.48 0 0015 9.5a.48.48 0 00-.148-.352l-4-4A.48.48 0 0010.5 5a.48.48 0 00-.352.148l-4 4A.48.48 0 006 9.5c0 .135.05.253.148.352A.48.48 0 006.5 10h2v1.75c0 3.714 1.05 5.992 3.148 6.836.698.276 1.566.414 2.602.414z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowBendedLeftUp
