import * as React from 'react'
function SvgCloudOfflineInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9 12.002c-.585-.674-1.289-1.113-2.138-1.29.117-.321.175-.673.175-1.024 0-.762-.293-1.436-.82-1.993-.556-.527-1.23-.82-1.992-.82-.586 0-1.084.176-1.553.469-.41-.703-.996-1.26-1.699-1.7C12.141 5.235 11.35 5 10.5 5c-.85 0-1.64.234-2.344.645a4.585 4.585 0 00-1.728 1.699 4.75 4.75 0 00-.615 2.343v.235c-.82.293-1.495.82-2.022 1.523A4.111 4.111 0 003 13.906c0 .762.176 1.465.557 2.11a4.38 4.38 0 001.552 1.552 4.06 4.06 0 002.11.557H18c.674 0 1.29-.146 1.875-.498a3.579 3.579 0 001.377-1.348 3.847 3.847 0 00.498-1.904c0-.879-.293-1.67-.85-2.373zM10.53 9.657a.75.75 0 10-1.06 1.06l1.72 1.72-1.72 1.72a.75.75 0 101.06 1.06l1.72-1.719 1.72 1.72a.75.75 0 101.06-1.06l-1.72-1.72 1.72-1.72a.75.75 0 10-1.06-1.06l-1.72 1.719-1.72-1.72z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudOfflineInverted
