import * as React from 'react'
function SvgChevronSmallDownBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 15.646a.5.5 0 00.707 0l.708-.707 4.95-4.95a.5.5 0 000-.707l-.708-.707a.5.5 0 00-.707 0l-4.596 4.597-4.596-4.597a.5.5 0 00-.707 0l-.707.707a.5.5 0 000 .708l4.95 4.95.706.706z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDownBold
