import * as React from 'react'
function SvgCloseBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.304 10.89L7.708 6.294a1.005 1.005 0 00-1.414 0 .998.998 0 000 1.414l4.596 4.596-4.596 4.597a1.004 1.004 0 000 1.414.997.997 0 001.414 0l4.596-4.597 4.597 4.597c.385.385 1.023.39 1.414 0a.997.997 0 000-1.414l-4.597-4.597 4.597-4.596a1.004 1.004 0 000-1.414.997.997 0 00-1.414 0l-4.597 4.596z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloseBig
