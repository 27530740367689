import * as React from 'react'
function SvgInsight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.536 16H12.464l-.014.001a4.333 4.333 0 01-.304-.003 5.2 5.2 0 01-.717-.073 2.078 2.078 0 01-.435-.12c-.042-.668-.288-1.307-.565-1.87-.253-.517-.577-1.057-.885-1.572a101.57 101.57 0 01-.184-.309C8.594 10.766 8 9.624 8 8.5 8 5.954 10.007 4 12.5 4S17 5.954 17 8.5c0 1.384-.61 2.543-1.348 3.722-.083.134-.17.27-.26.41-.281.442-.577.907-.808 1.347-.283.537-.536 1.154-.578 1.826-.093.04-.237.084-.435.12a5.2 5.2 0 01-1.021.076h-.014zm3.682-2.802C17.05 11.893 18 10.4 18 8.5 18 5.393 15.538 3 12.5 3S7 5.393 7 8.5c0 1.562.885 3.04 1.694 4.39C9.374 14.024 10 15.07 10 16v3.5l.1.133c.112.15.294.262.43.337.159.087.352.173.56.25.41.15.93.28 1.41.28.48 0 1-.13 1.41-.28.208-.077.401-.163.56-.25.136-.075.318-.187.43-.337l.1-.133V16c0-.89.573-1.789 1.218-2.802zM14 16.855c-.696.174-1.5.145-1.5.145s-.804.03-1.5-.145v2.232l.01.006c.108.059.254.125.425.188.347.127.745.219 1.065.219.32 0 .718-.092 1.066-.22.17-.062.316-.128.423-.187l.011-.006v-2.232zM13 7h-1v2h-2v1h2v2h1v-2h2V9h-2V7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInsight
