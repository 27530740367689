import * as React from 'react'
function SvgEmbedOpenInBrowser() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.053 9.632l-1.895-1.895-7.58 7.579-1.894-1.895 7.58-7.579-1.896-1.895L21 3l-.947 6.632zm-15.158 9.47H17.21v-4.738l1.894-1.894V19.1a1.9 1.9 0 01-1.895 1.895H4.896A1.9 1.9 0 013 19.101V6.785a1.9 1.9 0 011.895-1.894h6.631L9.632 6.785H4.895v12.316z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgEmbedOpenInBrowser
