import * as React from 'react'
function SvgTableInsertionPoint() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16V8h1V6h-4v2h1v8h-1v2h4v-2h-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTableInsertionPoint
