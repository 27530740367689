import * as React from 'react'
function SvgMarkProcessed() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={7.5} stroke="currentColor" />
      <path
        d="M8.5 12.5L11 15l5-5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default SvgMarkProcessed
