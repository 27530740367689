import * as React from 'react'
function SvgTyping() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5H6c-1.101 0-2 .9-2 2v7c0 1.1.899 2 2 2h4l4 3v-3h4c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-2 6.6a1.1 1.1 0 110-2.2 1.1 1.1 0 010 2.2zm-4 0a1.1 1.1 0 110-2.2 1.1 1.1 0 010 2.2zm-4 0a1.1 1.1 0 110-2.2 1.1 1.1 0 010 2.2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTyping
