import * as React from 'react'
function SvgCollapseRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 8.832a.4.4 0 01.65-.312l3.96 3.168a.4.4 0 010 .624l-3.96 3.168a.4.4 0 01-.65-.312V8.832z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCollapseRight
