import * as React from 'react'
function SvgCompany() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4h12v16h-4v-4h-4v4H6V4zM4 22V2h16v20H4zm7-16H8v3h3V6zm-3 5h3v3H8v-3zm5-5h3v3h-3V6zm3 5h-3v3h3v-3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCompany
