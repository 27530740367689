import * as React from 'react'
import View from '@packages/common/src/components/View'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'

export type Props = OuterProps & ControlContext

type OuterProps = {
  withError?: boolean
  children: React.ReactNode
  expand?: boolean
}

type ControlContext = {
  id?: string
  name: string
  variant?: 'regular' | 'compact'
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  defaultValue?: string
  maxLength: number
  placeholder?: string
  spellCheck?: boolean
  withError?: boolean
}

const styles = cmpStyles({
  box: ({ ctx: { withError } }) => ({
    position: 'relative',
    zIndex: withError ? 1 : 0,
    ':focus-within': {
      zIndex: 2,
    },
  }),
})

// @ts-ignore values are initialized immediately
export const controlContext = React.createContext<ControlContext>({})

const Control = ({ children, expand, ...contextValue }: Props) => (
  <controlContext.Provider value={contextValue}>
    <View as="label" style={styles.box} ctx={contextValue} expand={expand}>
      {children}
    </View>
  </controlContext.Provider>
)

export default Control
