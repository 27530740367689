import * as React from 'react'
function SvgSync() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.591 15.394c1.71-1.839 1.68-4.721-.097-6.511a4.592 4.592 0 00-2.581-1.313L12.965 6a6.134 6.134 0 013.627 1.778c2.381 2.397 2.41 6.267.093 8.718l1.317 1.325-4.167.228.012-4.412 1.744 1.757zm-5.026-9.042l-.011 4.41L8.81 9.008c-1.71 1.84-1.68 4.723.096 6.51a4.59 4.59 0 002.582 1.314l-.052 1.57a6.141 6.141 0 01-3.628-1.778c-2.38-2.398-2.41-6.269-.093-8.718L6.4 6.578l4.166-.226z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSync
