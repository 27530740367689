import * as React from 'react'
import View from '@packages/common/src/components/View'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'

type Props = { children: React.ReactNode }

/*
Set css variables only.
It's easier and more generic than passing props or setting a context. This way
works with any component.
 */

export const getBorderRadiusStyleInInputsGroup = (
  borderRadiusBase: number
): React.CSSProperties => ({
  borderTopLeftRadius: `calc(var(--comp-inputs-group-enable-border-radius-top, 1) * var(--comp-inputs-group-enable-border-radius-left, 1) * ${borderRadiusBase}px)`,
  borderTopRightRadius: `calc(var(--comp-inputs-group-enable-border-radius-top, 1) * var(--comp-inputs-group-enable-border-radius-right, 1) * ${borderRadiusBase}px)`,
  borderBottomLeftRadius: `calc(var(--comp-inputs-group-enable-border-radius-bottom, 1) * var(--comp-inputs-group-enable-border-radius-left, 1) * ${borderRadiusBase}px)`,
  borderBottomRightRadius: `calc(var(--comp-inputs-group-enable-border-radius-bottom, 1) * var(--comp-inputs-group-enable-border-radius-right, 1) * ${borderRadiusBase}px)`,
})

export const getMarginStyleInInputsGroup = (
  defaultMargin: number
): React.CSSProperties => ({
  marginTop: `calc(var(--comp-inputs-group-enable-margin-top, 1) * ${defaultMargin}px)`,
  marginBottom: `calc(var(--comp-inputs-group-enable-margin-bottom, 1) * ${defaultMargin}px)`,
  marginLeft: `calc(var(--comp-inputs-group-enable-margin-left, 1) * ${defaultMargin}px)`,
  marginRight: `calc(var(--comp-inputs-group-enable-margin-right, 1) * ${defaultMargin}px)`,
})

const styles = cmpStyles({
  row: {
    ':first-child:not(:last-child)': {
      '--comp-inputs-group-enable-border-radius-top': 1,
      '--comp-inputs-group-enable-border-radius-bottom': 0,
      '--comp-inputs-group-enable-margin-top': 0,
      '--comp-inputs-group-enable-margin-bottom': 0,
    },
    ':last-child:not(:first-child)': {
      '--comp-inputs-group-enable-border-radius-top': 0,
      '--comp-inputs-group-enable-border-radius-bottom': 1,
      '--comp-inputs-group-enable-margin-top': 0,
      '--comp-inputs-group-enable-margin-bottom': 0,
    },
    ':not(:first-child):not(:last-child)': {
      '--comp-inputs-group-enable-border-radius-top': 0,
      '--comp-inputs-group-enable-border-radius-bottom': 0,
      '--comp-inputs-group-enable-margin-top': 0,
      '--comp-inputs-group-enable-margin-bottom': 0,
    },
    ':first-child:last-child': {
      '--comp-inputs-group-enable-border-radius-top': 1,
      '--comp-inputs-group-enable-border-radius-bottom': 1,
      '--comp-inputs-group-enable-margin-bottom': 1,
    },
  },
  col: {
    flex: `1 1`,
    flexDirection: 'column',
    ':first-child:not(:last-child)': {
      '--comp-inputs-group-enable-border-radius-left': 1,
      '--comp-inputs-group-enable-border-radius-right': 0,
      '--comp-inputs-group-enable-margin-left': 1,
      '--comp-inputs-group-enable-margin-right': 0,
    },
    ':last-child:not(:first-child)': {
      '--comp-inputs-group-enable-border-radius-left': 0,
      '--comp-inputs-group-enable-border-radius-right': 1,
      '--comp-inputs-group-enable-margin-left': 0,
      '--comp-inputs-group-enable-margin-right': 1,
    },
    ':not(:first-child):not(:last-child)': {
      '--comp-inputs-group-enable-border-radius-left': 0,
      '--comp-inputs-group-enable-border-radius-right': 0,
      '--comp-inputs-group-enable-margin-left': 0,
      '--comp-inputs-group-enable-margin-right': 0,
    },
    ':first-child:last-child': {
      '--comp-inputs-group-enable-margin-left': 1,
      '--comp-inputs-group-enable-margin-right': 1,
    },
  },
})

const InputsGroup = ({ children }: Props) => <View isVertical>{children}</View>

InputsGroup.Row = ({ children }: Props) => (
  <View style={styles.row}>{children}</View>
)

InputsGroup.Col = ({ children }: Props) => (
  <View style={styles.col}>{children}</View>
)

export default InputsGroup
