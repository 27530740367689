import * as React from 'react'
function SvgPageLockedInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5v-.667C18 3.053 16.75 3 15.5 3S13 3.052 13 4.333V5h-1v6h7V5h-1zm-1 0h-3v-.667C14 4 14.4 4 15.501 4 16.603 4 17 4 17 4.333V5zm-6 2H5.75a.723.723 0 00-.531.219A.723.723 0 005 7.75v12.5c0 .208.073.385.219.531A.723.723 0 005.75 21h10.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V12h-6V7zm-3 7v-1h6v1H8zm6 2v-1H8v1h6zm-6 1h5v1H8v-1zm8-8V7h-1v2h1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageLockedInverted
