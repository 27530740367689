import * as React from 'react'
function SvgNotificationsUi() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.364 12.705l1.092 3.568H7.512l1.092-3.568c.13-.427.24-.873.315-1.338.136-.845.386-2.564.386-3.861 0-.708.346-1.225.922-1.624.622-.431 1.37-.61 1.757-.61.442 0 1.188.16 1.78.554.528.35.898.849.898 1.68 0 1.297.25 3.016.386 3.861.075.465.185.911.316 1.338zm-7.559-5.2c0-2.639 2.786-3.733 4.179-3.733 1.393 0 4.178.9 4.178 3.734 0 1.164.231 2.775.367 3.624.062.385.155.763.269 1.136l1.29 4.214a1 1 0 01-.956 1.293H6.836a1 1 0 01-.956-1.293l1.29-4.214c.114-.373.206-.751.268-1.136.136-.849.367-2.46.367-3.624zm3.972 13.09c2 0 2-1.53 2-1.53h-4s0 1.53 2 1.53z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgNotificationsUi
