import React from 'react'

const useAbortSignalOnUnmount = () => {
  const controller = React.useRef(new AbortController())

  React.useEffect(
    () => () => {
      controller.current.abort()
    },
    []
  )

  return controller.current.signal
}

export default useAbortSignalOnUnmount
