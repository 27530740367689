import * as React from 'react'
function SvgFilesUserInsightsV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 5a.5.5 0 00-.5.5v1h9A1.5 1.5 0 0118.5 8v4h1a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-11zm10 8h1a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0019.5 4h-11A1.5 1.5 0 007 5.5v1H5A1.5 1.5 0 003.5 8v6A1.5 1.5 0 005 15.5h2.5v4.123l4.58-4.123H17a1.5 1.5 0 001.5-1.5v-1zM5 7.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h3.5v2.877l3.197-2.877H17a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5H5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesUserInsightsV2
