import * as React from 'react'
function SvgFilesInputRequest() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.781 19.281A.723.723 0 0019 18.75V6.25c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H7.75a.723.723 0 00-.531.219A.723.723 0 007 6.25V9.5h1v-3h10v12H8v-2H7v2.25c0 .208.073.385.219.531a.723.723 0 00.531.219h10.5a.723.723 0 00.531-.219zm-8.66-9.606a.5.5 0 01.704-.055L14.768 13l-3.943 3.38a.5.5 0 11-.65-.76l2.473-2.12H5.915a1.5 1.5 0 110-1h6.733l-2.473-2.12a.5.5 0 01-.055-.705z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesInputRequest
