import * as React from 'react'
function SvgCloudUpload() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.71 11.332a3.412 3.412 0 011.997 1.203 3.37 3.37 0 01.793 2.215 3.47 3.47 0 01-.465 1.764A3.47 3.47 0 0117 18.25H6.937c-.71 0-1.367-.178-1.968-.533a4.013 4.013 0 01-1.436-1.436A3.8 3.8 0 013 14.313c0-.84.246-1.6.738-2.284a3.9 3.9 0 011.887-1.435v-.219c0-.784.196-1.513.588-2.188a4.344 4.344 0 011.6-1.6 4.305 4.305 0 012.2-.587c.794 0 1.527.2 2.202.602.674.4 1.203.93 1.586 1.585.437-.291.92-.437 1.449-.437.73 0 1.349.255 1.86.766.51.51.765 1.13.765 1.859 0 .328-.055.647-.164.957zm-3.827 1.723a.633.633 0 00.191-.465.633.633 0 00-.191-.465L11.34 9.609a.633.633 0 00-.465-.191.633.633 0 00-.465.191l-2.543 2.516a.633.633 0 00-.191.465c0 .182.064.337.191.465l.301.3a.65.65 0 00.478.192.65.65 0 00.479-.192l.875-.957v3.446c0 .182.064.337.191.465a.633.633 0 00.465.191h.438a.633.633 0 00.465-.191.633.633 0 00.191-.465v-3.446l.875.957a.65.65 0 00.479.192.65.65 0 00.478-.192l.3-.3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudUpload
