import * as React from 'react'
import { InlineAlert } from '@packages/common/src/components/Alerts/Inline/type'
import {
  createAlertEmitterCtx,
  useLastEmittedAlert,
  useEmitterActions,
} from '@packages/common/src/components/Alerts/common/alert-ctx'
import AlertEmitter from '@packages/common/src/components/Alerts/common/alert-emitter'
import { AlertConstructor } from '@packages/common/src/components/Alerts/common/types'

export const alertEmitterCtx = createAlertEmitterCtx<InlineAlert>()

export const useLastAlert = () => {
  const emitter = React.useContext(alertEmitterCtx)
  return useLastEmittedAlert(emitter)
}

export const useAlertActions = () => {
  const id = 'global-alert'
  const emitter = React.useContext(alertEmitterCtx)
  const { notify, close } = useEmitterActions(emitter)

  return React.useMemo(
    () => ({
      setAlert: (alert: AlertConstructor<InlineAlert>) => {
        notify({ ...alert, id })
      },
      closeAlert: () => {
        close(id)
      },
    }),
    [notify, close]
  )
}

export type WithAlertActions = {
  setAlert: (alert: AlertConstructor<InlineAlert>) => void
  closeAlert: () => void
}

export const withAlertActions =
  (BaseComponent: React.ComponentType<React.PropsWithChildren>) =>
  (props: any) => {
    const actions = useAlertActions()
    return (
      <BaseComponent
        {...props}
        setAlert={actions.setAlert}
        closeAlert={actions.closeAlert}
      />
    )
  }

class AlertProvider extends React.Component<React.PropsWithChildren> {
  emitter = new AlertEmitter<InlineAlert>()

  render() {
    const { children } = this.props
    return (
      <alertEmitterCtx.Provider value={this.emitter}>
        {children}
      </alertEmitterCtx.Provider>
    )
  }
}

export default AlertProvider
