import * as React from 'react'
function SvgFilesInbox() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.611 11.275c.176.254.264.537.264.85v4.219c0 .39-.137.722-.41.996-.274.273-.606.41-.996.41H5.406c-.39 0-.722-.137-.996-.41a1.356 1.356 0 01-.41-.996v-4.219c0-.313.088-.596.264-.85l3.076-4.19c.117-.194.278-.34.483-.439a1.45 1.45 0 01.63-.146h7.969c.215 0 .425.049.63.146.205.098.366.245.483.44l3.076 4.19zM8.101 7.643l-2.607 3.545h4.014l.937 1.874h3.985l.937-1.874h4.014l-2.608-3.545a.38.38 0 00-.351-.205H8.453a.38.38 0 00-.351.205zm11.836 4.95a.456.456 0 00-.131-.336.456.456 0 00-.337-.132h-3.516L15.016 14H9.859l-.937-1.875H5.406a.456.456 0 00-.337.132.456.456 0 00-.131.337v3.75c0 .136.043.249.131.337.088.087.2.131.337.131H19.47a.456.456 0 00.337-.131.456.456 0 00.131-.337v-3.75z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesInbox
