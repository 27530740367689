import { Auth } from '@apps/accounts/src/features/Workspace/auth'
import { UrlObject } from 'url'
import createSignInUrl from '@apps/accounts/src/utils/create-sign-in-url'

export const queryParams = {
  // url: 'url',
  fallbackUrl: 'fallback-url',
}

export const pathConstructor = {
  index: (
    auth: Auth,
    fallbackAuth: Auth,
    backUrl?: string,
    fallbackBackUrl?: string
  ): UrlObject => ({
    pathname: '/launch',
    query: {
      // [queryParams.url]: createSignInUrl(auth, backUrl),
      [queryParams.fallbackUrl]: createSignInUrl(fallbackAuth, fallbackBackUrl),
    },
  }),
}
