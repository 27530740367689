import * as React from 'react'
import Icon, { Props as IconProps } from './Icon'
import View from './View'
import cmpStyles from '../providers/style/cmp-styles'

const styles = cmpStyles({
  box: ({ isPaused, verticalAlign, display, marginTop }) => ({
    animation: 'rotation 1s infinite linear',
    animationPlayState: isPaused ? 'paused' : 'running',
    willChange: 'transform',
    verticalAlign,
    display,
    marginTop,
  }),
  screen: ({ theme: { components } }) => ({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    ...components.Spinner.Screen,
  }),
})

type Props = {
  scale?: number
  isPaused?: boolean
  color?: IconProps['color']
  verticalAlign?: React.CSSProperties['verticalAlign']
  marginTop?: React.CSSProperties['marginTop']
  display?: React.CSSProperties['display']
}

const Spinner = ({ scale = 0, ...props }: Props) => (
  <Icon name="loader-circle" style={styles.box} scale={scale} {...props} />
)

Spinner.Screen = () => (
  <View style={styles.screen}>
    <Spinner scale={3} />
  </View>
)

export default Spinner
