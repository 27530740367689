import * as React from 'react'
function SvgFolderAlias() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 10v7.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0119.25 19H6.75c-.48 0-.89-.172-1.234-.516a1.856 1.856 0 01-.268-.335l.763-.764c.025.151.095.283.208.396A.723.723 0 006.75 18h12.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V10H6v2H5V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 016.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V10zm-1-1v-.25a.723.723 0 00-.219-.531A.724.724 0 0019.25 8h-8.5a.723.723 0 01-.531-.219A.723.723 0 0110 7.25v-.5a.723.723 0 00-.219-.531A.723.723 0 009.25 6h-2.5a.723.723 0 00-.531.219A.723.723 0 006 6.75V9h14zM4.935 20.387c-.759-.29-1.291-.59-1.597-.895-.366-.366-.624-.722-.773-1.066-.444-1.04-.01-2.217 1.303-3.53l.62-.619-.708-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.177-.071h2.828a.24.24 0 01.177.072.24.24 0 01.072.176v2.829a.24.24 0 01-.072.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.618.62c-.18.18-.337.347-.469.5a4.944 4.944 0 00-.366.485c-.112.17-.195.331-.25.485a1.834 1.834 0 00-.099.483 1.36 1.36 0 00.058.5c.05.165.134.337.249.517.115.18.265.362.451.548a6.594 6.594 0 00.426.384c.036.028.063.05.08.066a.146.146 0 01.045.093.114.114 0 01-.037.092c-.03.03-.07.04-.124.03a.441.441 0 01-.097-.024 7.724 7.724 0 01-.215-.083z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderAlias
