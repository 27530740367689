import * as React from 'react'
function SvgCheckboxTick() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.528 14.3l.836.836a1 1 0 001.414 0l4.886-4.886a1 1 0 000-1.414l-.129-.129a1 1 0 00-1.414 0l-4.004 4.004-1.402-1.402a1 1 0 00-1.414 0l-.175.175a1 1 0 000 1.414L9.528 14.3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCheckboxTick
