import * as React from 'react'
function SvgCollapseTinyDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.413 10.12a.384.384 0 000-.541l-.49-.467a.377.377 0 00-.535 0l-3.16 3.16-3.16-3.16a.377.377 0 00-.536 0l-.42.396a.384.384 0 000 .542l3.848 3.995a.377.377 0 00.536 0l3.917-3.925z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCollapseTinyDown
