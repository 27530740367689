import * as React from 'react'
function SvgUnsynced() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={11.5} cy={13} r={1.5} fill="currentColor" />
    </svg>
  )
}
export default SvgUnsynced
