import * as React from 'react'
function SvgChevronSmallDoubleRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.106 16.45a.365.365 0 00-.106.245c0 .095.042.18.106.243l.53.53a.365.365 0 00.243.106c.085 0 .18-.043.244-.106l4.937-4.937a.365.365 0 00.106-.244.365.365 0 00-.106-.244L7.123 7.106A.365.365 0 006.88 7a.365.365 0 00-.243.106l-.53.53A.365.365 0 006 7.879c0 .085.042.18.106.244l4.164 4.164-4.164 4.164zm6 0a.365.365 0 00-.106.245c0 .095.042.18.106.243l.53.53a.365.365 0 00.243.106c.085 0 .18-.043.244-.106l4.937-4.937a.365.365 0 00.106-.244.365.365 0 00-.106-.244l-4.937-4.937A.365.365 0 0012.88 7a.365.365 0 00-.243.106l-.53.53a.365.365 0 00-.106.243c0 .085.042.18.106.244l4.164 4.164-4.164 4.164z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDoubleRight
