import * as React from 'react'
function SvgExclamationTriangle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.775 10.414a.298.298 0 01.094-.281.389.389 0 01.281-.125h1.688c.104 0 .198.042.281.125a.297.297 0 01.094.281l-.188 3.5a.506.506 0 01-.14.234.328.328 0 01-.235.11h-1.312a.328.328 0 01-.234-.11.507.507 0 01-.141-.234l-.188-3.5zm2.532 5.594c0 .354-.13.661-.39.922-.261.26-.569.39-.923.39a1.26 1.26 0 01-.922-.39c-.26-.26-.39-.568-.39-.922 0-.354.13-.662.39-.922s.568-.39.922-.39c.354 0 .662.13.922.39s.39.568.39.922zm0-11.25l7.5 13c.166.333.219.672.156 1.015-.063.344-.23.636-.5.875-.27.24-.594.36-.969.36h-15c-.375 0-.698-.12-.968-.36a1.499 1.499 0 01-.5-.875 1.585 1.585 0 01.156-1.015l7.5-13c.187-.334.453-.557.797-.672a1.59 1.59 0 011.03 0c.345.115.61.338.798.672zM4.65 18.227a.156.156 0 000 .187c.041.063.093.094.156.094h14.375c.062 0 .114-.031.156-.094a.156.156 0 000-.188L12.15 5.79c-.041-.062-.093-.094-.156-.094-.062 0-.114.032-.156.094L4.651 18.227z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgExclamationTriangle
