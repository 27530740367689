import * as React from 'react'
function SvgCloudOffline2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.969 6.219c-.703 0-1.319.176-1.905.498a3.577 3.577 0 00-1.347 1.377c-.352.586-.498 1.2-.498 1.875v.029a3.182 3.182 0 00-2.022 1.113 3.17 3.17 0 00-.79 2.139c0 .586.146 1.143.439 1.64.293.528.674.909 1.2 1.202.499.293 1.056.44 1.641.44h9.844a2.72 2.72 0 001.992-.821 2.74 2.74 0 00.82-1.992c0-.762-.292-1.436-.82-1.992-.556-.528-1.23-.82-1.992-.82h-.263c.175-.294.263-.587.263-.938 0-.528-.205-.967-.556-1.319a1.814 1.814 0 00-1.319-.556c-.469 0-.879.146-1.23.44a3.931 3.931 0 00-1.348-1.67c-.644-.41-1.348-.645-2.11-.645zm0-1.407c.79 0 1.553.176 2.256.528a5.6 5.6 0 011.787 1.406c.205-.03.41-.059.644-.059.85 0 1.582.293 2.227.88a3.137 3.137 0 011.055 2.167c.82.293 1.494.82 2.021 1.553.527.732.791 1.553.791 2.432 0 .761-.205 1.465-.586 2.11a4.3 4.3 0 01-1.523 1.552 4.06 4.06 0 01-2.11.556H7.688c-.85 0-1.641-.205-2.344-.615a4.456 4.456 0 01-1.729-1.728A4.592 4.592 0 013 13.25c0-.938.264-1.816.791-2.607A4.584 4.584 0 015.93 8.914a4.933 4.933 0 011.758-2.93 5.113 5.113 0 013.28-1.171zm4.08 5.177a.75.75 0 01-.038 1.06l-1.632 1.519 1.606 1.36a.75.75 0 11-.97 1.144l-1.742-1.475-1.762 1.64a.75.75 0 01-1.022-1.099l1.632-1.518-1.606-1.36a.75.75 0 11.97-1.145l1.742 1.476 1.762-1.64a.75.75 0 011.06.038z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudOffline2
