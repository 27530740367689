import * as React from 'react'
function SvgMarkUnprocessed() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={7.5} stroke="currentColor" />
      <circle cx={12} cy={12} r={5.5} stroke="currentColor" />
    </svg>
  )
}
export default SvgMarkUnprocessed
