import * as React from 'react'
function SvgAlertSuccessSmall() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 7.5a1 1 0 010 1.414l-7.548 7.548a2 2 0 01-2.9-.077l-3.126-3.473a1 1 0 011.486-1.338l3.126 3.473L17.086 7.5a1 1 0 011.414 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertSuccessSmall
