import * as React from 'react'
function SvgArrowBendedUpLeft() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 14.607c0 1.112-.425 2.622-1.276 4.53a28.6 28.6 0 01-.24.543c-.04.087-.084.16-.131.22-.08.115-.174.171-.282.171-.1 0-.179-.033-.236-.1a.374.374 0 01-.085-.251c0-.06.008-.15.025-.266.017-.117.025-.196.025-.236.034-.456.05-.868.05-1.236 0-.676-.058-1.282-.175-1.818-.117-.536-.28-1-.488-1.391a3.496 3.496 0 00-.803-1.015 4.715 4.715 0 00-1.06-.698 5.748 5.748 0 00-1.336-.427 12.735 12.735 0 00-1.547-.216c-.519-.04-1.106-.06-1.762-.06h-2.25v2.572a.618.618 0 01-.191.452.618.618 0 01-.452.19.618.618 0 01-.452-.19L3.19 10.238A.618.618 0 013 9.786c0-.174.064-.325.19-.452L8.335 4.19A.618.618 0 018.786 4c.174 0 .324.064.452.19.127.128.19.279.19.453v2.571h2.25c4.775 0 7.705 1.35 8.79 4.048.355.898.532 2.012.532 3.345z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowBendedUpLeft
