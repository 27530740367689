import * as React from 'react'
function SvgBacklink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.46 6.04a.65.65 0 010 .92l-9.89 9.89H15v1.3H6.35V9.5h1.3v6.43l9.89-9.89a.65.65 0 01.92 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBacklink
