import * as React from 'react'
function SvgHelpPanel() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.266 13.726c.234 0 .43.079.585.235a.795.795 0 01.235.586c0 .234-.078.43-.235.586a.795.795 0 01-.585.234.795.795 0 01-.586-.234.795.795 0 01-.235-.586c0-.235.078-.43.235-.586a.795.795 0 01.586-.235zm-.235-.703a.34.34 0 01-.249-.102.34.34 0 01-.102-.25c0-.507.136-.937.41-1.288.176-.195.478-.43.908-.703.332-.215.547-.381.645-.498a.926.926 0 00.292-.674c0-.313-.136-.576-.41-.791-.293-.254-.713-.381-1.26-.381-.41 0-.747.068-1.01.205a2.378 2.378 0 00-.747.645c-.04.058-.108.097-.205.117a.317.317 0 01-.264-.059l-.38-.264a.375.375 0 01-.147-.234.317.317 0 01.058-.264c.352-.43.713-.752 1.084-.966.45-.235.987-.352 1.612-.352.8 0 1.474.215 2.021.645.547.43.82.996.82 1.699 0 .488-.136.898-.41 1.23-.175.215-.478.46-.908.733-.332.214-.557.38-.674.498-.176.215-.264.449-.264.703 0 .098-.034.18-.102.249a.34.34 0 01-.249.102h-.469zm.235-8.085a6.175 6.175 0 00-3.18.85 6.333 6.333 0 00-2.299 2.299 6.175 6.175 0 00-.85 3.179c0 1.152.284 2.211.85 3.178a6.333 6.333 0 002.3 2.3c.967.567 2.026.85 3.179.85a6.175 6.175 0 003.178-.85 6.333 6.333 0 002.3-2.3c.567-.967.85-2.026.85-3.178a6.175 6.175 0 00-.85-3.18 6.3 6.3 0 00-2.314-2.299 6.2 6.2 0 00-3.164-.85zm0-.938c1.308 0 2.52.327 3.632.981a7.342 7.342 0 012.652 2.652 7.043 7.043 0 01.981 3.633c0 1.308-.327 2.52-.981 3.632a7.342 7.342 0 01-2.652 2.652 7.043 7.043 0 01-3.632.981 7.043 7.043 0 01-3.633-.981 7.342 7.342 0 01-2.652-2.652A7.043 7.043 0 015 11.266c0-1.309.327-2.52.981-3.633A7.342 7.342 0 018.633 4.98 7.043 7.043 0 0112.266 4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHelpPanel
