import * as React from 'react'
function SvgFolderLocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.333V6h1v7h-2v5.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0117.25 20H4.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 013 18.25V7.75c0-.48.172-.89.516-1.234A1.684 1.684 0 014.75 6h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V8h5V6h1v-.667C15 4.053 16.25 4 17.5 4s2.5.052 2.5 1.333zM16 6h3v-.667C19 5 18.603 5 17.501 5 16.4 5 16 5 16 5.333V6zm-2 3H8.75a.723.723 0 01-.531-.219A.723.723 0 018 8.25v-.5a.723.723 0 00-.219-.531A.723.723 0 007.25 7h-2.5a.723.723 0 00-.531.219A.723.723 0 004 7.75V10h10V9zM4 11v7.25c0 .208.073.385.219.531A.723.723 0 004.75 19h12.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V13h-4v-2H4zm11-4v5h5V7h-5zm3 2h-1v2h1V9z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderLocked
