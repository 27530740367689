import * as React from 'react'
function SvgPanelNewsInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18v-4h6v5H6a.961.961 0 01-.703-.297A.961.961 0 015 18zm14-9c.27 0 .505.099.703.297A.961.961 0 0120 10v2.5a.487.487 0 01-.14.36.487.487 0 01-.36.14h-15a.487.487 0 01-.36-.14.487.487 0 01-.14-.36V10c0-.27.099-.505.297-.703A.961.961 0 015 9h1.375a2.647 2.647 0 01-.313-1.25c0-.75.271-1.396.813-1.938C7.417 5.271 8.062 5 8.812 5a2.85 2.85 0 011.688.531c.458.313.969.844 1.531 1.594.563-.75 1.073-1.281 1.531-1.594A2.85 2.85 0 0115.25 5c.75 0 1.396.27 1.938.813C17.729 6.354 18 7 18 7.75c0 .417-.104.833-.313 1.25H19zM8.812 9H11.5c-.52-.77-.948-1.344-1.281-1.719-.292-.333-.552-.552-.782-.656a1.391 1.391 0 00-.625-.125c-.354 0-.65.12-.89.36-.24.239-.36.536-.36.89s.12.651.36.89c.24.24.536.36.89.36zm6.438 0c.354 0 .651-.12.89-.36.24-.239.36-.536.36-.89s-.12-.651-.36-.89a1.21 1.21 0 00-.89-.36c-.25 0-.458.042-.625.125-.23.104-.49.323-.781.656-.334.375-.76.948-1.281 1.719h2.687zM13 19v-5h6v4c0 .27-.099.505-.297.703A.961.961 0 0118 19h-5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelNewsInverted
