import scheme from './color-schemes/scheme.json'
import theme from './ui'

export type ThemeSchema = typeof theme
export type ThemeName = 'dark' | 'light'

export const colors = scheme

export type Weights = {
  hairline?: number
  thin?: number
  light?: number
  regular?: number
  medium?: number
  semibold?: number
  bold?: number
  heavy?: number
  black?: number
}
