import * as React from 'react'
import { connect, FormikProps, FormikFormProps } from 'formik'
import useScrollToValidationError from '../hooks/use-scroll-to-validation-error'

type Props = {
  formik: FormikProps<any>
}

const FormEnhanced = ({ formik, ...props }: Props) => {
  const formRef = React.useRef(null)
  useScrollToValidationError(formRef, formik)

  return (
    <form
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...props}
      ref={formRef}
    />
  )
}

export default connect<FormikFormProps>(FormEnhanced)
