import { FormikBag, FormikHelpers, FormikValues } from 'formik'
import { AbortSignalError } from '@packages/common/src/utils/cancelable-promise'

// allow using submissionErrorsHandler in `withFormik` and <Formik />
type FormikBagOptionalProps<P, V> = P extends void
  ? FormikHelpers<V>
  : FormikBag<P, V>

type WrappedSubmitHandler<P = void, V extends FormikValues = any> = (
  handleSubmit: (
    values: V,
    bag: FormikBagOptionalProps<P, V>
  ) => void | Promise<void>
) => (values: V, bag: FormikBagOptionalProps<P, V>) => any

type OnError<P = void, V extends FormikValues = any> = (
  error: unknown,
  bag: FormikBagOptionalProps<P, V>
) => void

export const submissionErrorsHandler =
  <P = void, V extends FormikValues = any>(
    onError: OnError<P, V>
  ): WrappedSubmitHandler<P, V> =>
  handleSubmit =>
  async (values, bag) => {
    try {
      await handleSubmit(values, bag)
    } catch (error) {
      if (error instanceof AbortSignalError) return

      onError(error, bag)
    }
  }
