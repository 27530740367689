import * as React from 'react'
function SvgPageShared() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 18.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H6.75a.723.723 0 01-.531-.219A.723.723 0 016 18.25V5.75c0-.208.073-.385.219-.531A.723.723 0 016.75 5h10.5c.254 0 .393.065.531.219.139.154.219.297.219.531v12.5zM7 18h10V6H7v12zm9-5H9v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-3h-2v2h2v-2zm-3 0h-2v2h2v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageShared
