import * as React from 'react'
function SvgItemFeatureInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5l7 6.707-7 6.707-7-6.707L12 5zm-4.763 6.707L12 16.183l4.763-4.476L12 7.231l-4.763 4.476z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeatureInverted
