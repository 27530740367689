import * as React from 'react'
function SvgSmartphone1() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5h8A1.5 1.5 0 0117.5 3v18a1.5 1.5 0 01-1.5 1.5H8A1.5 1.5 0 016.5 21V3A1.5 1.5 0 018 1.5zM5 3a3 3 0 013-3h8a3 3 0 013 3v18a3 3 0 01-3 3H8a3 3 0 01-3-3V3zm7 18a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSmartphone1
