import { ApiError } from '@packages/common/src/providers/network/errors'

const fetchApi = async <Response>(
  url: string,
  payload: { method?: 'post' | 'get'; body: Record<string, any> }
) => {
  const res = await fetch(url, {
    method: payload.method || 'get',
    headers: { 'Content-Type': 'application/json' },
    body: payload.body ? JSON.stringify(payload.body) : undefined,
  })

  const data = await res.json()

  if (!res.ok) {
    throw new ApiError(res.status, url, data)
  }

  return data as Response
}

export default fetchApi
