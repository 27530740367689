import * as React from 'react'
function SvgItemFeatureSetInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.107 6.6l3.6-3.6 3.6 3.6-3.6 3.6-3.6-3.6zm-5.4 5.4l3.6-3.6 3.6 3.6-3.6 3.6-3.6-3.6zm5.4 5.4l3.6 3.6 3.6-3.6-3.6-3.6-3.6 3.6zm12.6-5.4l-3.6 3.6-3.6-3.6 3.6-3.6 3.6 3.6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeatureSetInverted
