import * as React from 'react'
function SvgPageLockedTemplate() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5.333V6h1v6h-2v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 20.25V12h1v8h10v-8h-4V8H8V7h4V6h1v-.667C13 4.053 14.25 4 15.5 4s2.5.052 2.5 1.333zM14 6h3v-.667C17 5 16.603 5 15.501 5 14.4 5 14 5 14 5.333V6zm-6 7h6v1H8v-1zm6 2H8v1h6v-1zm-6 2h5v1H8v-1zm5-10v4h5V7h-5zm3 1v2h-1V8h1zM2 6v2l1-1h1v3l-1 1h3l-1-1V7h1l1 1V6H2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageLockedTemplate
