import * as React from 'react'
function SvgListOrdered() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.836 10.052v.74h-3.2v-.74h1.172V6.68c0-.133.004-.27.012-.412l-.832.696a.344.344 0 01-.142.07.34.34 0 01-.242-.04.233.233 0 01-.072-.066L4.22 6.5 5.984 5h.812v5.052h1.04zm2.68-3.386a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zm0 5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zm.5 4.5a.5.5 0 000 1h8a.5.5 0 000-1h-8zm-3.142 1.796a.352.352 0 00-.254-.09H6.136c-.112 0-.23.01-.354.03-.124.02-.249.049-.374.086l1.304-1.332c.155-.16.303-.317.444-.472.141-.155.265-.315.37-.48.105-.165.19-.339.252-.52.063-.181.094-.379.094-.592a1.68 1.68 0 00-.132-.676 1.512 1.512 0 00-.368-.52 1.647 1.647 0 00-.568-.334 2.172 2.172 0 00-.732-.118 2.36 2.36 0 00-.718.106c-.223.07-.42.176-.592.316a1.75 1.75 0 00-.43.524c-.115.21-.192.453-.232.73l.52.092c.136.021.24.012.312-.028.072-.04.13-.121.176-.244a.913.913 0 01.316-.474.887.887 0 01.266-.14c.1-.033.209-.05.326-.05.128 0 .243.018.346.054a.703.703 0 01.434.428c.04.105.06.227.06.366 0 .152-.02.297-.062.434a1.9 1.9 0 01-.178.406 2.764 2.764 0 01-.29.404 8.31 8.31 0 01-.394.428l-1.756 1.76a.588.588 0 00-.176.408v.328h3.968V18.2a.314.314 0 00-.094-.238z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgListOrdered
