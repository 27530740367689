import * as React from 'react'
function SvgExternalUrl() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 7h.5v-.5H17V7zm-3.5.5H17v-1h-3.5v1zm3-.5v3.5h1V7h-1zm0 6v3h1v-3h-1zm-.5 3.5H8v1h8v-1zM7.5 16V8h-1v8h1zM8 7.5h3v-1H8v1zm-.5.5a.5.5 0 01.5-.5v-1A1.5 1.5 0 006.5 8h1zm.5 8.5a.5.5 0 01-.5-.5h-1A1.5 1.5 0 008 17.5v-1zm8.5-.5a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zm-.354-8.854l-6 6 .708.708 6-6-.708-.708z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgExternalUrl
