import * as React from 'react'
function SvgSurveyQuestionOpenEnded() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6h14a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2zM2 8a3 3 0 013-3h14a3 3 0 013 3v8a3 3 0 01-3 3H5a3 3 0 01-3-3V8zm4.29 7.205c.24.25.583.375 1.03.375.25 0 .492-.038.725-.115.233-.077.44-.19.62-.34l-.37-.6a.356.356 0 00-.075-.08.133.133 0 00-.09-.03c-.03 0-.06.01-.09.03a2.716 2.716 0 01-.24.115.61.61 0 01-.19.025.413.413 0 01-.325-.135.56.56 0 01-.115-.375v-2.78h1.37v-.88H7.17V8.8h-.64a.283.283 0 00-.18.055.274.274 0 00-.095.155l-.26 1.4-.825.135v.49c0 .087.023.152.07.195a.24.24 0 00.17.065h.525v2.865c0 .443.118.792.355 1.045z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSurveyQuestionOpenEnded
