import * as React from 'react'
function SvgPlusThin() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8a.5.5 0 00-1 0v3.5H7.5a.5.5 0 000 1H11V16a.5.5 0 001 0v-3.5h3.5a.5.5 0 000-1H12V8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusThin
