import * as React from 'react'
function SvgEyeCrossed() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.628 12.79a2.5 2.5 0 01-.103-1.144l2.829 2.829a2.5 2.5 0 01-2.726-1.684zm2.018-3.266l2.829 2.83v-.001a2.5 2.5 0 00-2.829-2.829zm-6-3.17l12 12 .708-.708-12-12-.708.708zm7.53 8.943l1.614 1.615A7.03 7.03 0 0112 17.5c-5 0-8-5.5-8-5.5s.938-1.722 2.642-3.237l2.06 2.06a3.5 3.5 0 004.474 4.474zM12 6.5c-.96.003-1.91.203-2.79.588l1.613 1.614a3.5 3.5 0 014.474 4.474l2.062 2.062C19.061 13.721 20 12 20 12s-3-5.5-8-5.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgEyeCrossed
