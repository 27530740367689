import * as React from 'react'
function SvgAlertWarningBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zM12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.5-5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM12.435 6a1 1 0 01.999 1.062l-.375 6a1 1 0 01-.998.938h-.121a1 1 0 01-.999-.938l-.375-6A1 1 0 0111.564 6h.871z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertWarningBig
