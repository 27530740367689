import * as React from 'react'
function SvgSurveyQuestionMultiChoiceSingleSelect() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5h14a2 2 0 012 2v1a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2zM2 7a3 3 0 013-3h14a3 3 0 013 3v1a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3 7h14a2 2 0 012 2v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1a2 2 0 012-2zm-3 2a3 3 0 013-3h14a3 3 0 013 3v1a3 3 0 01-3 3H5a3 3 0 01-3-3v-1zm5-8.5a1 1 0 11-2 0 1 1 0 012 0zm-1 10a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSurveyQuestionMultiChoiceSingleSelect
