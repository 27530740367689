import * as React from 'react'
function SvgEmbedPlay() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.286 7.35v9.299L15.004 12 7.286 7.35zm11.428 4.651c0 .399-.418.686-.418.686l-11.74 7.07c-.857.545-1.556.141-1.556-.892V5.136C5 4.1 5.7 3.7 6.557 4.243l11.74 7.073c-.002 0 .417.286.417.685z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgEmbedPlay
