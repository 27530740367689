import * as React from 'react'
function SvgInsightInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.218 13.198C17.05 11.893 18 10.4 18 8.5 18 5.393 15.538 3 12.5 3S7 5.393 7 8.5c0 1.562.885 3.04 1.694 4.39C9.374 14.024 10 15.07 10 16v-.11s1.524.36 2.5.36 2.5-.36 2.5-.36V16c0-.89.573-1.789 1.218-2.802zM10 19.333v-2.442s1.519.406 2.5.406c.981 0 2.5-.406 2.5-.406v2.442c-.167.223-1 1.167-2.5 1.167s-2.333-.944-2.5-1.167zM12 7h1v2h2v1h-2v2h-1v-2h-2V9h2V7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInsightInverted
