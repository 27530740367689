import * as React from 'react'
function SvgChevronSmallDoubleLeft() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.06 7.636a.345.345 0 01.106.243c0 .085-.042.18-.106.244l-4.163 4.164 4.163 4.164a.365.365 0 01.106.244c0 .084-.042.18-.106.243l-.53.53a.365.365 0 01-.243.106.365.365 0 01-.244-.106L6.106 12.53A.365.365 0 016 12.287c0-.085.042-.18.106-.244l4.937-4.937A.365.365 0 0111.287 7c.085 0 .18.042.244.106l.53.53zm6 0a.345.345 0 01.106.243c0 .085-.042.18-.106.244l-4.163 4.164 4.163 4.164a.365.365 0 01.106.244c0 .084-.042.18-.106.243l-.53.53a.365.365 0 01-.243.106.365.365 0 01-.244-.106l-4.937-4.937a.365.365 0 01-.106-.244c0-.085.042-.18.106-.244l4.937-4.937A.365.365 0 0117.287 7c.085 0 .18.042.244.106l.53.53z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDoubleLeft
