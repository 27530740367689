import * as React from 'react'
function SvgPageSharedAlias() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.003 18.25a.723.723 0 01-.218.531.723.723 0 01-.532.219h-10.5a.723.723 0 01-.53-.219.723.723 0 01-.22-.531v-.451l1-.334V18h10V6h-10v6h-1V5.75c0-.208.073-.385.22-.531A.723.723 0 016.752 5h10.5c.255 0 .393.065.532.219.138.154.218.297.218.531v12.5zm-2-5.25h-7v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-3h-2v2h2v-2zm-3 0h-2v2h2v-2zM5.939 20.387c-.759-.29-1.29-.59-1.596-.895-.367-.366-.625-.722-.774-1.066-.444-1.04-.009-2.217 1.304-3.53l.619-.619-.707-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.176-.071H7.79a.24.24 0 01.177.072.24.24 0 01.071.176v2.829a.24.24 0 01-.071.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.619.62c-.18.18-.336.347-.468.5a4.944 4.944 0 00-.366.485c-.112.17-.196.331-.25.485a1.834 1.834 0 00-.1.483 1.36 1.36 0 00.059.5c.05.165.133.337.248.517.115.18.266.362.452.548a6.594 6.594 0 00.425.384c.037.028.064.05.08.066a.146.146 0 01.046.093.114.114 0 01-.037.092c-.03.03-.071.04-.125.03a.441.441 0 01-.096-.024 7.724 7.724 0 01-.216-.083z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedAlias
