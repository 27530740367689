import * as React from 'react'
function SvgRegex() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.283 8.094L15.617 5h-2.663l.343 3.094-3.059-.888-.395 2.558 2.75.22-1.81 2.443 2.39 1.195 1.082-2.47 1.257 2.488 2.285-1.213-1.82-2.444 2.76-.184-.325-2.593-3.129.888zM5.48 15.969c-.32.293-.479.709-.479 1.248 0 .533.167.946.501 1.24.334.292.791.438 1.371.438.563 0 1.003-.147 1.323-.443.32-.296.479-.708.479-1.235 0-.54-.155-.955-.466-1.248-.31-.293-.756-.44-1.336-.44-.61 0-1.074.147-1.393.44z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgRegex
