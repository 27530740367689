import * as React from 'react'
import Form from './Form'
import {
  SignInMethodProvider,
  useSignInMethod,
} from '@apps/accounts/src/features/Workspace/SignInPage/sign-in-method-ctx'
import AccountSelector from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector'
import {
  AuthWorkspace,
  ValidatedWorkspacesProvider,
} from '@apps/accounts/src/features/Workspace/SignInPage/validated-workspaces'
import {
  usePreselectedEmail,
  useBackUrl,
  useOrigin,
} from '@apps/accounts/src/features/Workspace/SignInPage/query-hooks'

type Props = {
  workspaces: AuthWorkspace[]
}

const SignInPage = ({ workspaces }: Props) => (
  <ValidatedWorkspacesProvider workspaces={workspaces}>
    <SignInMethodProvider>
      <SignInMethod />
    </SignInMethodProvider>
  </ValidatedWorkspacesProvider>
)

const SignInMethod = () => {
  const preselectedEmail = usePreselectedEmail()
  const backUrl = useBackUrl()
  const origin = useOrigin()
  const signInMethod = useSignInMethod()

  return signInMethod === 'form' ? (
    <Form autofillEmail={preselectedEmail} backUrl={backUrl} origin={origin} />
  ) : (
    <AccountSelector />
  )
}

export default SignInPage
