import * as React from 'react'
import { Style } from '@packages/common/src/providers/style/types'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import View from '@packages/common/src/components/View'

type Props = {
  height: number
  style?: Style
}

const styles = cmpStyles({
  img: ({ height }) => ({ display: 'block', height }),
})

const Logo = ({ height, style }: Props) => (
  <picture>
    <source
      srcSet="/images/logo-dark.svg"
      media="(prefers-color-scheme: dark)"
    />
    <View
      as="img"
      nativeProps={{
        src: '/images/logo.svg',
        alt: 'Specific Logo',
      }}
      height={height}
      style={style ? [styles.img, style] : styles.img}
    />
  </picture>
)

export default Logo
