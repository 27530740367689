import * as React from 'react'
function SvgItemFeatureTemplate() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.293l1.52 1.52-.707.707L12 5.707l-.813.813-.707-.707L12 4.293zm4.313 5.727L13.98 7.687l.707-.707 2.333 2.333-.707.707zM10.02 7.687L7.687 10.02l-.707-.707L9.313 6.98l.707.707zM5.707 12l.813-.813-.707-.707L4.293 12l1.52 1.52.707-.707L5.707 12zm12.586 0l-.813-.813.707-.707 1.52 1.52-1.52 1.52-.707-.707.813-.813zm-4.313 4.313l2.333-2.333.707.707-2.333 2.333-.707-.707zM7.687 13.98l2.333 2.333-.707.707-2.333-2.333.707-.707zM12 18.293l-.813-.813-.707.707 1.52 1.52 1.52-1.52-.707-.707-.813.813z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeatureTemplate
