import * as React from 'react'
import ThemeProviderCommon from '@packages/common/src/providers/style/ThemeProvider'
import { Renderer } from '@packages/common/src/providers/style/configure-renderer'
import configureRendererCommon from '@packages/common/src/providers/style/configure-renderer'
import { colors } from '@packages/common/src/themes/types'

export const configureRenderer = () => {
  const renderer = configureRendererCommon()
  renderer.renderStatic(`
    body {
      background: ${colors['Background/Primary']};
    }
  `)

  return renderer
}

type Props = {
  children: React.ReactNode
  renderer: Renderer
}

const ThemeProvider = ({ children, renderer }: Props) => (
  <ThemeProviderCommon renderer={renderer}>{children}</ThemeProviderCommon>
)

export default ThemeProvider
