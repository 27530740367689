import * as React from 'react'
function SvgFolderSpecsInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.75V9H4V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234zM4 10h16v7.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V10zm9.636 2.444a.47.47 0 00.116-.136l.296-.564a2.148 2.148 0 00-.756-.448 2.772 2.772 0 00-.968-.164c-.312 0-.59.048-.832.144-.24.096-.443.223-.608.38a1.591 1.591 0 00-.504 1.168c0 .27.037.495.112.676a1.428 1.428 0 00.72.752c.157.075.319.139.484.192.165.053.327.103.484.148.157.043.297.095.42.156a.894.894 0 01.296.228.53.53 0 01.116.356c0 .253-.077.444-.232.572-.152.128-.368.192-.648.192-.192 0-.357-.027-.496-.08a2.27 2.27 0 01-.36-.176 9.168 9.168 0 01-.276-.176.414.414 0 00-.228-.08.286.286 0 00-.152.044.335.335 0 00-.116.104l-.352.568c.112.115.243.219.392.312a2.95 2.95 0 001.004.396c.184.037.368.056.552.056.323 0 .61-.05.864-.148.256-.099.47-.233.644-.404.173-.173.305-.375.396-.604.093-.232.14-.48.14-.744 0-.243-.039-.448-.116-.616a1.293 1.293 0 00-.3-.432 1.666 1.666 0 00-.42-.292 4.015 4.015 0 00-.484-.2 11.37 11.37 0 00-.484-.16 2.872 2.872 0 01-.424-.164 1.047 1.047 0 01-.3-.216.468.468 0 01-.112-.32c0-.2.07-.361.208-.484.139-.125.347-.188.624-.188.157 0 .295.021.412.064.117.04.221.084.312.132.09.048.17.093.236.136.07.04.133.06.192.06a.25.25 0 00.148-.04z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderSpecsInverted
