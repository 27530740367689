import * as React from 'react'
function SvgCloudDownload() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.714 12.696a.26.26 0 00-.075-.192.261.261 0 00-.193-.075h-1.875V9.482a.257.257 0 00-.08-.188.257.257 0 00-.187-.08h-1.608a.257.257 0 00-.188.08.257.257 0 00-.08.188v2.947H8.555a.257.257 0 00-.189.08.257.257 0 00-.08.187.26.26 0 00.076.193l2.946 2.946a.26.26 0 00.193.076.26.26 0 00.193-.076l2.938-2.938a.314.314 0 00.083-.2zm5.357 1.875c0 .888-.314 1.645-.941 2.273a3.097 3.097 0 01-2.273.942H7.75c-1.032 0-1.915-.367-2.65-1.101-.733-.734-1.1-1.617-1.1-2.65 0-.725.195-1.394.586-2.008a3.664 3.664 0 011.574-1.381 6.166 6.166 0 01-.017-.36c0-1.183.418-2.193 1.255-3.03C8.236 6.419 9.246 6 10.428 6c.871 0 1.668.243 2.39.728a4.199 4.199 0 011.578 1.934c.396-.346.86-.52 1.39-.52.591 0 1.096.21 1.515.629.418.418.628.923.628 1.515 0 .424-.115.809-.344 1.155a3.168 3.168 0 011.787 1.134 3.11 3.11 0 01.7 1.996z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudDownload
