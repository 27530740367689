import * as React from 'react'
function SvgHeading() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5.5a.5.5 0 000 1H7v12H5.5a.5.5 0 000 1h4a.5.5 0 000-1H8v-5h8v5h-1.5a.5.5 0 000 1h4a.5.5 0 000-1H17v-12h1.5a.5.5 0 000-1h-4a.5.5 0 000 1H16v6H8v-6h1.5a.5.5 0 000-1h-4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHeading
