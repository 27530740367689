import * as React from 'react'
function SvgPageSharedAliasTemplate() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H3v2l1-1h1v3L4 9h3L6 8V5h1l1 1V4zm1 2h8.003v12h-10v-.535l-1 .334v.451c0 .208.073.385.22.531a.723.723 0 00.53.219h10.5a.723.723 0 00.532-.219.723.723 0 00.218-.531V5.75c0-.234-.08-.377-.218-.531-.139-.154-.277-.219-.532-.219H9v1zm-1.997 4h-1v2h1v-2zm9 3h-7v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-3h-2v2h2v-2zm-3 0h-2v2h2v-2zm-7.661 9.492c.305.306.837.604 1.596.895a10.235 10.235 0 00.216.083.441.441 0 00.096.025c.054.009.095-.001.125-.03a.114.114 0 00.037-.093.146.146 0 00-.046-.093.804.804 0 00-.08-.066 6.594 6.594 0 01-.425-.384 3.321 3.321 0 01-.452-.548 2.122 2.122 0 01-.248-.517 1.36 1.36 0 01-.058-.5c.012-.168.045-.33.099-.483.054-.154.138-.315.25-.485.112-.17.234-.33.366-.485.132-.153.288-.32.468-.5l.619-.62.707.707a.24.24 0 00.177.072.24.24 0 00.177-.072.24.24 0 00.071-.176v-2.829a.24.24 0 00-.071-.177.24.24 0 00-.177-.071H4.96a.24.24 0 00-.176.072.24.24 0 00-.072.176c0 .07.024.13.072.177l.707.707-.62.619c-1.312 1.313-1.747 2.49-1.303 3.53.15.344.407.7.774 1.066z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedAliasTemplate
