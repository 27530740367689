import * as React from 'react'
function SvgItemTagInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.613 12.508a1 1 0 000-1.257l-3.409-4.217a1 1 0 00-.777-.371H6.267a1 1 0 00-1 1v8.433a1 1 0 001 1h10.16a1 1 0 00.777-.372l3.409-4.216zm-5.171-.643c0 .575.472 1.04 1.054 1.04.582 0 1.054-.465 1.054-1.04 0-.574-.472-1.04-1.054-1.04-.582 0-1.054.466-1.054 1.04z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemTagInverted
