import getAxios, {
  configureAxios,
} from '@packages/common/src/providers/network/axios'

const configureNetwork = () => {
  configureAxios(process.env.NEXT_PUBLIC_API_URL)

  getAxios().defaults.headers.common['x-client-type'] = 'accounts'
  getAxios().defaults.headers.common[
    'x-client-version'
  ] = `${process.env.VERSION}`
}

export default configureNetwork
