import * as React from 'react'
function SvgPanelInboxWithNotificationInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.517 11.965l1.102 1.66h-3.603l-.938 1.875H9.797l-.938-1.875H5.256l2.49-3.75h4.704A3.983 3.983 0 0111.984 8H7.512c-.235 0-.46.054-.674.161-.215.108-.381.259-.498.454l-3.106 4.658a1.394 1.394 0 00-.234.791v3.78c0 .39.137.722.41.996.274.273.606.41.996.41H18.47c.39 0 .722-.137.996-.41.273-.274.41-.606.41-.996v-3.78c0-.293-.078-.556-.234-.79L18.3 11.261a3.978 3.978 0 01-1.783.703z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.984 11a4 4 0 100-8 4 4 0 000 8z"
        fill="red"
      />
    </svg>
  )
}
export default SvgPanelInboxWithNotificationInverted
