import * as React from 'react'
function SvgChevronSmallDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.676 9.204c.084 0 .18.042.243.106l4.164 4.163 4.164-4.163a.365.365 0 01.244-.106c.085 0 .18.042.243.106l.53.53a.365.365 0 01.106.243c0 .085-.042.18-.106.244l-4.937 4.937a.365.365 0 01-.244.106.365.365 0 01-.244-.106l-4.937-4.937a.365.365 0 01-.106-.244c0-.085.043-.18.106-.244l.53-.53a.345.345 0 01.244-.105z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDown
