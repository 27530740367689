import React from 'react'
import { useValidatedWorkspaces } from '@apps/accounts/src/features/Workspace/SignInPage/validated-workspaces'

const useAccountSelectorWorkspaces = (exceptEmail?: string) => {
  const workspaces = useValidatedWorkspaces()

  const workspacesFiltered = React.useMemo(
    () =>
      (exceptEmail
        ? workspaces.filter(({ user: { email } }) => email !== exceptEmail)
        : workspaces
      ).filter(({ token }) => !!token),
    [workspaces, exceptEmail]
  )

  return workspacesFiltered
}

export default useAccountSelectorWorkspaces
