import * as React from 'react'
function SvgListIndentRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7.5a.5.5 0 01.5-.5h14a.5.5 0 010 1h-14a.5.5 0 01-.5-.5zm0 3.161a.3.3 0 01.498-.226l3.244 2.84a.3.3 0 010 .45l-3.244 2.84A.3.3 0 015 16.339V10.66zM11.5 10a.5.5 0 000 1h8a.5.5 0 000-1h-8zm-.5 3.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zm.5 2.5a.5.5 0 000 1h8a.5.5 0 000-1h-8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgListIndentRight
