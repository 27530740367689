import * as React from 'react'
function SvgFolderSmartInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 0018.25 7H10v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 008.25 5h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 004 6.75V9h16zm0 1H4v7.25c0 .48.172.89.516 1.234.343.344.755.516 1.234.516H16.3l-.475-.475.791-1.25a1.42 1.42 0 01.252-.277 2.314 2.314 0 01-.404-.665 2.614 2.614 0 01-.17-.973 2.447 2.447 0 01.765-1.77c.255-.243.552-.422.874-.547.354-.14.73-.198 1.111-.198a3.645 3.645 0 01.956.123V10zm.199 5.063a.43.43 0 00.112-.127l.294-.549a1.63 1.63 0 00-.311-.224 2.074 2.074 0 00-.372-.171 2.204 2.204 0 00-.423-.109 2.637 2.637 0 00-.455-.038c-.28 0-.529.043-.745.129-.218.084-.4.197-.55.34a1.446 1.446 0 00-.455 1.046c0 .238.033.439.098.602.065.161.15.296.256.406.107.107.228.195.364.263.135.065.274.12.416.168.142.044.281.085.416.122.136.037.256.08.361.13.107.049.194.11.259.185a.425.425 0 01.098.29c0 .206-.06.356-.178.452-.12.093-.286.14-.501.14-.159 0-.296-.022-.413-.066a2.036 2.036 0 01-.311-.148c-.09-.053-.17-.102-.242-.146a.39.39 0 00-.21-.067.288.288 0 00-.15.042.338.338 0 00-.109.098l-.35.553c.1.1.218.191.354.273.135.082.28.153.433.213.154.059.313.104.476.137.166.033.328.049.487.049.29 0 .547-.043.774-.13.228-.088.42-.208.573-.36.157-.154.276-.334.357-.539.082-.205.123-.426.123-.662 0-.212-.033-.393-.098-.542a1.187 1.187 0 00-.259-.385 1.357 1.357 0 00-.364-.259 3.407 3.407 0 00-.416-.175 6.765 6.765 0 00-.42-.137 3.078 3.078 0 01-.364-.136.85.85 0 01-.256-.175.353.353 0 01-.098-.255.48.48 0 01.161-.382c.11-.096.275-.144.497-.144.128 0 .243.018.343.053.1.033.189.07.266.112.08.042.15.08.21.116a.384.384 0 00.175.049c.06 0 .11-.014.147-.043z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderSmartInverted
