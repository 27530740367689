import * as React from 'react'
function SvgLinkBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.326 6.098a3.75 3.75 0 00-5.303 0L9.878 9.243c-1.94 1.94-.601 5.26 2.142 5.311l.028-1.5c-1.42-.026-2.114-1.745-1.109-2.75l3.145-3.145a2.25 2.25 0 013.182 3.182l-1.284 1.283a.75.75 0 101.06 1.06l1.284-1.282a3.75 3.75 0 000-5.304zM5.598 18.826a3.75 3.75 0 005.304 0l2.9-2.9c1.968-1.968.497-5.326-2.282-5.215l.06 1.499c1.415-.057 2.163 1.653 1.162 2.655l-2.901 2.9a2.25 2.25 0 11-3.182-3.181L7.942 13.3a.75.75 0 00-1.06-1.06l-1.284 1.283a3.75 3.75 0 000 5.303z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLinkBold
