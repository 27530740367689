import * as React from 'react'
import {
  ConnectionError,
  IncompatibleVersionError,
} from '@packages/common/src/providers/network/errors'
import i18n from 'i18next'
import Text from '@packages/common/src/components/Text'
import { Trans } from 'react-i18next'
import Link from '@apps/accounts/src/components/Link'
import { InlineAlert } from '@packages/common/src/components/Alerts/Inline/type'
import { AlertConstructor } from '@packages/common/src/components/Alerts/common/types'

const errorToAlert = (
  error: Error | unknown
): AlertConstructor<InlineAlert> => {
  if (error instanceof IncompatibleVersionError) {
    return {
      accent: 'error',
      children:
        error.tooOldSide === 'server'
          ? i18n.t('network.error.old-server')
          : i18n.t('network.error.old-client'),
    }
  }

  if (error instanceof ConnectionError) {
    return {
      accent: 'error',
      title: i18n.t('network.error.general-title'),
      children: i18n.t('network.error.offline'),
    }
  }

  return {
    accent: 'error',
    children: (
      <Text color="inherit">
        <Trans
          i18nKey="error.unknown"
          components={{
            a: (
              <Link
                to="https://help.specific.app/hc/en-us/requests/new"
                target="_blank"
              />
            ),
          }}
        />
      </Text>
    ),
  }
}

export default errorToAlert
