import React from 'react'
import useAbortSignalOnUnmount from '@packages/common/src/hooks/use-abort-signal-on-unmount'

const withAbortSignalOnUnmount =
  <Props,>(
    BaseComponent: React.ComponentType<Props & { signal: AbortSignal }>
  ) =>
  (props: Props) => {
    const signal = useAbortSignalOnUnmount()
    return <BaseComponent {...props} signal={signal} />
  }

export default withAbortSignalOnUnmount
