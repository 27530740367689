import * as React from 'react'
function SvgDropdownSelectBox() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.013 10.479a.264.264 0 000-.372l-3.034-3.03a.26.26 0 00-.368 0l-3.034 3.03a.264.264 0 000 .372l.679.675a.26.26 0 00.368 0l2.171-2.172 2.171 2.172a.26.26 0 00.369 0l.678-.675zm0 4.272a.264.264 0 010 .373l-3.034 3.03a.26.26 0 01-.368 0l-3.034-3.03a.264.264 0 010-.373l.679-.674a.259.259 0 01.368 0l2.171 2.171 2.171-2.171a.26.26 0 01.369 0l.678.674z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDropdownSelectBox
