import * as React from 'react'
function SvgPageAliasInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.781 18.781A.723.723 0 0018 18.25V5.75c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H6.75a.723.723 0 00-.531.219A.723.723 0 006 5.75V13h2v5l-1.945.54a.739.739 0 00.164.241.723.723 0 00.531.219h10.5a.723.723 0 00.531-.219zM15 10H9v1h6v-1zm-6 2h6v1H9v-1zm5 2H9v1h5v-1zm-9.062 7.387c-.759-.29-1.29-.59-1.596-.895-.367-.366-.625-.722-.774-1.066-.444-1.04-.009-2.217 1.304-3.53l.619-.619-.707-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.176-.071H6.79a.24.24 0 01.177.072.24.24 0 01.071.176v2.829a.24.24 0 01-.071.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.619.62c-.18.18-.336.347-.468.5a4.944 4.944 0 00-.366.485c-.112.17-.196.331-.25.485a1.834 1.834 0 00-.1.483 1.36 1.36 0 00.059.5c.05.165.133.337.248.517.115.18.266.362.452.548a6.594 6.594 0 00.425.384c.037.028.064.05.08.066a.146.146 0 01.046.093.114.114 0 01-.037.092c-.03.03-.071.04-.125.03a.441.441 0 01-.096-.024 7.724 7.724 0 01-.216-.083z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageAliasInverted
