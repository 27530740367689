import * as React from 'react'
function SvgPanelFormattingInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.7a.7.7 0 01.7-.7h10.6a.7.7 0 01.7.7v1.6a.7.7 0 01-.7.7h-.6a.7.7 0 01-.7-.7V6h-2.802l-2.083 10h.185a.7.7 0 01.7.7v.6a.7.7 0 01-.7.7H7.7a.7.7 0 01-.7-.7v-.6a.7.7 0 01.7-.7h.393l2.084-10H7v.3a.7.7 0 01-.7.7h-.6a.7.7 0 01-.7-.7V4.7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelFormattingInverted
