import * as React from 'react'
function SvgSurveyQuestionNps() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x={0.5}
        y={4.5}
        width={23}
        height={14}
        rx={5.5}
        stroke="currentColor"
      />
      <path
        d="M5.753 14.628c-.489-.002-.91-.122-1.262-.36-.35-.24-.62-.585-.81-1.038-.187-.453-.28-.997-.278-1.633 0-.635.094-1.176.282-1.623.189-.447.459-.786.81-1.02.352-.234.771-.352 1.258-.352s.905.118 1.256.353c.352.234.623.575.812 1.022.19.445.283.985.281 1.62 0 .638-.094 1.183-.284 1.636-.187.453-.456.798-.807 1.037-.35.239-.77.358-1.258.358zm0-1.02c.333 0 .6-.168.798-.503.199-.335.297-.838.296-1.508 0-.442-.046-.81-.137-1.103-.089-.293-.216-.514-.38-.662a.83.83 0 00-.577-.221c-.332 0-.597.166-.796.497-.198.331-.299.828-.3 1.489 0 .447.044.82.133 1.119.09.297.219.52.383.67a.84.84 0 00.58.222zM18.667 14.628c-.489-.002-.91-.122-1.262-.36-.35-.24-.62-.585-.81-1.038-.187-.453-.28-.997-.277-1.633 0-.635.093-1.176.28-1.623.19-.447.46-.786.81-1.02.353-.234.772-.352 1.259-.352.487 0 .905.118 1.256.353.352.234.623.575.812 1.022.19.445.283.985.281 1.62 0 .638-.094 1.183-.284 1.636-.187.453-.456.798-.807 1.037-.35.239-.77.358-1.258.358zm0-1.02c.333 0 .6-.168.798-.503.2-.335.297-.838.296-1.508 0-.442-.046-.81-.137-1.103-.089-.293-.216-.514-.38-.662a.83.83 0 00-.577-.221c-.332 0-.597.166-.796.497-.198.331-.299.828-.3 1.489 0 .447.044.82.133 1.119.09.297.219.52.383.67a.84.84 0 00.58.222zM15.003 8.682V14.5h-1.23V9.85h-.034l-1.333.835V9.594l1.44-.912h1.157zM10 5h1v13h-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSurveyQuestionNps
