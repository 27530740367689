import * as React from 'react'
function SvgCategoryDot() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17a5 5 0 100-10 5 5 0 000 10z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCategoryDot
