import * as React from 'react'
function SvgCaseSensitive() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.084 12.85l-1.02-2.886a9.994 9.994 0 01-.383-1.196 9.967 9.967 0 01-.384 1.209L7.282 12.85h2.802zm3.276 3.582h-1.514a.666.666 0 01-.413-.12.727.727 0 01-.238-.31l-.636-1.8H6.802l-.637 1.8a.743.743 0 01-.228.296.625.625 0 01-.41.134H4L7.686 7H9.68l3.679 9.431zm4.128-2.802c-.438.022-.802.06-1.093.114a2.63 2.63 0 00-.692.211.908.908 0 00-.364.296.65.65 0 00-.107.36c0 .26.073.445.218.553.145.108.348.162.608.162.299 0 .557-.053.776-.159.22-.106.437-.272.654-.497v-1.04zm-3.653-2.97a4.01 4.01 0 011.306-.797 4.443 4.443 0 011.547-.263c.399 0 .755.065 1.07.195.313.13.58.31.799.543.219.232.387.508.504.829.116.32.175.671.175 1.053v4.211h-.819a.836.836 0 01-.387-.071c-.089-.048-.161-.147-.218-.299l-.143-.429a6.445 6.445 0 01-.49.39 2.876 2.876 0 01-.491.283c-.169.076-.35.133-.543.172a3.275 3.275 0 01-.646.058c-.295 0-.564-.038-.806-.116a1.751 1.751 0 01-.628-.348 1.532 1.532 0 01-.406-.576 2.056 2.056 0 01-.143-.792c0-.247.063-.494.188-.742.126-.247.342-.47.647-.672.306-.202.713-.369 1.222-.5.51-.133 1.148-.208 1.915-.225v-.345c0-.42-.09-.729-.267-.926-.178-.197-.433-.295-.767-.295-.247 0-.453.029-.618.087a3.05 3.05 0 00-.432.189l-.348.188a.78.78 0 01-.376.088.513.513 0 01-.316-.094.862.862 0 01-.211-.224l-.319-.572z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCaseSensitive
