import * as React from 'react'
function SvgPlayPreviewBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 7.264v9.473L16.434 12 7.75 7.264zm-1.5-.927a.95.95 0 011.405-.834l10.382 5.663a.95.95 0 010 1.668L7.655 18.497a.95.95 0 01-1.405-.834V6.337z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlayPreviewBig
