import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import { controlContext } from '@packages/common/src/components/TextInput/pure/Control'

export type Props = {
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const useFormikFieldWithCustomEvents = (props: Props = {}) => {
  const controlCtx = React.useContext(controlContext)
  const [field] = useField(controlCtx.name)
  const { validateOnChange, setFieldError } = useFormikContext()

  const onChangeMemo = React.useCallback(
    (e: any) => {
      if (!validateOnChange) {
        setFieldError(field.name, undefined)
      }
      field.onChange(e)
      props.onChange?.(e)
    },
    [props.onChange]
  )

  const onBlurMemo = React.useCallback(
    (e: any) => {
      field.onBlur(e)
      props.onBlur?.(e)
    },
    [props.onBlur]
  )

  return {
    ...field,
    value: field.value,
    onChange: onChangeMemo,
    onBlur: onBlurMemo,
  }
}

export default useFormikFieldWithCustomEvents
