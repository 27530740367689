import * as React from 'react'
function SvgPanelSharing() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.93 18.805c-.521.52-1.13.87-1.828 1.047a4.192 4.192 0 01-2.094 0 3.902 3.902 0 01-1.828-1.047c-.521-.521-.87-1.13-1.047-1.828a4.192 4.192 0 010-2.094 3.902 3.902 0 011.047-1.828l2.593-2.594c.105-.104.235-.125.391-.063.156.063.234.172.234.329 0 .156.01.302.032.437.02.136-.01.245-.094.328l-2.344 2.375a2.755 2.755 0 00-.875 2.063c0 .812.287 1.505.86 2.078.572.573 1.265.86 2.078.86.812 0 1.5-.292 2.062-.876l2.5-2.469a2.892 2.892 0 00.844-2.078c0-.802-.281-1.495-.844-2.078a2.83 2.83 0 00-.875-.594c-.167-.062-.245-.177-.234-.343.01-.167.047-.334.11-.5a.286.286 0 01.187-.22.406.406 0 01.312 0c.48.21.906.5 1.281.876.521.52.875 1.13 1.063 1.828a3.967 3.967 0 010 2.094 4.022 4.022 0 01-1.063 1.828l-2.468 2.469zm-.344-5.407a4.022 4.022 0 01-1.063-1.828 3.967 3.967 0 010-2.093 4.022 4.022 0 011.063-1.829l2.469-2.468c.52-.521 1.13-.87 1.828-1.047a4.192 4.192 0 012.094 0 3.902 3.902 0 011.828 1.047c.52.52.87 1.13 1.047 1.828a4.192 4.192 0 010 2.094 3.902 3.902 0 01-1.047 1.828l-2.594 2.593c-.104.105-.234.125-.39.063-.157-.063-.235-.172-.235-.328 0-.156-.01-.302-.031-.438-.021-.135.01-.245.093-.328l2.344-2.375c.584-.562.875-1.25.875-2.062 0-.813-.286-1.506-.86-2.078a2.83 2.83 0 00-2.077-.86c-.813 0-1.5.292-2.063.875l-2.5 2.469a2.892 2.892 0 00-.844 2.078c0 .802.282 1.495.844 2.078.25.25.542.448.875.594.167.062.245.177.234.344-.01.166-.046.333-.109.5a.286.286 0 01-.187.218.406.406 0 01-.313 0c-.479-.208-.906-.5-1.281-.875z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelSharing
