import * as React from 'react'
function SvgFilesEvents() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.422 6.243a.5.5 0 01-.5-.5v-2.68a.5.5 0 011 0v2.68a.5.5 0 01-.5.5zM5.375 4.45a.5.5 0 10-.687.727l2.11 1.993a.5.5 0 00.686-.727L5.374 4.45zm4.47 4.092a.6.6 0 00-.75.751l3.749 12.034c.178.57.99.56 1.15-.016l1.838-6.547 4.96-1.69c.55-.188.54-.97-.015-1.142L9.845 8.541zm3.552 11.198L10.276 9.72l9.03 2.801-3.996 1.363a.6.6 0 00-.384.405l-1.53 5.449zM2.437 8.93a.5.5 0 01.5-.5h2.954a.5.5 0 010 1H2.937a.5.5 0 01-.5-.5zm9.256-2.468a.5.5 0 00.582.813l2.172-1.556a.5.5 0 00-.582-.813l-2.172 1.556zm-6.539 6.593a.5.5 0 00.817.578l1.431-2.022a.5.5 0 00-.816-.578l-1.432 2.022z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesEvents
