import * as React from 'react'
function SvgPageInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.781 18.781A.723.723 0 0018 18.25V5.75c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H6.75a.723.723 0 00-.531.219A.723.723 0 006 5.75v12.5c0 .208.073.385.219.531A.723.723 0 006.75 19h10.5a.723.723 0 00.531-.219zM15 10H9v1h6v-1zm-6 2h6v1H9v-1zm5 2H9v1h5v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageInverted
