import * as React from 'react'
function SvgBurgerTopbarWindows() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.75A.75.75 0 016.75 7h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 7.75zm0 4a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM6.75 15a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBurgerTopbarWindows
