import * as React from 'react'
function SvgBrandLoom() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.963 10.983H15.71l4.55-2.627-1-1.73-4.549 2.626 2.626-4.549-1.73-1-2.626 4.55V3h-1.998v5.253l-2.627-4.55-1.73 1L9.251 9.25l-4.55-2.625-.998 1.73 4.549 2.626H3v1.998h5.252l-4.548 2.627.999 1.73 4.548-2.625-2.626 4.548 1.73 1 2.627-4.55v5.253h1.998v-5.252l2.626 4.548 1.73-.999-2.626-4.549 4.55 2.627.999-1.731-4.549-2.626h5.252v-1.998h.001zm-8.981 3.716a2.728 2.728 0 110-5.455 2.728 2.728 0 010 5.455z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandLoom
