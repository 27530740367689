import * as React from 'react'
function SvgPageHistory() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.09 13a6.2 6.2 0 01-10.371 4.587l-.87.967a7.5 7.5 0 10-2.446-5.095l-1.96-1.829-.886.951 3.578 3.337 3.337-3.578-.951-.887-1.818 1.95A6.2 6.2 0 1118.09 13zm-6.85-4h1.3v3.914l3.275 1.814-.63 1.137-3.945-2.185V9z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageHistory
