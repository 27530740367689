import * as React from 'react'
function SvgAlertSuccessBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm2 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-4.925-3.092a.994.994 0 10-1.482-1.325l-6.34 7.153a.937.937 0 01-1.444-.052l-1.924-2.508a.976.976 0 10-1.528 1.215l2.668 3.235a2 2 0 003.028.067l7.022-7.785z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertSuccessBig
