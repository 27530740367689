import * as React from 'react'
function SvgClearStyling() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm4.294-4.436a5 5 0 00-7.24-6.603l7.24 6.603zm-1.347 1.476l-7.24-6.604a5 5 0 007.24 6.603z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgClearStyling
