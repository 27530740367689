import * as React from 'react'
function SvgFilesGettingStarted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25 7.022a.5.5 0 01.73-.444l9.598 4.987a.5.5 0 01.003.885l-9.597 5.083a.5.5 0 01-.734-.442V7.021z"
        stroke="currentColor"
      />
    </svg>
  )
}
export default SvgFilesGettingStarted
