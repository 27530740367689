import * as React from 'react'
function SvgBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.013 12.75c.375.258.68.583.914.976.234.386.351.843.351 1.37 0 .633-.134 1.17-.404 1.61-.264.433-.644.775-1.143 1.027-.527.27-1.136.46-1.828.572-.685.111-1.494.167-2.426.167H6v-.686c.182-.017.41-.044.686-.079.275-.035.468-.076.58-.123.216-.088.363-.202.44-.343.081-.146.122-.334.122-.562v-8.79c0-.21-.032-.386-.097-.527-.064-.14-.22-.263-.465-.369a3.252 3.252 0 00-.633-.184c-.24-.053-.451-.094-.633-.123V6h6.776c1.705 0 2.936.234 3.692.703.756.469 1.134 1.16 1.134 2.074 0 .422-.085.794-.255 1.117-.17.316-.413.591-.73.826a4.38 4.38 0 01-1.028.562c-.399.158-.826.29-1.283.396v.167c.457.047.931.15 1.424.307.492.153.92.352 1.283.598zm-2.83-3.753c0-.691-.197-1.23-.59-1.617-.386-.387-.969-.58-1.748-.58-.112 0-.258.006-.44.017a38.47 38.47 0 00-.457.018v4.623h.457c.95 0 1.65-.22 2.1-.66.452-.444.678-1.045.678-1.8zm.615 5.985c0-.867-.258-1.532-.774-1.995-.515-.463-1.256-.694-2.223-.694-.111 0-.261.006-.449.018a13.32 13.32 0 00-.404.017v4.562c.053.216.217.401.492.553.276.147.61.22 1.002.22.698 0 1.263-.234 1.697-.703.44-.469.659-1.128.659-1.978z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBold
