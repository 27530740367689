import * as React from 'react'
import Text, { Props as TextProps, getLineHeight } from './Text'
import icons, { IconNames } from './icons'
import { StandardError } from '@packages/common/src/errors/standard-error'

export type IconName = IconNames | 'transparent'

export type Props = Omit<TextProps, 'children'> & {
  name: IconName
}

class MissingIconError extends StandardError {
  constructor(name: string) {
    super('Missing icon: ' + name)
  }
}

const defaultStyle = ({ scale = 0, fontFamily, theme }: TextProps) => {
  const size = getLineHeight({ fontFamily, scale }, theme)

  return {
    width: size,
    height: size,
    fontSize: size,
  }
}

const Icon = ({ name, style, color = 'inherit', ...props }: Props) => {
  if (name === 'transparent') {
    // special
    return (
      <Text {...props} style={[defaultStyle, style]} fontFamily="icon">
        &nbsp;
      </Text>
    )
  }

  const SvgIcon = icons[name]

  if (!SvgIcon) {
    if (process.env.NODE_ENV !== 'production') {
      throw new MissingIconError(name)
    }
    return null
  }

  return (
    <Text
      {...props}
      style={[defaultStyle, style]}
      color={color}
      fontFamily="icon">
      <SvgIcon />
    </Text>
  )
}

export default Icon
