import * as React from 'react'
function SvgTrippledot() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.502 9A3.502 3.502 0 004 12.5C4 14.43 5.568 16 7.502 16h9.996A3.502 3.502 0 0021 12.5c0-1.93-1.568-3.5-3.502-3.5H7.502zM19 12.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM12.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 12.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTrippledot
