import * as React from 'react'
function SvgBrandWindows() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4.754l8.196-1.129v7.92H2v-6.79zm0 14.492l8.196 1.129v-7.821H2v6.691zm9.098 1.25L22 22v-9.446H11.098v7.941zm0-16.992v8.04H22V2L11.098 3.504z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandWindows
