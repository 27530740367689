import * as React from 'react'
function SvgCollapseUp() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.31 8.804a.373.373 0 00-.53 0l-4.364 4.364a.373.373 0 000 .53l.977.976a.373.373 0 00.53 0l3.122-3.123 3.123 3.123a.373.373 0 00.53 0l.976-.977a.373.373 0 000-.53L12.31 8.805z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCollapseUp
