import * as React from 'react'
function SvgTableBackspace() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7h-7.165c-.33 0-.804.177-1.052.394l-5.097 4.46a.512.512 0 000 .79l5.097 4.46c.248.218.722.396 1.052.396H17.5c.825 0 1.5-.675 1.5-1.5V8.5c0-.825-.675-1.5-1.5-1.5zm-2.107 8.25l-1.917-1.918-1.917 1.918-1.082-1.082 1.916-1.918-1.917-1.918L11.56 9.25l1.918 1.916 1.916-1.916 1.083 1.082-1.917 1.918 1.918 1.916-1.084 1.084z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTableBackspace
