import * as React from 'react'
function SvgDescriptionForAi() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 17.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H6.75a.723.723 0 01-.531-.219A.723.723 0 016 17.25v-9.5c0-.208.073-.385.219-.531A.723.723 0 016.75 7h10.5c.254 0 .393.065.531.219.139.154.219.297.219.531v9.5zM17 17V8H7v9h10zm-2-7H9v1h6v-1zm-6 2h6v1H9v-1zm5 2H9v1h5v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDescriptionForAi
