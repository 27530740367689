import * as React from 'react'
function SvgTemplatePlaceholder() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeDasharray="5 2"
        d="M4.5 4.5h16v15h-16z"
      />
    </svg>
  )
}
export default SvgTemplatePlaceholder
