import * as React from 'react'
function SvgCommentCountSingle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.15a.35.35 0 00-.35.35v6c0 .193.157.35.35.35h2.65v2.35l3.133-2.35H17a.35.35 0 00.35-.35v-6a.35.35 0 00-.35-.35H6zm-1.65.35c0-.911.739-1.65 1.65-1.65h11c.911 0 1.65.739 1.65 1.65v6A1.65 1.65 0 0117 16.15h-4.783L7.35 19.8v-3.65H6a1.65 1.65 0 01-1.65-1.65v-6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCommentCountSingle
