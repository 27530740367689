import * as React from 'react'
function SvgHiddenComment() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.75 12.031c0-.237-.064-.456-.191-.656a8.753 8.753 0 00-3.2-3.172C14.992 7.401 13.497 7 11.875 7c-1.622 0-3.117.401-4.484 1.203a8.752 8.752 0 00-3.2 3.172c-.127.2-.191.42-.191.656 0 .237.064.456.191.656a8.753 8.753 0 003.2 3.172c1.367.802 2.862 1.204 4.484 1.204 1.622 0 3.117-.402 4.484-1.204a8.753 8.753 0 003.2-3.171c.127-.201.191-.42.191-.657zm-4.06 2.707c-1.158.675-2.43 1.012-3.815 1.012-1.385 0-2.657-.337-3.814-1.012a7.598 7.598 0 01-2.748-2.707A7.543 7.543 0 017.8 9.488a7.552 7.552 0 013.418-1.148c.291.292.437.647.437 1.066 0 .42-.15.78-.45 1.08-.302.301-.662.451-1.081.451-.42 0-.78-.15-1.08-.45-.3-.302-.451-.661-.451-1.08a3.664 3.664 0 00-.438 1.75c0 .674.169 1.293.506 1.859a3.85 3.85 0 001.354 1.353 3.56 3.56 0 001.859.506c.675 0 1.294-.169 1.86-.506a3.85 3.85 0 001.353-1.353 3.562 3.562 0 00.506-1.86c0-.838-.255-1.595-.766-2.27a7.677 7.677 0 012.064 1.3 7.89 7.89 0 011.546 1.845 7.598 7.598 0 01-2.749 2.707z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHiddenComment
