import React from 'react'
import AlertEmitter from '@packages/common/src/components/Alerts/common/alert-emitter'
import {
  AnyAlert,
  AlertConstructor,
} from '@packages/common/src/components/Alerts/common/types'

// provide common alert context and hooks

export const createAlertEmitterCtx = <Alert extends AnyAlert>() =>
  React.createContext<AlertEmitter<Alert>>({} as any)

export const useEmitterActions = <Alert extends AnyAlert>(
  emitter: AlertEmitter<Alert>
) => {
  const notify = useNotify(emitter)
  const close = useClose(emitter)

  return React.useMemo(() => ({ notify, close }), [notify, close])
}

const useNotify = <Alert extends AnyAlert>(emitter: AlertEmitter<Alert>) =>
  React.useCallback(
    (alert: AlertConstructor<Alert>) => {
      emitter.push(alert)
    },
    [emitter]
  )

const useClose = <Alert extends AnyAlert>(emitter: AlertEmitter<Alert>) =>
  React.useCallback(
    (id: string) => {
      emitter.close(id)
    },
    [emitter]
  )

export const useLastEmittedAlert = <Alert extends AnyAlert>(
  emitter: AlertEmitter<Alert>
): Alert | undefined => {
  const [lastAlert, setLastAlert] = React.useState<Alert>()

  React.useEffect(() => {
    const listenerNew = emitter.onPush(setLastAlert)
    const listenerClose = emitter.onClose(id => {
      setLastAlert(alert => (alert?.id === id ? undefined : alert))
    })

    return () => {
      listenerNew.unbind()
      listenerClose.unbind()
    }
  }, [emitter])

  return lastAlert
}
