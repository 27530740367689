import axiosFactory, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  Cancel,
} from 'axios'
import { ApiError, ConnectionError } from './errors'
import { Disposable } from '@packages/common/src/disposable'
import { AbortSignalError } from '@packages/common/src/utils/cancelable-promise'
import { StandardError } from '../../errors/standard-error'

let axiosConfigured: AxiosInstance

class AxiosNotConfiguredError extends StandardError {}

const getAxios = () => {
  if (!axiosConfigured) {
    throw new AxiosNotConfiguredError()
  }

  return axiosConfigured
}

const isAxiosNetworkError = (error: any): error is AxiosError =>
  error?.isAxiosError

const isAxiosCancelError = (error: any): error is Cancel =>
  error?.__CANCEL__ === true

export const transformError = (err: any): any => {
  if (isAxiosNetworkError(err)) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return new ApiError(
        err.response.status,
        err.response.config.url || '',
        err.response.data,
        err.response.config.headers
      )
    }

    if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      return new ConnectionError()
    }
  }

  if (isAxiosCancelError(err)) {
    return new AbortSignalError()
  }

  // Something happened in setting up the request that triggered an Error
  return err
}

export const authHeader = (token: string) => (token ? `bearer ${token}` : '')

type Callbacks = {
  onSuccess?: (res: AxiosResponse) => void
  onError?: (err: unknown) => void
}

// custom optional interceptors
export const setCallbacks = (
  axiosInstance: AxiosInstance,
  { onSuccess, onError }: Callbacks
): Disposable => {
  const interceptor1 = axiosInstance.interceptors.response.use(res => {
    if (onSuccess) {
      onSuccess(res)
    }
    return res
  })

  const interceptor2 = axiosInstance.interceptors.response.use(
    res => res,
    err => {
      // Keep success/error interceptors separated. This is the only way how to
      // catch errors thrown in previous onSuccess callback

      if (onError) {
        onError(err)
      }

      return Promise.reject(err)
    }
  )

  return {
    dispose() {
      axiosInstance.interceptors.response.eject(interceptor1)
      axiosInstance.interceptors.response.eject(interceptor2)
    },
  }
}

export const configureAxios = (baseURL: string) => {
  axiosConfigured = axiosFactory.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
  })

  // default interceptors
  axiosConfigured.interceptors.response.use(
    res => res,
    err => Promise.reject(transformError(err))
  )
}

export default getAxios
