import { Integration } from '@sentry/types'

// @ts-ignore
let SentryImpl

if (process.browser) {
  SentryImpl = require('@sentry/browser')
} else {
  SentryImpl = require('@sentry/node')
}

const integrations = (integrationsArr: Integration[]) =>
  // do not change console.log source in development
  integrationsArr.filter(integration => integration.name !== 'Breadcrumbs')

const configureErrorsReporting = () => {
  if (process.env.NEXT_PUBLIC_ENABLE_SENTRY !== '1') return

  // @ts-ignore
  SentryImpl.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.VERSION,
    environment: process.env.APP_ENV,
    integrations:
      process.env.NODE_ENV === 'development' ? integrations : undefined,
  })
}

export const Sentry = SentryImpl

export default configureErrorsReporting
