import * as React from 'react'
function SvgFilesCustomers() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.495 19.119a8 8 0 10-8.99 0c.045-1.564.366-3.017 1.031-4.12.514-.852 1.233-1.49 2.159-1.797a3 3 0 112.61 0c.926.307 1.645.945 2.159 1.797.665 1.103.986 2.556 1.03 4.12zm-.962.56a.5.5 0 01-.033-.179c0-1.606-.302-3.005-.893-3.984C14.032 14.56 13.185 14 12 14c-1.185 0-2.032.561-2.607 1.516-.591.979-.893 2.378-.893 3.984a.499.499 0 01-.033.18A7.967 7.967 0 0012 20.5a7.967 7.967 0 003.533-.82zM12 21.5a9 9 0 100-18 9 9 0 000 18zm0-9a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesCustomers
