import * as React from 'react'
function SvgAlertWarningSmall() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.883 5a1 1 0 01.994 1.11l-.778 7a1 1 0 01-.994.89h-.159a1 1 0 01-.998-.944l-.39-7a1 1 0 011-1.056h1.325zM12 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertWarningSmall
