import * as React from 'react'
function SvgFilesReviewRequests() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.781 19.281A.723.723 0 0017 18.75V16.5h-1v2H6v-12h10v3h1V6.25c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H5.75a.723.723 0 00-.531.219A.723.723 0 005 6.25v12.5c0 .208.073.385.219.531a.723.723 0 00.531.219h10.5a.723.723 0 00.531-.219zM14.5 15.707l5.854-5.853a.5.5 0 00-.708-.708L14.5 14.293l-2.146-2.147a.5.5 0 00-.708.708l2.854 2.853z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesReviewRequests
