// @ts-ignore why cannot be found?
import type { default as GraphemeBreakerNodeCompatible } from 'grapheme-breaker-mjs'
import { StandardError } from '../errors/standard-error'
import { InvalidPositionError } from '../errors/common-errors'

export class NegativeCaretPositionError extends StandardError {}

export class InvalidStringIndex extends StandardError {}

let GraphemeBreaker: typeof GraphemeBreakerNodeCompatible

try {
  GraphemeBreaker = require('grapheme-breaker-mjs').default

  // eslint-disable-next-line no-restricted-syntax
  if (!GraphemeBreaker) throw new Error()
} catch {
  // todo: CLI-1364
  // For tests only. Do we need it?
  GraphemeBreaker = require('grapheme-breaker-u10-0')
}

export const cmpAlphabetically = (a: string, b: string): number =>
  a.localeCompare(b)

export const stripAccents = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const stringToGraphemes = (str: string): string[] =>
  GraphemeBreaker.break(str)

export const truncate = (str: string, maxLength: number) => {
  const graphemes = stringToGraphemes(str)

  let truncated = graphemes.slice(0, maxLength).join('')

  if (graphemes.length > maxLength) {
    truncated += '…'
  }

  return truncated
}

export const stringIndexToGraphemeIndex = (
  graphemes: string[],
  stringIndex: number,
  stringIndexInGrapheme: 'grapheme_start' | 'grapheme_end'
) => {
  if (graphemes.length === 0) {
    throw new InvalidStringIndex(`Graphemes cannot be empty`)
  }

  let index = stringIndex
  const foundIndex = graphemes.findIndex(grapheme => {
    if (stringIndexInGrapheme === 'grapheme_start' && index === 0) {
      return true
    }

    index -= grapheme.length

    if (stringIndexInGrapheme === 'grapheme_end' && index === -1) {
      return true
    }

    if (index < -1) {
      throw new InvalidStringIndex()
    }

    return false
  })

  if (foundIndex === -1) {
    throw new InvalidStringIndex()
  }

  return foundIndex
}

export const graphemeCaretPositionToStringLength = (
  graphemes: string[],
  graphemeCaretPosition: number
) => {
  if (graphemeCaretPosition < 0) {
    throw new NegativeCaretPositionError()
  }

  if (graphemeCaretPosition > graphemes.length) {
    throw new InvalidPositionError(graphemeCaretPosition, graphemes.length)
  }

  return graphemes.slice(0, graphemeCaretPosition).join('').length
}
