import * as React from 'react'
function SvgPanelKeyboardShortcuts() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.417 6H3.583C2.71 6 2 6.672 2 7.5v9c0 .828.709 1.5 1.583 1.5h15.834C20.29 18 21 17.328 21 16.5v-9c0-.828-.709-1.5-1.583-1.5zm.527 10.5c0 .276-.236.5-.527.5H3.583a.515.515 0 01-.527-.5v-9c0-.276.236-.5.527-.5h15.834c.29 0 .527.224.527.5v9zM7.542 12.375v-.75a.386.386 0 00-.396-.375h-.792a.386.386 0 00-.396.375v.75c0 .207.178.375.396.375h.792a.386.386 0 00.396-.375zm3.166 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.791a.386.386 0 00.396-.375zm3.167 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.791a.386.386 0 00.396-.375zm3.167 0v-.75a.386.386 0 00-.396-.375h-.792a.386.386 0 00-.396.375v.75c0 .207.178.375.396.375h.792a.386.386 0 00.396-.375zm-11.084 2.5v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.792a.386.386 0 00.395-.375zm12.667 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.395.375h.792a.386.386 0 00.396-.375zm-12.667-5v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.792a.386.386 0 00.395-.375zm3.167 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.791a.386.386 0 00.396-.375zm3.167 0v-.75a.386.386 0 00-.396-.375h-.792a.386.386 0 00-.396.375v.75c0 .207.178.375.396.375h.792a.386.386 0 00.396-.375zm3.166 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.396.375h.791a.386.386 0 00.396-.375zm3.167 0v-.75a.386.386 0 00-.396-.375h-.791a.386.386 0 00-.396.375v.75c0 .207.177.375.395.375h.792a.386.386 0 00.396-.375zm-3.167 4.75v-.25a.386.386 0 00-.396-.375H7.939a.386.386 0 00-.396.375v.25c0 .207.177.375.396.375h7.125a.386.386 0 00.395-.375z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelKeyboardShortcuts
