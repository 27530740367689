import * as React from 'react'
function SvgInlineCode() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.293 4.5H13L9 19h1.293l4-14.5zM15 9l1-1 4 4-4 4-1-1 3-3-3-3zM7.5 8l1 1L5 12l3.5 3-1 1L3 12l4.5-4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInlineCode
