import * as React from 'react'
function SvgFilesFields() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm2 3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm2.5 2.5a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesFields
