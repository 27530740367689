import * as React from 'react'
function SvgFolderSharedLockedAliasInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6v-.667C21 4.053 19.75 4 18.5 4S16 4.052 16 5.333V6h-1v7h7V6h-1zm-1 8v4.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 20H5.75c-.202 0-.391-.03-.569-.091L6 19v-5H4v-3h10v3h6zm-6-6h-4v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 008.25 6h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 004 7.75V10h10V8zm3-2.667V6h3v-.667C20 5 19.603 5 18.501 5 17.4 5 17 5 17 5.333zM16 15h1v1h-1v2h-2v-2h-1v2h-2v-2h-1v2H8v-2H7v-1h9zm-3-3h-2v2h2v-2zm-3 0H8v2h2v-2zM2.935 22.387c-.76-.29-1.291-.59-1.597-.895-.366-.366-.624-.722-.773-1.066-.444-1.04-.01-2.217 1.303-3.53l.62-.619-.708-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.177-.071h2.828a.24.24 0 01.177.072.24.24 0 01.072.176v2.829a.24.24 0 01-.072.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.618.62c-.18.18-.337.347-.469.5a4.944 4.944 0 00-.366.485c-.112.17-.195.331-.25.485a1.834 1.834 0 00-.099.483 1.36 1.36 0 00.058.5c.05.165.134.337.249.517.115.18.265.362.451.548a6.594 6.594 0 00.426.384c.036.028.063.05.08.066a.146.146 0 01.045.093.114.114 0 01-.037.092c-.03.03-.07.04-.124.03a.441.441 0 01-.097-.024 7.724 7.724 0 01-.215-.083zM19 9h-1v2h1V9z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderSharedLockedAliasInverted
