import { paths } from '@packages/common/src/providers/network/open-api-schema'

type AnyObject = Record<string, string>

export type RequestUrl = keyof paths

export type RequestMethod<Url extends RequestUrl> = keyof paths[Url]

export type RequestBody<
  Url extends RequestUrl,
  Method extends RequestMethod<Url>
> = paths[Url][Method] extends {
  requestBody: { content: { 'application/json': any } }
}
  ? paths[Url][Method]['requestBody']['content']['application/json']
  : undefined

export type ResponseCode<
  Url extends keyof paths,
  Method extends RequestMethod<Url>
> = keyof (paths[Url][Method] extends { responses: any }
  ? paths[Url][Method]['responses']
  : never)

export type RequestSlug<
  Url extends keyof paths,
  Method extends RequestMethod<Url>
> = paths[Url][Method] extends { parameters: { path: any } }
  ? paths[Url][Method]['parameters']['path']
  : undefined

export type RequestQuery<
  Url extends RequestUrl,
  Method extends RequestMethod<Url>
> = paths[Url][Method] extends { parameters: { query: any } }
  ? paths[Url][Method]['parameters']['query']
  : undefined

export type OpenApiQuery<
  Url extends RequestUrl,
  Method extends RequestMethod<Url>,
  Data extends RequestBody<Url, Method>,
  Code extends ResponseCode<Url, Method>
> = {
  _urlSchema: Url
  url: string
  method: Method
  headers?: AnyObject
  data: Data
  codeHint: Code // typescript hint
}

export type ResponseOf<
  Url extends RequestUrl,
  Method extends RequestMethod<Url>,
  Code extends ResponseCode<Url, Method>
> = paths[Url][Method] extends {
  responses: Record<Code, { content: { 'application/json': any } }>
}
  ? paths[Url][Method]['responses'][Code]['content']['application/json']
  : never

export type InfiniteResponseOf<
  Url extends RequestUrl,
  Method extends RequestMethod<Url>,
  Code extends ResponseCode<Url, Method>
> = paths[Url][Method] extends {
  responses: Record<
    Code,
    { content: { 'application/json': { data: any[]; hasMore: boolean } } }
  >
}
  ? paths[Url][Method]['responses'][Code]['content']['application/json']
  : never

export type OpenApiQueryResponse<Q extends OpenApiQuery<any, any, any, any>> =
  ResponseOf<Q['_urlSchema'], Q['method'], Q['codeHint']>

export const buildQuery = <
  Url extends RequestUrl,
  Method extends RequestMethod<Url>,
  Code extends ResponseCode<Url, Method>,
  Data extends RequestBody<Url, Method>,
  Queries extends RequestQuery<Url, Method>,
  Slugs extends RequestSlug<Url, Method>
>(
  url: Url,
  method: Method,
  codeHint: Code,
  extra?: {
    headers?: AnyObject
    queries?: Queries
  } & (Data extends undefined
    ? {
        data?: undefined
      }
    : {
        data: Data
      }) &
    (Slugs extends undefined
      ? {
          slugs?: undefined
        }
      : {
          slugs: Slugs
        })
): OpenApiQuery<Url, Method, Data, Code> => ({
  _urlSchema: url,
  url: buildUrl(url, extra?.queries, extra?.slugs),
  method,
  data: extra?.data,
  headers: extra?.headers,
  codeHint,
})

export const buildUrl = (
  urlSchema: string,
  queries: AnyObject = {},
  slugs: AnyObject = {}
) => {
  let url = `${urlSchema}`
  Object.entries(slugs).forEach(([param, value]) => {
    url = url.replace(`{${param}}`, `${value}`)
  })

  const filteredQueries = Object.fromEntries(
    Object.entries(queries).filter(([, value]) => value !== undefined)
  )

  const queriesString = new URLSearchParams(filteredQueries).toString()
  if (queriesString) {
    url += '?' + queriesString
  }

  return url
}
