import * as React from 'react'
function SvgDownload1() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.5a1 1 0 10-2 0v9.086l-2.293-2.293a1 1 0 00-1.414 1.414l4 4 .707.707.707-.706v-.002l4-3.999a1 1 0 00-1.414-1.414L13 13.586V4.5zM6 19a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDownload1
