import * as React from 'react'
function SvgMove() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12a.48.48 0 01-.148.352l-2 2a.48.48 0 01-.352.148.48.48 0 01-.352-.148A.48.48 0 0116 14v-1h-3v3h1a.48.48 0 01.352.148.48.48 0 01.148.352.48.48 0 01-.148.352l-2 2A.48.48 0 0112 19a.48.48 0 01-.352-.148l-2-2A.48.48 0 019.5 16.5a.48.48 0 01.148-.352A.48.48 0 0110 16h1v-3H8v1a.48.48 0 01-.148.352.48.48 0 01-.352.148.48.48 0 01-.352-.148l-2-2A.48.48 0 015 12a.48.48 0 01.148-.352l2-2A.48.48 0 017.5 9.5a.48.48 0 01.352.148A.48.48 0 018 10v1h3V8h-1a.48.48 0 01-.352-.148A.48.48 0 019.5 7.5a.48.48 0 01.148-.352l2-2A.48.48 0 0112 5a.48.48 0 01.352.148l2 2a.48.48 0 01.148.352.48.48 0 01-.148.352A.48.48 0 0114 8h-1v3h3v-1a.48.48 0 01.148-.352.48.48 0 01.352-.148.48.48 0 01.352.148l2 2A.48.48 0 0119 12z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMove
