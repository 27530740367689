import * as React from 'react'
function SvgChevronSmallRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.695c0-.085.042-.18.106-.244l4.164-4.164-4.164-4.164A.365.365 0 019 7.88c0-.084.042-.18.106-.243l.53-.53A.365.365 0 019.879 7c.085 0 .18.042.244.106l4.937 4.937a.365.365 0 01.106.244c0 .085-.042.18-.106.244l-4.937 4.937a.365.365 0 01-.244.106.365.365 0 01-.243-.106l-.53-.53A.345.345 0 019 16.695z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallRight
