import * as React from 'react'
function SvgFeatureLink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 5l7 6.707-7 6.707-2.765-2.65.62-.619L14 17.183l5.763-5.476L14 6.231l-2.445 2.324-.619-.62L14 5z"
        fill="currentColor"
      />
      <path
        d="M7.334 8.854l2.459 2.458H3v1h6.793l-2.459 2.459.707.707 3.666-3.666-3.666-3.666-.707.708z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFeatureLink
