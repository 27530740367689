import * as React from 'react'
function SvgImage() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6.5H5a1 1 0 00-1 1v10c0 .09.012.176.034.26l3.929-3.93 2.5 2 5.037-5.037 4.5 4.5V7.5a1 1 0 00-1-1zm-14 12a1 1 0 01-.259-.034l3.296-3.296 2.5 2 4.963-4.963 4.5 4.5v.793a1 1 0 01-1 1H5zm0-13a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2v-10a2 2 0 00-2-2H5zm4 5a1 1 0 11-2 0 1 1 0 012 0zm1 0a2 2 0 11-4 0 2 2 0 014 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgImage
