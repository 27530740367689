import * as React from 'react'
function SvgChevronSmallDoubleDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92 6.106A.365.365 0 007.675 6a.345.345 0 00-.244.106l-.53.53a.365.365 0 00-.106.243c0 .085.043.18.106.244l4.937 4.937a.365.365 0 00.244.106c.085 0 .18-.042.244-.106l4.937-4.937a.365.365 0 00.106-.244.365.365 0 00-.106-.243l-.53-.53A.365.365 0 0016.491 6a.365.365 0 00-.244.106l-4.164 4.164L7.92 6.106zm0 6.204a.365.365 0 00-.244-.106.345.345 0 00-.244.106l-.53.53a.365.365 0 00-.106.243c0 .085.043.18.106.244l4.937 4.937a.365.365 0 00.244.106c.085 0 .18-.042.244-.106l4.937-4.937a.365.365 0 00.106-.244.365.365 0 00-.106-.244l-.53-.53a.365.365 0 00-.243-.105.365.365 0 00-.244.106l-4.164 4.164L7.92 12.31z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDoubleDown
