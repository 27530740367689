// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const pageView = (url: string) => {
  if (process.env.NEXT_PUBLIC_ENABLE_ANALYTIC !== '1' || !window.gtag) return

  window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_path: url,
  })
}
