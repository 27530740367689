import * as React from 'react'
function SvgHorizontalRule() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M4 11h16v2H4z" />
    </svg>
  )
}
export default SvgHorizontalRule
