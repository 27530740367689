import * as React from 'react'
function SvgUserFeedbackNote() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6h12v7h-5v5H6V6zm8 11.086L17.086 14H14v3.086zM5 6a1 1 0 011-1h12a1 1 0 011 1v7.086a1 1 0 01-.293.707l-4.914 4.914a1 1 0 01-.707.293H6a1 1 0 01-1-1V6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUserFeedbackNote
