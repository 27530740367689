import * as React from 'react'
function SvgBurgerBigTiny() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.5a.5.5 0 01.5-.5h17a.5.5 0 010 1h-17a.5.5 0 01-.5-.5zm0 6a.5.5 0 01.5-.5h17a.5.5 0 010 1h-17a.5.5 0 01-.5-.5zm.5 5.5a.5.5 0 000 1h17a.5.5 0 000-1h-17z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBurgerBigTiny
