import * as React from 'react'
function SvgPanelCommandPalette() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.105 12.659a.286.286 0 000-.411l-4.16-4.16a.286.286 0 00-.412 0l-.446.445a.286.286 0 000 .411l3.509 3.51-3.509 3.508a.286.286 0 000 .41l.446.447a.286.286 0 00.411 0l4.16-4.16zm6.634 4.08c0-.16-.084-.286-.192-.286h-5.759c-.108 0-.192.125-.192.286v.571c0 .161.084.286.192.286h5.759c.108 0 .192-.125.192-.286v-.571z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelCommandPalette
