import * as React from 'react'
function SvgItemProblemV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 18a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 1a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm-.917-11.003a.92.92 0 111.834 0L12 13h-1l-.417-5.003zM11.5 16a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemProblemV2
