import * as React from 'react'
function SvgModeLight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 6V3h1v3h-1z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17a5 5 0 100-10 5 5 0 000 10zm0-1a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
      <path
        d="M5.282 5.99l2.122 2.12.707-.706L5.99 5.282l-.708.708zM3 11.5h3v1H3v-1zM7.404 15.89l-2.121 2.12.707.707 2.12-2.12-.706-.708zM18 11.5h3v1h-3v-1zM18.01 5.282l-2.12 2.122.706.707 2.122-2.121-.708-.708zM11.5 21v-3h1v3h-1zM15.89 16.596l2.12 2.122.707-.708-2.12-2.12-.708.706z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgModeLight
