import * as React from 'react'
function SvgList() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.5a1 1 0 11-2 0 1 1 0 012 0zm0 5a1 1 0 11-2 0 1 1 0 012 0zm-1 6a1 1 0 100-2 1 1 0 000 2zM9.5 6a.5.5 0 000 1h10a.5.5 0 000-1h-10zM9 11.5a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm.5 4.5a.5.5 0 000 1h10a.5.5 0 000-1h-10z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgList
