import * as React from 'react'
function SvgWindowsShellClose() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.708 12L17 16.292l-.708.708L12 12.708 7.708 17 7 16.292 11.292 12 7 7.708 7.708 7 12 11.292 16.292 7l.708.708L12.708 12z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgWindowsShellClose
