import * as React from 'react'
function SvgFolderMissingParentInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.75V9H4V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234zM4 10h16v7.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19h-5.566a.779.779 0 00.426-.684.776.776 0 00-.767-.76.764.764 0 00-.348 1.444H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V10zm8.924 7.038h-1.21v-.109c.002-1.119.303-1.46.84-1.8.393-.249.696-.527.696-.945 0-.445-.348-.732-.78-.732-.418 0-.805.278-.824.799h-1.304c.029-1.279.975-1.886 2.135-1.886 1.269 0 2.17.652 2.17 1.77 0 .752-.39 1.222-.988 1.576-.505.304-.728.595-.735 1.218v.109z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderMissingParentInverted
