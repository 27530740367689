import * as React from 'react'
function SvgMergeCells() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7h7v2h-1V8H5v7h5v-1h1v2H4V7zm9 9h7V7h-7v2h1V8h5v7h-5v-1h-1v2zm-5-4v2l2.94-2.5L8 9v2H6v1h2zm7.94-1H18v1h-2.06v2L13 11.5 15.94 9v2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMergeCells
