import * as React from 'react'
function SvgPlayPreviewSmall() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 8.782v6.436L15.47 12 9.75 8.782zm-1.5-.94a.95.95 0 011.416-.828l7.392 4.158a.95.95 0 010 1.656l-7.392 4.158a.95.95 0 01-1.416-.828V7.842z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlayPreviewSmall
