import * as React from 'react'
function SvgMedia() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 7c.133 0 .25.117.25.25v9.5c0 .133-.117.25-.25.25H6.25a.257.257 0 01-.25-.25v-9.5c0-.133.117-.25.25-.25h12.5zm1.25.25C20 6.562 19.437 6 18.75 6H6.25C5.562 6 5 6.563 5 7.25v9.5c0 .688.563 1.25 1.25 1.25h12.5c.688 0 1.25-.563 1.25-1.25v-9.5zM10 9.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm4.75-.25L18 12.5V16H7v-1.5L9.5 12l1.25 1.25 4-4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMedia
