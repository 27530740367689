import * as React from 'react'
function SvgSelection() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8h11v4H4V8zm3 5h14v4H7v-4zm11-5h-2v4h2V8zM4 13h2v4H4v-4zm17-5h-2v4h2V8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSelection
