import * as React from 'react'
function SvgSpecifications() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7a2 2 0 012-2h15a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V7zm7-1h5v2h-5V6zM9 8V6H5a1 1 0 00-1 1v1h5zM4 9h5v9H5a1 1 0 01-1-1V9zm17-1V7a1 1 0 00-1-1h-4v2h5zm-5 1h5v8a1 1 0 01-1 1h-4V9zm-1 0v9h-5V9h5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSpecifications
