import * as React from 'react'
function SvgArrowDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.066 12.594l.43.449a.452.452 0 01.137.332c0 .13-.046.24-.137.332l-3.789 3.79a.452.452 0 01-.332.136.452.452 0 01-.332-.137l-3.79-3.789a.452.452 0 01-.136-.332c0-.13.046-.24.137-.332l.43-.45a.42.42 0 01.332-.126c.13.006.24.055.332.146l2.246 2.344V7.352c0-.13.045-.241.136-.332a.452.452 0 01.332-.137h.626c.13 0 .24.045.332.137a.452.452 0 01.136.332v7.605l2.246-2.344a.488.488 0 01.332-.146.42.42 0 01.332.127z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowDown
