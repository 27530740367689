import * as React from 'react'
function SvgPlusBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.258 12.113v1.032a.374.374 0 01-.113.274.374.374 0 01-.274.113h-2.839v2.839a.374.374 0 01-.113.274.374.374 0 01-.274.113h-1.032a.374.374 0 01-.274-.113.373.373 0 01-.113-.274v-2.839H8.387a.374.374 0 01-.274-.113.373.373 0 01-.113-.274v-1.032c0-.108.038-.199.113-.274a.373.373 0 01.274-.113h2.839V8.887c0-.107.037-.199.113-.274a.373.373 0 01.274-.113h1.032c.108 0 .2.038.274.113a.374.374 0 01.113.274v2.839h2.839c.107 0 .199.037.274.113a.374.374 0 01.113.274z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusBold
