import * as React from 'react'
function SvgFolderShared() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17.25v-8.5c0-.48-.172-.89-.516-1.234A1.684 1.684 0 0018.25 7H10v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 008.25 5h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 004 6.75v10.5c0 .48.172.89.516 1.234.343.344.755.516 1.234.516h12.5c.48 0 .89-.172 1.234-.516.344-.343.516-.755.516-1.234zm-1 0V10H5v7.25c0 .208.073.385.219.531A.723.723 0 005.75 18h12.5a.723.723 0 00.531-.219.723.723 0 00.219-.531zm0-8.5V9H5V6.75c0-.208.073-.385.219-.531A.723.723 0 015.75 6h2.5c.208 0 .385.073.531.219A.723.723 0 019 6.75v.5c0 .208.073.385.219.531A.723.723 0 009.75 8h8.5c.208 0 .385.073.531.219A.723.723 0 0119 8.75zM17 14H7v1h1v2h2v-2h1v2h2v-2h1v2h2v-2h1v-1zm-1-3h-2v2h2v-2zm-3 0h-2v2h2v-2zm-3 2v-2H8v2h2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderShared
