import * as React from 'react'
function SvgBrandApple() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.052 12.569c-.01-1.639.732-2.876 2.232-3.787-.84-1.2-2.107-1.861-3.782-1.99-1.585-.126-3.317.923-3.951.923-.67 0-2.206-.88-3.412-.88C6.648 6.876 4 8.824 4 12.784c0 1.17.214 2.379.643 3.626.572 1.639 2.634 5.657 4.787 5.59 1.125-.027 1.92-.8 3.384-.8 1.42 0 2.157.8 3.411.8 2.17-.031 4.037-3.683 4.582-5.327-2.912-1.37-2.755-4.018-2.755-4.103zm-2.528-7.332c1.22-1.446 1.108-2.764 1.072-3.237-1.076.063-2.322.732-3.032 1.558-.781.884-1.241 1.978-1.143 3.21 1.166.09 2.228-.508 3.103-1.53z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandApple
