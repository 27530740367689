import * as React from 'react'
function SvgDropdownDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.344 10.729a.39.39 0 00.146.322l4.187 4.213a.5.5 0 00.709 0l4.186-4.213a.39.39 0 00.147-.322.39.39 0 00-.147-.323l-.732-.732a.39.39 0 00-.322-.147.39.39 0 00-.323.147l-3.164 3.135-3.164-3.135a.39.39 0 00-.322-.147.39.39 0 00-.322.147l-.733.732a.39.39 0 00-.146.323z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDropdownDown
