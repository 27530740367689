import * as React from 'react'
function SvgCodeBlock() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.742 18.658a.234.234 0 01-.132-.102.366.366 0 01-.044-.19l4.131-14.18a.283.283 0 01.117-.147.194.194 0 01.176-.03l1.143.352c.058 0 .102.03.132.088.029.059.044.127.044.205l-4.131 14.18a.282.282 0 01-.118.146.193.193 0 01-.175.03l-1.143-.352zm-1.289-3.193a.315.315 0 01-.176.058.315.315 0 01-.175-.058l-4.014-3.78C4.029 11.627 4 11.568 4 11.51c0-.059.03-.117.088-.176l4.014-3.78a.315.315 0 01.175-.058c.059 0 .118.02.176.059l.791.85c.04.058.059.121.059.19 0 .068-.03.122-.088.16L6.109 11.51l3.106 2.754c.058.039.088.092.088.16 0 .07-.02.133-.059.191l-.79.85zm8.145.058c.058 0 .117-.02.175-.058l4.014-3.78c.059-.058.088-.117.088-.175 0-.059-.03-.117-.088-.176l-4.014-3.78a.315.315 0 00-.175-.058.315.315 0 00-.176.059l-.791.85a.337.337 0 00-.059.19c0 .068.03.122.088.16l3.106 2.755-3.106 2.754a.184.184 0 00-.088.16c0 .07.02.133.059.191l.79.85c.06.039.118.058.177.058z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCodeBlock
