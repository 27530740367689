import * as React from 'react'
function SvgClear() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 4c1.396 0 2.688.349 3.875 1.047a7.83 7.83 0 012.828 2.828A7.513 7.513 0 0119.5 11.75a7.513 7.513 0 01-1.047 3.875 7.83 7.83 0 01-2.828 2.828A7.513 7.513 0 0111.75 19.5a7.513 7.513 0 01-3.875-1.047 7.83 7.83 0 01-2.828-2.828A7.513 7.513 0 014 11.75c0-1.396.349-2.688 1.047-3.875a7.831 7.831 0 012.828-2.828A7.513 7.513 0 0111.75 4zm3.813 9.781L13.5 11.75l2.063-2.031a.435.435 0 00.093-.266.435.435 0 00-.094-.266l-1.25-1.25a.338.338 0 00-.25-.093.457.457 0 00-.28.093L11.75 10 9.719 7.937a.435.435 0 00-.266-.093.435.435 0 00-.266.093l-1.25 1.25a.338.338 0 00-.093.25c0 .105.031.198.093.282L10 11.75l-2.063 2.031a.435.435 0 00-.093.266c0 .094.031.182.093.265l1.25 1.25a.338.338 0 00.25.094.457.457 0 00.282-.094L11.75 13.5l2.031 2.063a.435.435 0 00.266.093.435.435 0 00.265-.094l1.25-1.25a.338.338 0 00.094-.25.457.457 0 00-.094-.28z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgClear
