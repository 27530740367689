import * as React from 'react'
function SvgProcessWithAi() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5l.5 1.403 1.5.52-1.5.519L11 9l-.5-1.558-1.5-.52 1.5-.52L11 5zm8.707 3.5L16.5 5.293 5.293 16.5 8.5 19.707 19.707 8.5zM8.5 18.293L6.707 16.5 14 9.207 15.793 11 8.5 18.293zm8-8L14.707 8.5 16.5 6.707 18.293 8.5 16.5 10.293zM17 14l.75 1.929 2.25.714-2.25.714L17 19.5l-.75-2.143-2.25-.714 2.25-.714L17 14zM6.75 8.929L6 7l-.75 1.929L3 9.643l2.25.714L6 12.5l.75-2.143L9 9.643l-2.25-.714z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgProcessWithAi
