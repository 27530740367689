import * as React from 'react'
function SvgPageSharedLockedInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.333V4h1v6h-7V4h1v-.667C13 2.053 14.25 2 15.5 2s2.5.052 2.5 1.333zM14 4h3v-.667C17 3 16.603 3 15.501 3 14.4 3 14 3 14 3.333V4zM5.75 6H11v5h6v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 19.25V6.75c0-.208.073-.385.219-.531A.723.723 0 015.75 6zM16 6v2h-1V6h1zm-1 9H8v1h1v2h2v-2h1v2h2v-2h1v-1zm-1-1v-2h-2v2h2zm-3-2H9v2h2v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedLockedInverted
