import * as React from 'react'
function SvgParagraph() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5c-.76 0-1.877.252-2.815 1.011C6.726 6.788 6 8.061 6 10c0 1.94.726 3.212 1.685 3.989A4.629 4.629 0 0010.5 15h.5v4.5a.5.5 0 001 0V6h2v13.5a.5.5 0 001 0V6h2.5a.5.5 0 000-1h-7zm.5 1h-.5c-.574 0-1.456.198-2.185.789C7.607 7.362 7 8.339 7 10c0 1.66.607 2.638 1.315 3.211.729.59 1.611.789 2.185.789h.5V6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgParagraph
