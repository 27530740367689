import * as React from 'react'
function SvgTickBig() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.84 9.071a.866.866 0 00-.25-.607L17.374 7.25a.866.866 0 00-1.214 0l-5.857 5.866-2.625-2.634a.866.866 0 00-1.215 0L5.25 11.696a.866.866 0 000 1.215l4.446 4.446a.866.866 0 001.215 0l7.678-7.678c.161-.161.25-.384.25-.608z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTickBig
