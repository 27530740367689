import * as React from 'react'
function SvgPageSharedTemplateInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H3v2l1-1h1v3L4 9h3L6 8V5h1l1 1V4zm-.5 3v.5l.5 1V10H6v8.25c0 .208.073.385.219.531A.723.723 0 006.75 19h10.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V5.75c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H9v2H7.5zm7.5 6h1v1h-1v2h-2v-2h-1v2h-2v-2H9v-1h6zm-2-3h2v2h-2v-2zm-3 0h2v2h-2v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageSharedTemplateInverted
