import * as React from 'react'
function SvgCollapseDown() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.548 9.62a.4.4 0 01.312.65l-3.168 3.96a.4.4 0 01-.624 0L8.9 10.27a.4.4 0 01.312-.65h6.336z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCollapseDown
