import * as React from 'react'
function SvgPanelInboxInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.64 11.273c.157.235.235.498.235.791v3.78c0 .39-.137.722-.41.996-.274.273-.606.41-.996.41H5.406c-.39 0-.722-.137-.996-.41a1.356 1.356 0 01-.41-.996v-3.78c0-.293.078-.556.234-.79L7.34 6.614c.117-.195.283-.346.498-.454.215-.107.44-.161.674-.161h7.851c.235 0 .46.054.674.161.215.108.38.259.498.454l3.106 4.658zM8.747 7.875l-2.49 3.75h3.603l.938 1.875h3.281l.938-1.875h3.603l-2.49-3.75H8.746z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelInboxInverted
