import * as React from 'react'
function SvgCloseTab() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.636 8.364c.201.2.201.526 0 .727L12.727 12l2.91 2.91a.514.514 0 01-.728.726L12 12.727l-2.91 2.91a.514.514 0 01-.726-.728L11.273 12l-2.91-2.91a.514.514 0 11.728-.726L12 11.273l2.91-2.91c.2-.2.525-.2.726 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloseTab
