import * as React from 'react'
import cmpStyles from '../providers/style/cmp-styles'
import View from './View'
import { Style } from '../providers/style/types'

type Props = {
  spacing?: number
  style?: Style
}

const styles = cmpStyles({
  box: ({ theme, spacing }) => ({
    ...theme.components.Separator,
    minHeight: 1,
    maxHeight: 1,
    width: '100%',
    marginVertical: spacing,
  }),
})

const Separator = ({ spacing = 0, style }: Props) => (
  <View style={style ? [styles.box, style] : styles.box} spacing={spacing} />
)

export default Separator
