import { colors } from '@packages/common/src/themes/types'
import focusSelector from './focus-selector'

const baseSize = 5
const baseLineHeight = 16

export type BorderRadius = 'small' | 'normal' | 'large' | 'xLarge' | 'xxLarge'

const theme = {
  baseSize,

  typo: {
    icon: {
      rhythm: baseLineHeight,
      lineHeight: {
        3: `${baseLineHeight * 2.25}px`,
        2: `${baseLineHeight * 1.875}px`,
        1: `${baseLineHeight * 1.75}px`,
        0: `${baseLineHeight * 1.5}px`,
        '-1': `${baseLineHeight * 1.375}px`,
        '-2': `${baseLineHeight * 1.25}px`,
        '-3': `${baseLineHeight}px`,
      },
      fontSize: {
        3: 36,
        2: 30,
        1: 20,
        0: 17,
        '-1': 15,
        '-2': 13,
        '-3': 11,
      },
      weight: {
        hairline: 100,
        thin: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        heavy: 800,
        black: 900,
      },
      letterSpacing: {},
    },
    lato: {
      rhythm: baseLineHeight,
      fontSize: {
        0: 15,
        '-1': 13,
        '-2': 11,
      },
      lineHeight: {
        0: `${baseLineHeight * 1.375}px`,
        '-1': `${baseLineHeight * 1.25}px`,
        '-2': `${baseLineHeight}px`,
      },
      letterSpacing: {},
      weight: {
        hairline: 100,
        thin: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        heavy: 800,
        black: 900,
      },
    },
    inter: {
      rhythm: baseLineHeight,
      fontSize: {
        3: 40,
        1: 30,
        0: 24,
        '-1': 20,
        '-2': 17,
        '-3': 15,
      },
      lineHeight: {
        3: `${baseLineHeight * 3}px`,
        1: `${baseLineHeight * 2.25}px`,
        0: `${baseLineHeight * 2}px`,
        '-1': `${baseLineHeight * 1.75}px`,
        '-2': `${baseLineHeight * 1.5}px`,
        '-3': `${baseLineHeight * 1.375}px`,
      },
      letterSpacing: {
        3: '-0.03em',
        1: '-0.02em',
        0: '-0.02em',
        '-1': '-0.02em',
        '-2': 0,
        '-3': 0,
      },
      weight: {
        hairline: 100,
        thin: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        heavy: 800,
        black: 900,
      },
    },
    'Roboto Mono': {
      rhythm: 22,
      fontSize: {
        0: 14,
      },
      lineHeight: {
        0: `22px`,
      },
      letterSpacing: {},
      weight: {
        regular: 400,
      },
    },
  },
  borderRadius: {
    small: 2,
    normal: 3,
    large: 5,
    xLarge: 10,
    xxLarge: 12,
  },

  components: {
    AccountsList: {
      backgroundColor: colors['Background/Primary'],
      Item: {
        ':hover': {
          backgroundColor: colors['Background/Primary - Base 1 : Hover'],
        },
      },
    },
    Avatar: {
      counter: [
        {
          backgroundColor: colors['Background/Secondary'],
          color: colors['Base Text/Secondary'],
        },
      ],
      company: [
        {
          backgroundColor: colors['User/User 1 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 1 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 2 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 3 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 4 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 5 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 6 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 7 - Avatar'],
          color: '#FFF',
        },
      ],
      remoteUser: [
        {
          backgroundColor: colors['User/User 1 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 2 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 3 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 4 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 5 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 6 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 7 - Avatar'],
          color: '#FFF',
        },
      ],
      user: [
        {
          backgroundColor: colors['User/Current User - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 1 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 2 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 3 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 4 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 5 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 6 - Avatar'],
          color: '#FFF',
        },
        {
          backgroundColor: colors['User/User 7 - Avatar'],
          color: '#FFF',
        },
      ],
    },
    Back: {
      color: colors['Base Text/Link'],
      [focusSelector]: {
        boxShadow: `0 0 0 1px ${colors['Focus/Focus - Base Outline 1']},
          0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
      },
    },
    BadgeCounter: {
      backgroundColor: colors['Chip/Unread - Background'],
      color: colors['Chip/Unread - Content'],
    },
    Button: {
      primary: {
        backgroundColor: colors['Button/Primary - Background'],
        borderColor: colors['Button/Primary - Outline'],
        color: colors['Button/Primary - Content Primary'],
        ':hover': {
          backgroundColor: colors['Button/Primary - Background : Hover'],
        },
        ':active': {
          backgroundColor: colors['Button/Primary - Background : Active'],
        },
        [focusSelector]: {
          borderColor: 'transparent',
          // keep for focusIntent prop
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Base Outline 1']},
          0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
        },
        ':disabled': {
          borderColor: 'transparent',
          color: colors['Button/Disabled - Content'],
          backgroundColor: colors['Button/Disabled - Background'],
        },
      },
      secondary: {
        backgroundColor: colors['Button/Secondary - Background'],
        borderColor: colors['Button/Secondary - Background'],
        color: colors['Button/Secondary - Content Primary'],
        ':hover': {
          backgroundColor: colors['Button/Secondary - Background : Hover'],
        },
        ':active': {
          backgroundColor: colors['Button/Secondary - Background : Active'],
        },
        [focusSelector]: {
          // keep for focusIntent prop
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Base Outline 1']},
          0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
        },
        ':disabled': {
          borderColor: 'transparent',
          color: colors['Button/Disabled - Content'],
          backgroundColor: colors['Button/Disabled - Background'],
        },
      },
      warning: {
        backgroundColor: colors['Button/Warning - Background'],
        borderColor: colors['Button/Warning - Background'],
        color: colors['Button/Warning - Content Primary'],
        ':hover': {
          backgroundColor: colors['Button/Warning - Background : Hover'],
        },
        ':active': {
          backgroundColor: colors['Button/Warning - Background : Active'],
        },
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Warning Outline 1']},
        0 0 0 5px ${colors['Focus/Focus - Warning Outline 2']}`,
        },
        ':disabled': {
          borderColor: 'transparent',
          color: colors['Button/Disabled - Content'],
          backgroundColor: colors['Button/Disabled - Background'],
        },
      },
      error: {
        backgroundColor: colors['Button/Danger - Background'],
        borderColor: colors['Button/Danger - Background'],
        color: colors['Button/Danger - Content Primary'],
        ':hover': {
          backgroundColor: colors['Button/Danger - Background : Hover'],
        },
        ':active': {
          backgroundColor: colors['Button/Danger - Background : Active'],
        },
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Error Outline 1']},
        0 0 0 5px ${colors['Focus/Focus - Error Outline 2']}`,
        },
        ':disabled': {
          borderColor: 'transparent',
          color: colors['Button/Disabled - Content'],
          backgroundColor: colors['Button/Disabled - Background'],
        },
      },
    },
    ButtonWithShortcut: {
      color: colors['Base Text/Secondary'],
    },
    Card: {
      processing: {
        color: colors['Base Text/Orange'],
      },
      expired: {
        color: colors['Base Text/Red'],
      },
    },
    Checkbox: {
      primary: {
        borderColor: colors['Checkbox/Off - Outline'],
        ':checked': {
          color: colors['Checkbox/Check'],
          backgroundColor: colors['Checkbox/On - Background'],
          borderColor: colors['Checkbox/On - Background'],
        },
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Base Outline 1']},
          0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
        },
      },
      ':disabled': {
        opacity: 0.5,
      },
    },
    Checkout: {
      Summary: {
        backgroundColor: colors['Background/Primary - Base 1 : Active'],
      },
    },
    Comments: {
      Box: {
        boxShadow: colors['Drop Shadow/Outlined Shadow 20'],
        background: colors['Background/Primary'],
      },
    },
    ConnectionError: {
      Screen: {
        backgroundColor: colors['Background/Primary'],
      },
      Icon: {
        color: colors['Base Text/Secondary'],
      },
    },
    ContextMenu: {
      backgroundColor: colors['Background/Primary'],
      boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
    },
    DeveloperTools: {
      Bar: {
        background: colors['Background/Primary'],
        borderColor: colors['Separator/Primary'],
        color: colors['Base Text/Secondary'],
      },
    },
    Counter: {
      Text: {
        color: colors['Base Text/Secondary'],
      },
      Icon: {
        color: colors['Base Icon/Secondary'],
      },
    },
    Dialog: {
      Box: {
        backgroundColor: colors['Background/Primary'],
        boxShadow: colors['Drop Shadow/Outlined Shadow 30'],
      },
      Shortcut: {
        color: colors['Base Text/Secondary'],
      },
    },
    DropArea: {
      borderColor: colors['Files Panel/Panel Root Outline'],
    },
    EditorTabs: {
      Bar: {
        background: colors['Background/Secondary'],
      },
      Divider: {
        backgroundColor: colors['Separator/Secondary'],
      },
      Tab: {
        default: {
          background: 'transparent',
          ':hover': {
            background: colors['Background/Secondary - Base 1 : Hover'],
          },
          ':active': {
            background: colors['Background/Primary'],
          },
        },
        blend: {
          maskImage:
            'linear-gradient(90deg, black calc(100% - 24px), transparent)',
          '-webkitMaskImage':
            'linear-gradient(90deg, black calc(100% - 24px), transparent)',
        },
      },
      Close: {
        color: colors['Button/Icon - Content'],
        ':hover': {
          color: colors['Button/Icon - Content : Hover'],
        },
        ':active': {
          color: colors['Button/Icon - Content : Hover'],
        },
      },
    },
    Error: {
      color: colors['Base Text/Red'],
    },
    ErrorScreen: {
      backgroundColor: colors['Background/Primary'],
    },
    Formatting: {
      Panel: {
        borderColor: colors['Separator/Primary'],
      },
      Divider: {
        backgroundColor: colors['Separator/Primary'],
      },
      Button: {
        ':normal': {
          color: colors['Base Icon/Secondary'],
        },
        ':disabled': {
          color: colors['Base Icon/Disabled'],
        },
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
          background: colors['Button/Formatting - Background : Hover'],
        },
      },
      HighlightedTextOrIcon: {
        color: colors['Base Text/Link'],
      },
      ButtonTextOrIcon: {
        color: colors['Base Text/Primary'],
        ':disabled': {
          color: colors['Base Icon/Disabled'],
        },
      },
    },
    File: {
      box: {
        ':drag-preview': {
          backgroundColor: colors['Files Panel/Dragged Item - Background'],
        },
      },
      icon: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
        ':active': {
          color: colors['Base Text/Inverted - Primary'],
        },
      },
      text: {
        color: colors['Base Text/Primary'],
        ':active': {
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    Files: {
      Page: {
        background: colors['Background/Primary'],
        ':hover': {
          background: colors['Background/Primary - Base 1 : Hover'],
        },
        ':active': {
          background: colors['Background/Primary - Base 1 : Active'],
        },
        ':selected': {
          background: colors['Background/Primary - Inverted'],
        },
      },
      Folder: {
        Expand: {
          color: colors['Base Icon/Secondary'],
          ':hover': {
            color: colors['Base Icon/Secondary : Hover'],
          },
        },
      },
      Section: {
        LabelAndIcon: {
          color: colors['Base Text/Secondary'],
          ':hover': {
            background: colors['Background/Primary - Base 1 : Active'],
            color: colors['Base Text/Primary'],
          },
        },
        Plus: {
          color: colors['Base Icon/Secondary'],
          ':hover': {
            color: colors['Base Icon/Secondary : Hover'],
          },
        },
      },
    },
    FileTree: {
      Node: {
        ':window-inactive': {
          background: `linear-gradient(to bottom, ${colors['Background/Primary - Base 1 : Active']} calc(100% - 1px), transparent calc(100% - 1px))`,
        },
        ':focus': {
          background: `linear-gradient(to bottom, ${colors['Background/Primary - Base 1 : Active']} calc(100% - 1px), transparent calc(100% - 1px))`,
          color: colors['Base Text/Inverted - Primary'],
        },
        ':active': {
          background: `linear-gradient(to bottom, ${colors['Background/Primary - Inverted']} calc(100% - 1px), transparent calc(100% - 1px))`,
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    Freedom: {
      Mentions: {
        Box: {
          background: colors['Background/Primary'],
          boxShadow: colors['Drop Shadow/Outlined Shadow 30'],
        },
        Item: {
          default: {
            background: colors['Background/Primary'],
            color: colors['Base Text/Primary'],
          },
          selected: {
            background: colors['Background/Primary - Inverted'],
            color: colors['Base Text/Inverted - Primary'],
          },
        },
        NotFound: {
          color: colors['Base Text/Text Placeholder'],
        },
      },
    },
    IntegratedAlert: {
      warning: {
        backgroundColor: colors['Alert - Vivid/Warning - Background'],
        color: colors['Alert - Vivid/Content'],
      },
      error: {
        backgroundColor: colors['Alert - Vivid/Error - Background'],
        color: colors['Alert - Vivid/Content'],
      },
    },
    Inviting: {
      Form: {
        InviteRow: {
          Icon: {
            color: colors['Base Icon/Secondary'],
            ':hover': {
              color: colors['Base Icon/Secondary : Hover'],
            },
            ':active': {
              color: colors['Base Icon/Secondary : Hover'],
            },
          },
        },
        AddRow: {
          color: colors['Base Text/Link'],
          ':hover': {
            color: colors['Base Text/Link'],
          },
          ':active': {
            color: colors['Base Text/Link'],
          },
        },
      },
      Overview: {
        State: {
          success: {
            color: colors['Base Text/Green'],
          },
          error: {
            color: colors['Base Text/Red'],
          },
        },
      },
    },
    LinkPreview: {
      link: {
        color: colors['Base Text/Secondary'],
      },
      edit: {
        ':hover': {
          background: colors['Button/Formatting - Background : Hover'],
        },
      },
      icon: {
        color: colors['Base Text/Primary'],
      },
      box: {
        boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        background: colors['Background/Primary'],
      },
    },
    LinkEditor: {
      icon: {
        color: colors['Base Text/Primary'],
      },
      Input: {
        color: colors['Base Text/Primary'],
        backgroundColor: colors['Input Box/Background'],
        boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        [focusSelector]: {
          boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        },
        '::placeholder': {
          color: colors['Base Text/Text Placeholder'],
        },
      },
    },
    Link: {
      default: {
        color: colors['Base Text/Secondary'],
        ':hover': {
          color: colors['Base Text/Secondary : Hover'],
        },
      },
      web: {
        color: colors['Base Text/Link'],
      },
      inherit: {
        color: 'inherit',
      },
    },
    List: {
      Item: {
        color: colors['Base Text/Primary'],
      },
    },
    MenuItem: {
      Label: {
        color: colors['Base Text/Primary'],
        ':hover': {
          color: colors['Base Text/Inverted - Primary'],
          backgroundColor: colors['Background/Primary - Inverted'],
        },
      },
      Shortcut: {
        color: colors['Base Text/Secondary'],
        ':hover': {
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    MenuItemDivider: {
      backgroundColor: colors['Separator/Primary'],
    },
    Modal: {
      boxShadow: colors['Drop Shadow/Outlined Shadow 30'],
    },
    AlertGlobal: {
      generic: {
        boxShadow: colors['Drop Shadow/Shadow 1'],
        backgroundColor: colors['Alert - Vivid/Generic - Background'],
        color: colors['Alert - Vivid/Generic - Content'],
      },
      success: {
        boxShadow: colors['Drop Shadow/Shadow 1'],
        backgroundColor: colors['Alert - Vivid/Success - Background'],
        color: colors['Alert - Vivid/Content'],
      },
      error: {
        boxShadow: colors['Drop Shadow/Shadow 1'],
        backgroundColor: colors['Alert - Vivid/Error - Background'],
        color: colors['Alert - Vivid/Content'],
      },
      info: {
        boxShadow: colors['Drop Shadow/Shadow 1'],
        backgroundColor: colors['Alert - Vivid/Info - Background'],
        color: colors['Alert - Vivid/Content'],
      },
      warning: {
        boxShadow: colors['Drop Shadow/Shadow 1'],
        backgroundColor: colors['Alert - Vivid/Warning - Background'],
        color: colors['Alert - Vivid/Content'],
      },
    },
    AlertInline: {
      info: {
        backgroundColor: colors['Alert - Soft/Info - Background'],
        borderColor: colors['Alert - Soft/Info - Outline'],
        color: colors['Alert - Soft/Info - Content'],
      },
      warning: {
        backgroundColor: colors['Alert - Soft/Warning - Background'],
        borderColor: colors['Alert - Soft/Warning - Outline'],
        color: colors['Alert - Soft/Warning - Content'],
      },
      error: {
        backgroundColor: colors['Alert - Soft/Error - Background'],
        borderColor: colors['Alert - Soft/Error - Outline'],
        color: colors['Alert - Soft/Error - Content'],
      },
      success: {
        backgroundColor: colors['Alert - Soft/Success - Background'],
        borderColor: colors['Alert - Soft/Success - Outline'],
        color: colors['Alert - Soft/Success - Content'],
      },
    },
    Page: {
      background: colors['Background/Primary'],
    },
    PageNotFoundError: {
      Icon: {
        color: colors['Base Text/Primary'],
      },
    },
    Pagination: {
      backgroundColor: colors['Button/Primary - Background'],
      borderColor: colors['Button/Primary - Outline'],
      color: colors['Button/Primary - Content Primary'],
      ':hover': {
        backgroundColor: colors['Button/Primary - Background : Hover'],
      },
      ':active': {
        backgroundColor: colors['Button/Primary - Background : Active'],
      },
    },
    Panel: {
      borderColor: colors['Separator/Primary'],
    },
    PasswordChecker: {
      0: {
        color: colors['Password Strength Indicator/Level 0'],
      },
      1: {
        color: colors['Password Strength Indicator/Level 1'],
      },
      2: {
        color: colors['Password Strength Indicator/Level 2-3'],
      },
      3: {
        color: colors['Password Strength Indicator/Level 2-3'],
      },
      4: {
        color: colors['Password Strength Indicator/Level 4'],
      },
    },
    PinCode: {
      success: {
        color: colors['Base Text/Primary'],
      },
      error: {
        color: colors['Base Text/Primary'],
      },
    },
    Preferences: {
      EmptyState: {
        color: colors['Base Text/Secondary'],
      },
      List: {
        Row: {
          default: {
            background: colors['Background/Primary'],
            borderColor: 'transparent',
            ':hover': {
              background: colors['Background/Primary - Base 1 : Hover'],
            },
            ':active': {
              background: colors['Background/Primary - Base 1 : Active'],
            },
            [focusSelector]: {
              borderColor: colors['Focus/Focus - Base Outline 1'],
              boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
            },
          },
          warning: {
            background: colors['Alert - Soft/Warning - Background'],
            borderColor: colors['Alert - Soft/Warning - Outline'],
            [focusSelector]: {
              borderColor: colors['Focus/Focus - Base Outline 1'],
              boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
            },
          },
        },
        Items: {
          Text: {
            default: {
              color: colors['Base Text/Primary'],
            },
            secondary: {
              color: colors['Base Text/Secondary'],
            },
            error: {
              color: colors['Base Text/Red'],
            },
            warning: {
              color: colors['Alert - Soft/Warning - Content'],
            },
          },
          Icon: {
            default: {
              color: colors['Base Icon/Secondary'],
              ':hover': {
                color: colors['Base Icon/Secondary : Hover'],
                backgroundColor: colors['Button/Icon - Background : Hover'],
              },
              ':active': {
                color: colors['Base Icon/Secondary : Hover'],
                backgroundColor: colors['Button/Icon - Background : Active'],
              },
            },
            warning: {
              color: colors['Alert - Soft/Warning - Content'],
              ':hover': {
                backgroundColor:
                  colors['Alert - Soft/Warning - Icon Background : Hover'],
              },
              ':active': {
                backgroundColor:
                  colors['Alert - Soft/Warning - Icon Background : Hover'],
              },
            },
          },
        },
      },
      TransferOwnership: {
        Success: {
          backgroundColor: colors['Background/Primary'],
        },
      },
      VatField: {
        success: {
          color: colors['Base Text/Green'],
        },
        error: {
          color: colors['Base Text/Red'],
        },
      },
    },
    Presence: {
      color: colors['Base Text/Secondary'],
      borderColor: colors['Background/Primary'],
    },
    Radio: {
      borderColor: colors['Radio Button/Off - Outline'],
      backgroundColor: colors['Radio Button/Circle'],
      ':disabled': {
        opacity: 0.5,
      },
      [focusSelector]: {
        borderColor: colors['Focus/Focus - Base Outline 1'],
        boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
      },
    },
    RouteLink: {
      ':active': {
        backgroundColor: colors['Background/Primary - Inverted'],
        color: colors['Base Text/Inverted - Primary'],
      },
      [focusSelector]: {
        boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}, 0 0 0 1px ${colors['Focus/Focus - Base Outline 1']}`,
      },
      ':window-inactive': {
        backgroundColor: colors['Background/Primary - Base 1 : Active'],
        color: colors['Base Text/Primary'],
      },
    },
    Spinner: {
      Screen: {
        color: colors['Base Icon/Secondary'],
      },
    },
    Search: {
      Box: {
        backgroundColor: colors['Background/Primary'],
        boxShadow: colors['Drop Shadow/Outlined Shadow 30'],
      },
      Input: {
        color: colors['Base Text/Primary'],
        '::placeholder': {
          color: colors['Base Text/Text Placeholder'],
        },
      },
      Trigger: {
        color: colors['Button/Icon - Content'],
        ':hover': {
          color: colors['Button/Icon - Content : Hover'],
        },
        ':active': {
          color: colors['Button/Icon - Content : Hover'],
        },
      },
      Result: {
        default: {
          backgroundColor: colors['Background/Primary'],
          color: colors['Base Text/Primary'],
        },
        ':focus': {
          backgroundColor: colors['Background/Primary - Inverted'],
          color: colors['Base Text/Inverted - Primary'],
        },
      },
      Highlight: {
        backgroundColor: colors['Search Match/Content Match Background'],
        color: colors['Search Match/Content Match Text'],
      },
    },
    Select: {
      Control: {
        normal: {
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline']}`,
          backgroundColor: colors['Input Box/Background'],
          [focusSelector]: {
            boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
          },
        },
        error: {
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Invalid']}, 0 0 0 1px ${colors['Input Box/Outline : Invalid']} inset`,
          backgroundColor: colors['Input Box/Background : Invalid'],
          [focusSelector]: {
            backgroundColor: colors['Input Box/Background'],
            boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
          },
        },
      },
      Indicator: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
      },
      Menu: {
        boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        backgroundColor: colors['Background/Primary'],
      },
      EmptyMenu: {
        color: colors['Base Text/Text Placeholder'],
      },
      Option: {
        color: colors['Base Text/Primary'],
        ':focus': {
          backgroundColor: colors['Background/Primary - Inverted'],
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    SelectSimple: {
      Control: {
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
        },
      },
      Indicator: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
      },
      Option: {
        ':focus': {
          backgroundColor: colors['Background/Primary - Inverted'],
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    SpecStatusSelect: {
      Control: {
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
        },
      },
      Indicator: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
      },
      Option: {
        ':focus': {
          backgroundColor: colors['Background/Primary - Inverted'],
          color: colors['Base Text/Inverted - Primary'],
        },
      },
    },
    Separator: {
      backgroundColor: colors['Separator/Primary'],
    },
    Sharing: {
      Readonly: {
        color: colors['Base Text/Secondary'],
      },
    },
    Switch: {
      Background: {
        default: {
          backgroundColor: colors['Switch/Background - Off'],
          ':checked': {
            backgroundColor: colors['Switch/Background - On'],
          },
          ':disabled': {
            opacity: 0.4,
          },
          [focusSelector]: {
            boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}, 0 0 0 1px ${colors['Focus/Focus - Base Outline 1']}`,
          },
        },
        monochrome: {
          backgroundColor: colors['Switch/Background - Monochrome'],
          ':checked': {
            backgroundColor: colors['Switch/Background - Monochrome'],
          },
          ':disabled': {
            opacity: 0.4,
          },
          [focusSelector]: {
            boxShadow: `0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}, 0 0 0 1px ${colors['Focus/Focus - Base Outline 1']}`,
          },
        },
      },
      Circle: {
        backgroundColor: colors['Switch/Circle'],
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      },
    },
    Tabs: {
      Bar: {
        backgroundColor: colors['Background/Secondary'],
      },
      Tab: {
        backgroundColor: 'transparent',
        color: colors['Base Text/Primary'],
        ':active': {
          backgroundColor: colors['Background/Primary'],
          color: colors['Base Text/Primary'],
        },
      },
    },
    Text: {
      primary: {
        color: colors['Base Text/Primary'],
      },
      secondary: {
        color: colors['Base Text/Secondary'],
      },
      secondaryIcon: {
        color: colors['Base Icon/Secondary'],
      },
      placeholder: {
        color: colors['Base Text/Text Placeholder'],
      },
      error: {
        color: colors['Base Text/Red'],
      },
      warning: {
        color: colors['Base Text/Orange'],
      },
      inherit: {
        color: 'inherit',
      },
    },
    TextInput: {
      adornment: {
        color: colors['Base Icon/Secondary'],
      },
      input: {
        normal: {
          color: colors['Base Text/Primary'],
          backgroundColor: colors['Input Box/Background'],
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline']}`,
          ':disabled, [readonly]': {
            opacity: 0.5,
          },
          [focusSelector]: {
            boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
          },
          '::placeholder': {
            color: colors['Base Text/Text Placeholder'],
          },
          ':disabled::placeholder': {
            color: 'transparent',
          },
          ':-webkit-autofill': {
            colorScheme: 'light', // todo
          },
          ':-webkit-autofill::first-line': {
            fontFamily: 'lato',
            fontSize: 15,
          },
        },
        error: {
          color: colors['Base Text/Primary'],
          backgroundColor: colors['Input Box/Background : Invalid'],
          boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Invalid']}, 0 0 0 1px ${colors['Input Box/Outline : Invalid']} inset`,
          [focusSelector]: {
            backgroundColor: colors['Input Box/Background'],
            boxShadow: `0 0 0 1px ${colors['Input Box/Outline : Focus']}, 0 0 0 1px ${colors['Input Box/Outline : Focus']} inset`,
          },
          '::placeholder': {
            color: colors['Base Text/Text Placeholder'],
          },
          ':disabled::placeholder': {
            color: 'transparent',
          },
          ':-webkit-autofill': {
            colorScheme: 'light',
          },
          ':-webkit-autofill::first-line': {
            fontFamily: 'lato',
            fontSize: 15,
          },
        },
      },
    },
    Tooltip: {
      boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
      backgroundColor: colors['Tooltip/Background'],
      color: colors['Tooltip/Content Primary'],
    },
    ToolBar: {
      Box: {
        borderColor: colors['Separator/Primary'],
      },
      NewPage: {
        color: colors['Button/Icon - Content'],
        ':hover': {
          color: colors['Button/Icon - Content : Hover'],
        },
        ':active': {
          color: colors['Button/Icon - Content : Hover'],
        },
      },
      ShowRightPanel: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
      },
    },
    TopBarApp: {
      box: {
        backgroundColor: colors['Background/Secondary'],
      },
      gradientBottom: {
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.1) 100%)',
      },
    },
    TopBarLink: {
      color: colors['Base Icon/Secondary'],
      ':hover': {
        color: colors['Base Icon/Secondary : Hover'],
      },
    },
    TopBarModal: {
      box: {
        backgroundColor: colors['Background/Primary'],
        borderBottomColor: colors['Separator/Primary'],
        color: colors['Base Text/Primary'],
      },
      icon: {
        color: colors['Base Icon/Secondary'],
        ':hover': {
          color: colors['Base Icon/Secondary : Hover'],
        },
      },
    },
    WorkspaceButton: {
      ':hover': {
        color: colors['Base Text/Secondary : Hover'],
      },
    },
    WindowsFrame: {
      backgroundColor: colors['Background/Primary'],
      color: colors['Base Text/Inverted - Primary'],
    },
    WorkspaceWizard: {
      background: colors['Background/Primary'],
      Header: {
        backgroundColor: colors['Background/Primary'],
      },
    },
    BillingOverview: {
      link: {
        color: colors['Base Text/Red'],
      },
    },
    WindowControls: {
      none: {
        generic: {
          color: colors['Windows Shell/Button Foreground'],
          ':hover': {
            backgroundColor: colors['Windows Shell/Button Background : Hover'],
            color: colors['Windows Shell/Button Foreground : Hover'],
          },
          ':active': {
            backgroundColor: colors['Windows Shell/Button Background : Active'],
            color: colors['Windows Shell/Button Foreground : Hover'],
          },
        },
        close: {
          ':hover': {
            backgroundColor:
              colors['Windows Shell/Button Close Background : Hover'],
            color: colors['Windows Shell/Button Close Foreground : Hover'],
          },
          ':active': {
            backgroundColor:
              colors['Windows Shell/Button Close Background : Active'],
            color: colors['Windows Shell/Button Close Foreground : Hover'],
          },
        },
      },
      scrim: {
        generic: {
          color: colors['Windows Shell/Button Foreground On Scrim'],
          ':hover': {
            backgroundColor: colors['Windows Shell/Button Background : Hover'],
            color: colors['Windows Shell/Button Foreground : Hover'],
          },
          ':active': {
            backgroundColor: colors['Windows Shell/Button Background : Active'],
            color: colors['Windows Shell/Button Foreground : Hover'],
          },
        },
        close: {
          ':hover': {
            backgroundColor:
              colors['Windows Shell/Button Close Background : Hover'],
            color: colors['Windows Shell/Button Close Foreground : Hover'],
          },
          ':active': {
            backgroundColor:
              colors['Windows Shell/Button Close Background : Active'],
            color: colors['Windows Shell/Button Close Foreground : Hover'],
          },
        },
      },
    },
    Logo: {
      borderColor: colors['Smarkup Logo/Rectangle Outline'],
    },
    HistoryNavigation: {
      Icon: {
        active: {
          color: colors['Base Icon/Secondary'],
          ':hover': {
            color: colors['Base Icon/Secondary : Hover'],
          },
        },
        inactive: {
          color: colors['Base Icon/Disabled'],
        },
      },
    },
    AvatarCropper: {
      borderColor: colors['Separator/Primary'],
      color: colors['Scrim'],
    },
    Slider: {
      Range: {
        backgroundColor: colors['Base Text/Primary'],
      },
      Thumb: {
        borderColor: colors['Base Text/Primary'],
        backgroundColor: colors['Background/Primary'],
        [focusSelector]: {
          boxShadow: `0 0 0 1px ${colors['Focus/Focus - Base Outline 1']},
          0 0 0 5px ${colors['Focus/Focus - Base Outline 2']}`,
        },
      },
      Track: {
        backgroundColor: colors['Base Text/Primary'],
      },
    },
    Help: {
      borderColor: colors['Button/Primary - Outline'],
      backgroundColor: colors['Button/Primary - Background'],
      '&:hover': {
        backgroundColor: colors['Button/Primary - Background : Hover'],
      },
      '&:active': {
        backgroundColor: colors['Button/Primary - Background : Active'],
      },
    },
    Find: {
      Separator: {
        backgroundColor: colors['Separator/Primary'],
      },
      Button: {
        active: {
          color: colors['Base Icon/Secondary'],
        },
        inactive: {
          color: colors['Base Icon/Disabled'],
        },
      },
      Input: {
        color: colors['Base Text/Primary'],
        backgroundColor: colors['Input Box/Background'],
        boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        [focusSelector]: {
          boxShadow: colors['Drop Shadow/Outlined Shadow 10'],
        },
        '::placeholder': {
          color: colors['Base Text/Text Placeholder'],
        },
      },
    },
  },
}

export default theme
