import * as React from 'react'
function SvgFolderSharedLockedInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6v-.667C20 4.053 18.75 4 17.5 4S15 4.052 15 5.333V6h-1v7h7V6h-1zm-1 8v4.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0117.25 20H4.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 013 18.25V11h10v3h6zm-6-6H9v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 007.25 6h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 003 7.75V10h10V8zm3-2.667V6h3v-.667C19 5 18.603 5 17.501 5 16.4 5 16 5 16 5.333zM15 15h1v1h-1v2h-2v-2h-1v2h-2v-2H9v2H7v-2H6v-1h9zm-3-3h-2v2h2v-2zm-3 0H7v2h2v-2zm9-3h-1v2h1V9z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderSharedLockedInverted
