import * as React from 'react'
function SvgMoveHorizontally() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12.5a.48.48 0 01-.148.352l-2 2A.48.48 0 0116.5 15a.48.48 0 01-.352-.148A.48.48 0 0116 14.5v-1H8v1a.48.48 0 01-.148.352A.48.48 0 017.5 15a.48.48 0 01-.352-.148l-2-2A.48.48 0 015 12.5a.48.48 0 01.148-.352l2-2A.48.48 0 017.5 10a.48.48 0 01.352.148A.48.48 0 018 10.5v1h8v-1a.48.48 0 01.148-.352A.48.48 0 0116.5 10a.48.48 0 01.352.148l2 2A.48.48 0 0119 12.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMoveHorizontally
