import * as React from 'react'
function SvgFilesStudies() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.781 18.281A.723.723 0 0019 17.75V5.25c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H7.75a.723.723 0 00-.531.219A.723.723 0 007 5.25V6.5H5.75a.723.723 0 00-.531.219A.723.723 0 005 7.25v12.5c0 .208.073.385.219.531a.723.723 0 00.531.219h10.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V18.5h1.25a.723.723 0 00.531-.219zM7 17.75V7.5H6v12h10v-1H7.75a.723.723 0 01-.531-.219A.723.723 0 017 17.75zm11-.25H8v-12h10v12zm-2-8h-6v1h6v-1zm-6 2h6v1h-6v-1zm5 2h-5v1h5v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesStudies
