import * as React from 'react'
function SvgChevronSmallDownBold2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 14.646a.5.5 0 00.707 0l.708-.707 3.535-3.535a.5.5 0 000-.707l-.707-.707a.5.5 0 00-.707 0l-3.182 3.182L9.182 8.99a.5.5 0 00-.707 0l-.707.707a.5.5 0 000 .707l3.535 3.535.707.707z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDownBold2
