import * as React from 'react'
import Head from 'next/head'

type Props = {
  title: string
}

const Analytics = () => (
  <>
    <script
      type="text/plain"
      data-cookiecategory="analytic"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
    />
    <script
      type="text/plain"
      data-cookiecategory="analytic"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
          `,
      }}
    />
    <script
      type="text/plain"
      data-cookiecategory="analytic"
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.NEXT_PUBLIC_FB_TRACKING_ID}');
        fbq('track', 'PageView');
        `,
      }}
    />
    <script
      src={`${process.env.NEXT_PUBLIC_COOKIES_CONSENT_URL}/cookie.js`}
      data-dialog="false"
      defer
    />
  </>
)

const Meta = ({ title }: Props) => {
  return (
    <Head>
      <title>{`${title} | Specific`}</title>
      <meta content="width=device-width,initial-scale=1" name="viewport" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      {process.env.NEXT_PUBLIC_ENABLE_ANALYTIC === '1' ? <Analytics /> : null}
    </Head>
  )
}

export default Meta
