import * as React from 'react'
function SvgFolderMissingParent() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.75v8.5c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H15v-1h3.25a.723.723 0 00.531-.219.723.723 0 00.219-.531V10H5v7.25c0 .208.073.385.219.531A.723.723 0 005.75 18H10v1H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234zm-1 0V9H5V6.75c0-.208.073-.385.219-.531A.723.723 0 015.75 6h2.5c.208 0 .385.073.531.219A.723.723 0 019 6.75v.5c0 .208.073.385.219.531A.723.723 0 009.75 8h8.5c.208 0 .385.073.531.219A.723.723 0 0119 8.75zm-6.076 8.288h-1.21v-.109c.002-1.119.303-1.46.84-1.8.393-.249.696-.527.696-.945 0-.445-.348-.732-.78-.732-.418 0-.805.278-.824.799h-1.304c.029-1.279.975-1.886 2.135-1.886 1.269 0 2.17.652 2.17 1.77 0 .752-.39 1.222-.988 1.576-.505.304-.728.595-.735 1.218v.109zm.186 1.278a.778.778 0 01-.767.767.764.764 0 110-1.528c.409 0 .764.342.767.761z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderMissingParent
