import * as React from 'react'
function SvgFilesInputRequestV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.5a8 8 0 11-16 0 8 8 0 0116 0zm1 0a9 9 0 11-18 0 9 9 0 0118 0zm-4.146-2.146a.5.5 0 00-.708-.708L10.5 15.293l-2.646-2.647a.5.5 0 00-.708.708l3.354 3.353 6.354-6.353z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesInputRequestV2
