import * as React from 'react'
function SvgHashtag() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.56 13.5h-2.268l.572-2.286h2.268L12.56 13.5zM19.417 9a.31.31 0 00-.053-.25.298.298 0 00-.223-.107h-2.777l.696-2.786a.31.31 0 00-.053-.25.313.313 0 00-.224-.107h-2c-.133 0-.25.09-.285.214l-.723 2.929h-2.268l.696-2.786a.31.31 0 00-.053-.25.314.314 0 00-.224-.107h-2c-.134 0-.25.09-.285.214l-.724 2.929h-2.92a.28.28 0 00-.276.214l-.5 2a.28.28 0 00.054.25.298.298 0 00.223.107h2.777L7.703 13.5h-2.92a.287.287 0 00-.276.214l-.5 2a.31.31 0 00.053.25.298.298 0 00.223.107H7.06l-.696 2.786a.31.31 0 00.053.25.313.313 0 00.224.107H8.65c.125 0 .24-.089.276-.214l.724-2.929h2.267l-.696 2.786a.31.31 0 00.054.25.317.317 0 00.232.107h2c.125 0 .24-.089.277-.214l.723-2.929h2.92c.133 0 .25-.089.276-.214l.5-2a.28.28 0 00-.053-.25.298.298 0 00-.224-.107H15.15l.571-2.286h2.92c.134 0 .24-.089.276-.214l.5-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHashtag
