import * as React from 'react'
function SvgFilesProblemsV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32 5.5h8.36l4.157 7-4.156 7H8.319l-4.156-7 4.156-7zm8.93-1l4.75 8-4.75 8h-9.5L3 12.5l4.75-8h9.5zm-1.63 5.494l-.708-.707-2.584 2.584L9.76 9.303l-.707.707 2.568 2.568-2.556 2.557.707.707 2.556-2.556 2.57 2.568.706-.707-2.568-2.569 2.584-2.584z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesProblemsV2
