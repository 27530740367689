import * as React from 'react'
function SvgItemProblemInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.375 3.5c2.5 1.667 3.75 4.167 3.75 7.5C15.375 11 16 10.167 16 8.5c.833.833 1.25 2.292 1.25 4.375 0 3.091-1.835 5.571-5.503 5.624a6.35 6.35 0 00.503-.061c1.25-.417 1.875-1.646 1.875-3.688-1.974 1.184-3.012.125-3.116-3.179L11 11.195 11 11c-2.035 1.69-2.44 3.987-1.214 6.892.211.28.521.465.93.556C7.574 18.073 6 15.735 6 12.875c0-1.782.726-2.839 1.587-3.803l.237-.26.485-.52.242-.265c.96-1.07 1.824-2.305 1.824-4.527z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemProblemInverted
