import * as React from 'react'
function SvgAiGenerated() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5a2 2 0 00-2 2v11a2 2 0 002 2h14a2 2 0 002-2V7a2 2 0 00-2-2H5zm.616 12h1.977l.647-1.99h3.151l.646 1.99h1.978l-3.009-8.727H8.628L5.616 17zm5.308-3.43L9.85 10.267h-.068L8.71 13.57h2.215zM16.91 17V8.273h-1.845V17h1.845z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAiGenerated
