import * as React from 'react'
function SvgSurveyQuestionEmail() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.556 6.387c-1.267.986-2.095 2.392-2.573 3.826-.558 1.675-.683 3.556-.104 5.173.57 1.593 1.841 2.983 4.197 3.69 3.41 1.023 6.302.09 7.643-.663a.547.547 0 01.713.142.464.464 0 01-.13.676c-1.52.884-4.739 1.935-8.514.803-2.644-.793-4.168-2.404-4.85-4.31-.675-1.883-.512-4.002.097-5.827.522-1.566 1.444-3.16 2.907-4.3 1.474-1.146 3.46-1.802 6.04-1.54 2.904.295 4.747 1.717 5.684 3.536.924 1.794.943 3.927.312 5.635-.49 1.324-1.183 2.182-2.028 2.619-.849.438-1.766.408-2.608.127-.876-.292-1.35-1.094-1.537-1.926a7.076 7.076 0 01-.795 1.065c-.736.796-1.645 1.254-2.66.916-1.087-.362-1.718-1.132-1.897-2.114-.172-.944.081-2.03.609-3.084.572-1.145 1.694-1.969 2.903-2.375 1.208-.406 2.585-.423 3.698.155l.378.197-.135.405c-.172.516-.61 1.645-.942 2.473a3.788 3.788 0 00-.192 2.106c.142.668.465 1.093.886 1.234.658.219 1.284.216 1.833-.068.554-.286 1.115-.9 1.549-2.076.543-1.471.52-3.31-.263-4.831-.772-1.497-2.305-2.735-4.896-2.999-2.343-.238-4.068.357-5.325 1.335zm5.48 4.927c.255-.636.564-1.429.768-1.983-.75-.25-1.657-.218-2.52.073-1.022.343-1.9 1.02-2.328 1.874-.473.946-.638 1.804-.52 2.458.113.615.482 1.095 1.23 1.345.486.162 1.007.004 1.61-.647.606-.656 1.202-1.728 1.76-3.12z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSurveyQuestionEmail
