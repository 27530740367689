import * as React from 'react'
function SvgCheckCircle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.875 2.52A10.905 10.905 0 0012.25 1c-2.026 0-3.901.507-5.625 1.52A11.368 11.368 0 002.52 6.625 10.905 10.905 0 001 12.25c0 2.026.507 3.901 1.52 5.625a11.368 11.368 0 004.105 4.105 10.905 10.905 0 005.625 1.52c2.026 0 3.901-.507 5.625-1.52a11.367 11.367 0 004.105-4.105 10.905 10.905 0 001.52-5.625c0-2.026-.507-3.901-1.52-5.625a11.368 11.368 0 00-4.105-4.105zM7.714 4.402a8.804 8.804 0 014.536-1.225c1.633 0 3.145.409 4.536 1.225a9.15 9.15 0 013.312 3.312 8.804 8.804 0 011.225 4.536 8.804 8.804 0 01-1.225 4.536 9.15 9.15 0 01-3.312 3.312 8.804 8.804 0 01-4.536 1.225 8.804 8.804 0 01-4.536-1.225 9.15 9.15 0 01-3.312-3.312 8.804 8.804 0 01-1.225-4.536c0-1.633.409-3.145 1.225-4.536a9.149 9.149 0 013.312-3.312zm11.068 5.08a.564.564 0 00-.181-.407l-.998-.998a.564.564 0 00-.409-.182.49.49 0 00-.362.136l-6.442 6.396-2.676-2.767a.663.663 0 00-.409-.136.49.49 0 00-.362.136l-1.044 1.044a.431.431 0 00-.181.362c0 .152.06.288.181.409l4.128 4.128a.43.43 0 00.363.181c.151 0 .287-.06.408-.181l7.803-7.757a.431.431 0 00.181-.363z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCheckCircle
