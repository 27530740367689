import * as React from 'react'
import Image from '../Image'
import {
  AvatarLoadingStatus,
  useAvatarStatus,
  useAvatarStatusSetter,
} from './image-ctx'
import { AvatarImageProps } from './types'
import cmpStyles from '../../providers/style/cmp-styles'

const styles = cmpStyles({
  image: ({ variant, size, theme: { borderRadius } }) => ({
    width: size,
    height: size,
    borderRadius: variant === 'circled' ? '50%' : borderRadius.large,
  }),
})

const AvatarImage = ({
  variant = 'circled',
  src,
  size = 40,
  testID,
}: AvatarImageProps) => {
  useImageLoader(src)
  const status = useAvatarStatus()

  if (!src) {
    return null
  }

  if (['loading', 'loaded'].includes(status)) {
    return (
      <Image
        testID={testID}
        weight="black"
        style={styles.image}
        src={src}
        size={size}
        variant={variant}
      />
    )
  }

  return null
}

const useImageLoader = (src?: string) => {
  const setAvatarStatus = useAvatarStatusSetter()

  React.useEffect(() => {
    if (!src) {
      setAvatarStatus('error')
      return
    }

    let isMounted = true
    const image = new window.Image()

    const updateStatus = (status: AvatarLoadingStatus) => () => {
      if (!isMounted) return
      setAvatarStatus(status)
    }

    image.onload = updateStatus('loaded')
    image.onerror = updateStatus('error')
    image.src = src

    setAvatarStatus(image.complete ? 'loaded' : 'loading')

    return () => {
      isMounted = false
    }
  }, [src])
}

export default AvatarImage
