import * as React from 'react'
function SvgLoaderCircle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 12c.552 0 1.007-.45.929-.997a7.001 7.001 0 00-13.794-.369 7 7 0 005.868 8.295c.547.078.997-.377.997-.929v-.38c0-.552-.453-.99-.992-1.108a4.621 4.621 0 115.504-5.504c.119.54.556.992 1.108.992H18z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLoaderCircle
