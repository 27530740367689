import * as React from 'react'
import { useSignInMethodSwitch } from '@apps/accounts/src/features/Workspace/SignInPage/sign-in-method-ctx'
import View from '@packages/common/src/components/View'
import AccountsList from '@packages/common/src/components/AccountsList'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import canFastAutoSignInToDesktop from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector/can-fast-auto-sign-in-to-desktop'
import useAccountSelectorWorkspaces from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector/use-account-selector-workspaces'
import { requestOpenApi } from '@packages/common/src/providers/network/client'
import { useAlertActions } from '@apps/accounts/src/components/AlertProvider'
import errorToAlert from '@apps/accounts/src/features/Workspace/SignInPage/error-to-alert'
import { ApiError } from '@packages/common/src/providers/network/errors'
import { withToken as loginTwiceQuery } from '@packages/common/src/providers/network/queries/auth-tokens/login'
import { useBackUrl } from '@apps/accounts/src/features/Workspace/SignInPage/query-hooks'
import { useRouter } from 'next/router'
import { pathConstructor } from '@apps/accounts/src/pages/launch.helpers'
import { useTranslation } from 'react-i18next'
import Heading from '@packages/common/src/components/Heading'

const styles = cmpStyles({
  box: {
    flexDirection: 'column',
    alignSelf: 'center',
    maxWidth: 300,
  },
})

const AccountSelector = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const backUrl = useBackUrl()
  const [selectedWorkspaceId, setSelectedWorkspaceId] = React.useState('')
  const { setAlert } = useAlertActions()
  const changeSignInMethod = useSignInMethodSwitch()
  const workspaces = useAccountSelectorWorkspaces()
  const fastSignInWorkspaceId = canFastAutoSignInToDesktop()

  const accounts = React.useMemo(
    () =>
      workspaces.map(({ workspace, user }) => ({
        id: workspace.id,
        name: workspace.name,
        email: user.email,
      })),
    [workspaces]
  )

  const selectWorkspace = async (workspaceId: string) => {
    const authWorkspace = workspaces.find(ws => ws.workspace.id === workspaceId)

    if (!authWorkspace) return

    setSelectedWorkspaceId(workspaceId)

    try {
      const { token } = await requestOpenApi(
        loginTwiceQuery(authWorkspace.token)
      )
      const auth = {
        workspaceId,
        token,
        userId: authWorkspace.user.id,
      }
      const fallbackAuth = {
        workspaceId,
        token: authWorkspace.token,
        userId: authWorkspace.user.id,
      }
      router.push(pathConstructor.index(auth, fallbackAuth, backUrl))
    } catch (error) {
      if (error instanceof ApiError) {
        setAlert(errorToAlert(error))
      } else {
        throw error
      }
    } finally {
      setSelectedWorkspaceId('')
    }
  }

  const goToSignIn = () => {
    changeSignInMethod('form')
  }

  React.useEffect(() => {
    if (fastSignInWorkspaceId) {
      selectWorkspace(fastSignInWorkspaceId)
    }
  }, [])

  return (
    <View style={styles.box}>
      <Heading align="center" scale={-1}>
        {t('sign-in.selector.title')}
      </Heading>
      <AccountsList
        accounts={accounts}
        selectedId={selectedWorkspaceId}
        onSelect={selectWorkspace}
        fallbackText={t('sign-in.selector.add-account')}
        onFallback={goToSignIn}
      />
    </View>
  )
}

export default AccountSelector
