import * as React from 'react'
function SvgPageLockedTemplateInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4.333V5h1v6h-7V5h1v-.667C13 3.053 14.25 3 15.5 3s2.5.052 2.5 1.333zm-1 0V5h-3v-.667C14 4 14.4 4 15.501 4 16.603 4 17 4 17 4.333zM8 7h3v5h6v8.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H5.75a.723.723 0 01-.531-.219A.723.723 0 015 20.25V12h2v-1.5l-.5-1V9H8V7zm0 6v1h6v-1H8zm6 2v1H8v-1h6zm-1 2H8v1h5v-1zM7 6H2v2l1-1h1v3l-1 1h3l-1-1V7h1l1 1V6zm8 1v2h1V7h-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPageLockedTemplateInverted
