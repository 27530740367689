import { StandardError } from '@packages/common/src/errors/standard-error'

export class AbortSignalError extends StandardError {}

const abortSignalToPromise = <Return>(signal: AbortSignal) =>
  new Promise<Return>((_, reject) => {
    signal.addEventListener(
      'abort',
      () => {
        reject(new AbortSignalError())
      },
      { once: true }
    )
  })

export const abortWithSignal = <Return>(
  promise: Promise<Return>,
  signal: AbortSignal
): Promise<Return> =>
  Promise.race([promise, abortSignalToPromise<Return>(signal)])
