import * as React from 'react'
function SvgOpenAi() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.046 3C9.05 3 7.277 4.268 6.659 6.14a4.591 4.591 0 00-1.757.764 4.52 4.52 0 00-1.283 1.411 4.487 4.487 0 00.569 5.316 4.414 4.414 0 00.39 3.675c.994 1.713 2.996 2.59 4.954 2.182.43.478.956.86 1.546 1.12.59.261 1.23.395 1.877.392 1.996 0 3.77-1.268 4.387-3.14a4.533 4.533 0 003.034-2.175 4.477 4.477 0 00-.562-5.316v-.005a4.426 4.426 0 00-.39-3.682c-.995-1.707-2.997-2.585-4.949-2.177a4.563 4.563 0 00-1.55-1.116A4.62 4.62 0 0011.046 3zm0 1.17l-.006.007c.803 0 1.576.274 2.193.78-.024.012-.074.042-.11.06L9.488 7.085a.582.582 0 00-.296.512v4.852l-1.564-.89v-4.01c0-.895.36-1.753 1-2.387.64-.633 1.51-.99 2.417-.99zm4.376 1.413a3.456 3.456 0 011.715.451c.522.297.955.724 1.255 1.24.396.682.544 1.48.408 2.254-.025-.018-.074-.042-.105-.06l-3.633-2.073a.615.615 0 00-.6 0L10.207 9.82V8.04l3.515-2.005a3.437 3.437 0 011.701-.453zM6.437 7.425v4.261a.59.59 0 00.297.512l4.25 2.42-1.57.896-3.509-2a3.382 3.382 0 01-1.59-2.049 3.336 3.336 0 01.342-2.559 3.414 3.414 0 011.78-1.48zM14.58 8.48l3.516 2a3.345 3.345 0 011.247 4.607l.007.007a3.4 3.4 0 01-1.78 1.475v-4.261a.582.582 0 00-.296-.512L13.017 9.37l1.563-.89zm-2.583 1.469l1.792 1.024v2.042l-1.792 1.024-1.791-1.024v-2.042l1.791-1.024zm2.818 1.61l1.563.89v4.004c0 1.865-1.532 3.377-3.417 3.377v-.006a3.438 3.438 0 01-2.187-.78c.025-.012.08-.043.111-.061l3.633-2.067a.57.57 0 00.297-.512v-4.846zm-1.02 2.62v1.78l-3.515 2c-1.638.926-3.726.377-4.671-1.232h.006a3.308 3.308 0 01-.408-2.255c.025.018.074.042.105.06l3.633 2.073a.615.615 0 00.6 0l4.25-2.426z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgOpenAi
