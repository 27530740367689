import * as React from 'react'
function SvgDiscussionAccept() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15.586l9.293-9.293a1 1 0 111.414 1.414l-10 10a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 15.586z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDiscussionAccept
