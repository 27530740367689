import * as React from 'react'
function SvgChatGpt() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.046 15H17a1 1 0 001-1V8a1 1 0 00-1-1H7a1 1 0 00-1 1v9.339L8.046 15zM5 20V8a2 2 0 012-2h10a2 2 0 012 2v6a2 2 0 01-2 2H8.5L5 20z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChatGpt
