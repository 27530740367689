import * as React from 'react'
function SvgFilesOverview() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.745 4.028L21.895 20.5H2.13l9.616-16.472zm.023 1.944L8.541 11.5h6.634l-3.407-5.528zM5.622 16.5l-1.751 3h16.234l-1.849-3H5.622zm.584-1H17.64l-1.849-3H7.957l-1.751 3z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesOverview
