import * as React from 'react'
function SvgChevronBigLeft() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.795 5.397a.548.548 0 00-.169-.387l-.841-.842A.58.58 0 0015.398 4a.58.58 0 00-.387.168l-7.843 7.843a.58.58 0 00-.168.387.58.58 0 00.168.387l7.843 7.842a.58.58 0 00.387.169.58.58 0 00.387-.169l.841-.841a.58.58 0 00.169-.387.58.58 0 00-.169-.387l-6.614-6.614 6.614-6.614a.58.58 0 00.169-.387z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronBigLeft
