import * as React from 'react'
function SvgDiscussionReject() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.294 12.036l4.517-4.517a.89.89 0 00-1.259-1.258l-4.516 4.516-4.517-4.516A.89.89 0 006.26 7.519l4.516 4.517-4.516 4.516a.89.89 0 101.258 1.259l4.517-4.517 4.516 4.517a.89.89 0 001.259-1.259l-4.517-4.516z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDiscussionReject
