import * as React from 'react'
function SvgExclamationTriangleInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.299 18.138c.171.342.225.69.16 1.044a1.541 1.541 0 01-.513.899c-.279.246-.61.37-.996.37H4.536c-.386 0-.718-.124-.996-.37a1.54 1.54 0 01-.514-.9 1.63 1.63 0 01.16-1.043l7.708-13.36a1.44 1.44 0 01.819-.69 1.634 1.634 0 011.06 0c.353.118.626.348.819.69l7.707 13.36zm-9.056-2.762c-.407 0-.755.145-1.044.434a1.423 1.423 0 00-.433 1.043c0 .407.144.755.433 1.044.29.29.637.434 1.044.434.407 0 .755-.145 1.044-.434.289-.289.433-.637.433-1.043 0-.407-.144-.755-.433-1.044a1.423 1.423 0 00-1.044-.434zm-1.413-5.298l.257 4.367c0 .086.037.166.112.24a.372.372 0 00.273.113h1.542a.372.372 0 00.273-.112.338.338 0 00.112-.241l.257-4.367a.412.412 0 00-.113-.306.372.372 0 00-.272-.112h-2.056a.371.371 0 00-.273.112.412.412 0 00-.112.306z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgExclamationTriangleInverted
