import * as React from 'react'
function SvgTickTiny() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.131 13.978l6.263-6.262a1 1 0 011.414 1.414l-7.121 7.121a.997.997 0 01-.355.23 1 1 0 01-1.298-.403l-2.121-3.674a1 1 0 111.732-1l1.486 2.574z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTickTiny
