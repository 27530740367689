import * as React from 'react'
function SvgExclamationCircle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.25c1.396 0 2.688.349 3.875 1.047a7.83 7.83 0 012.828 2.828A7.513 7.513 0 0119.75 12a7.513 7.513 0 01-1.047 3.875 7.83 7.83 0 01-2.828 2.828A7.513 7.513 0 0112 19.75a7.513 7.513 0 01-3.875-1.047 7.83 7.83 0 01-2.828-2.828A7.513 7.513 0 014.25 12c0-1.396.349-2.688 1.047-3.875a7.831 7.831 0 012.828-2.828A7.513 7.513 0 0112 4.25zm0 14a6.065 6.065 0 003.125-.844 6.303 6.303 0 002.281-2.281c.563-.958.844-2 .844-3.125a6.065 6.065 0 00-.844-3.125 6.303 6.303 0 00-2.281-2.281c-.958-.563-2-.844-3.125-.844a6.065 6.065 0 00-3.125.844 6.303 6.303 0 00-2.281 2.281c-.563.958-.844 2-.844 3.125s.281 2.167.844 3.125a6.303 6.303 0 002.281 2.281c.958.563 2 .844 3.125.844zM13.313 15c0 .354-.13.661-.391.922-.26.26-.568.39-.922.39a1.26 1.26 0 01-.922-.39 1.26 1.26 0 01-.39-.922c0-.354.13-.662.39-.922s.568-.39.922-.39c.354 0 .661.13.922.39.26.26.39.568.39.922zM10.78 8.406c-.02-.125.005-.224.078-.297A.4.4 0 0111.156 8h1.688c.125 0 .224.036.297.11.072.072.099.171.078.296l-.188 4.25a.506.506 0 01-.14.235.328.328 0 01-.235.109h-1.312a.328.328 0 01-.235-.11.506.506 0 01-.14-.234l-.188-4.25z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgExclamationCircle
