import * as React from 'react'
function SvgAiGeneratedChecked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.112 3.434a.75.75 0 10-1.224-.867l-2.27 3.204-1.042-1.251a.75.75 0 10-1.152.96l2.29 2.75 3.398-4.796zM5 5h10.388L21 11.834V18a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2zm.616 12h1.977l.647-1.99h3.151l.646 1.99h1.978l-3.009-8.727H8.628L5.616 17zm5.308-3.43L9.85 10.267h-.068L8.71 13.57h2.215zM16.91 17V8.273h-1.845V17h1.845z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAiGeneratedChecked
