import { EventEmitter } from 'typed-event-emitter'
import {
  AnyAlert,
  AlertConstructor,
} from '@packages/common/src/components/Alerts/common/types'

class AlertEmitter<Alert extends AnyAlert> extends EventEmitter {
  private idCounter = 0

  private nextId() {
    this.idCounter++
    return String(this.idCounter)
  }

  onPush = this.registerEvent<[Alert]>()
  onClose = this.registerEvent<[string]>()

  push = (alert: AlertConstructor<Alert>) => {
    this.emit(this.onPush, {
      ...alert,
      id: alert.id ?? this.nextId(),
    })
  }

  close = (id: string) => {
    this.emit(this.onClose, id)
  }
}

export default AlertEmitter
