import * as React from 'react'
function SvgFilesRoadmap() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21 6.157a1 1 0 01.846-.023l4.937 2.14 4.086-1.897a1 1 0 011.421.907v8.938a1 1 0 01-.639.932L14.355 18.9a1 1 0 01-.7.008l-5-1.806-3.77 1.572A1 1 0 013.5 17.75V9.132a1 1 0 01.553-.895l4.158-2.08zm5.29 2.994l-4-1.734v8.926l4 1.445V9.15zm1-.01v8.63l4-1.55V7.285l-4 1.858zm-10-.01l4-2v8.982a.997.997 0 00-.229.065L4.5 17.75V9.132z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesRoadmap
