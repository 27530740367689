import * as React from 'react'
function SvgChevronSmallDoubleUp() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.734 12.06a.345.345 0 01-.243.106.365.365 0 01-.244-.106l-4.164-4.163L7.92 12.06a.365.365 0 01-.243.106.365.365 0 01-.244-.106l-.53-.53a.365.365 0 01-.106-.243c0-.085.043-.18.106-.244l4.937-4.937A.365.365 0 0112.083 6c.085 0 .18.042.244.106l4.937 4.937a.365.365 0 01.106.244c0 .085-.042.18-.106.244l-.53.53zm0 5.797a.345.345 0 01-.243.106.365.365 0 01-.244-.106l-4.164-4.164-4.164 4.164a.365.365 0 01-.243.106.365.365 0 01-.244-.106l-.53-.53a.365.365 0 01-.106-.244c0-.085.043-.18.106-.243l4.937-4.938a.365.365 0 01.244-.106c.085 0 .18.043.244.106l4.937 4.938a.365.365 0 01.106.243c0 .085-.042.18-.106.244l-.53.53z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallDoubleUp
