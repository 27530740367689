import * as React from 'react'
function SvgInfoCircle() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 1a8 8 0 100-16 8 8 0 000 16zm.689-12.5c.57 0 .854.388.854.832 0 .554-.495 1.067-1.139 1.067-.539 0-.854-.318-.839-.845 0-.444.375-1.054 1.124-1.054zM10.936 16c-.45 0-.779-.277-.464-1.498l.516-2.164c.09-.346.104-.485 0-.485-.135 0-.718.24-1.064.475l-.224-.374c1.093-.929 2.35-1.474 2.89-1.474.45 0 .524.542.3 1.373l-.591 2.275c-.105.402-.06.54.045.54.135 0 .577-.166 1.011-.513l.255.346C12.547 15.584 11.385 16 10.936 16z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInfoCircle
