import * as React from 'react'
function SvgFilesSurveyWidget() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9a1 1 0 100-2 1 1 0 000 2zm1 .732A2 2 0 1016.268 7H6a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1V9.732zM18 10a2 2 0 01-2-2H6v10h12v-8zm-3 2H8v1h7v-1zm-2 2H8v1h5v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesSurveyWidget
