import * as React from 'react'
function SvgTrash() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.787 5.8a.5.5 0 01.459-.3h2.508a.5.5 0 01.459.3l.523 1.2H9.264l.523-1.2zM8.173 7l.698-1.6a1.5 1.5 0 011.375-.9h2.508a1.5 1.5 0 011.375.9l.698 1.6H17.5a.5.5 0 010 1H17v10.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 016 18.5V8h-.5a.5.5 0 010-1h2.673zM7 8h9v10.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V8zm3 1.5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zm3.5.5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTrash
