import * as React from 'react'
function SvgChevronSmallLeft() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.166 7.88a.345.345 0 00-.106-.244l-.53-.53A.365.365 0 0014.288 7a.365.365 0 00-.244.106l-4.937 4.937a.365.365 0 00-.106.244c0 .085.042.18.106.244l4.937 4.937a.365.365 0 00.244.106c.085 0 .18-.043.244-.106l.53-.53a.365.365 0 00.105-.243.365.365 0 00-.106-.244l-4.164-4.164 4.164-4.164a.365.365 0 00.106-.244z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallLeft
