import * as React from 'react'
function SvgChevronSmallUp() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.49 15.37c.096 0 .18-.042.244-.106l.53-.53a.366.366 0 00.106-.243.365.365 0 00-.106-.244L12.327 9.31a.365.365 0 00-.244-.106.365.365 0 00-.244.106l-4.937 4.937a.365.365 0 00-.106.244c0 .084.043.18.106.243l.53.53a.365.365 0 00.244.106c.084 0 .18-.042.243-.106l4.164-4.164 4.164 4.164a.365.365 0 00.244.106z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChevronSmallUp
