import * as React from 'react'
function SvgHelpPanel2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.286 5.788c.198-.198.422-.389.671-.572a4.968 4.968 0 012.937-.957c.506 0 .986.077 1.44.231.463.154.863.374 1.2.66.345.286.616.638.814 1.056.205.41.308.873.308 1.386 0 .557-.088 1.038-.264 1.441a3.992 3.992 0 01-.649 1.067c-.264.3-.55.568-.858.803-.308.227-.598.444-.869.649-.271.198-.502.4-.693.605a1.103 1.103 0 00-.297.682l-.121 1.804h-.715l-.066-1.881c-.015-.308.062-.58.23-.814a3.48 3.48 0 01.65-.671c.264-.213.55-.429.858-.649.308-.22.594-.47.858-.748.271-.279.495-.598.671-.957.183-.36.275-.788.275-1.287a2.33 2.33 0 00-.858-1.848 2.685 2.685 0 00-.913-.495 3.322 3.322 0 00-1.078-.176c-.484 0-.906.066-1.265.198a4.036 4.036 0 00-.891.429 3.842 3.842 0 00-.572.429c-.14.125-.235.187-.286.187a.26.26 0 01-.242-.132l-.275-.44zm2.222 13.365a1.03 1.03 0 01.286-.715 1.04 1.04 0 01.726-.308c.14 0 .271.03.396.088a.89.89 0 01.319.22.887.887 0 01.22.319.919.919 0 01.088.396 1.034 1.034 0 01-.308.726 1.028 1.028 0 01-.715.286c-.286 0-.528-.095-.726-.286a1.008 1.008 0 01-.286-.726z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgHelpPanel2
