import { useRouter } from 'next/router'
import { queryParams } from '@apps/accounts/src/pages/index.helpers'

export const usePreselectedEmail = () => {
  const router = useRouter()
  return router.query[queryParams.autofillEmail] as string | undefined
}

export const useBackUrl = () => {
  const router = useRouter()
  return (
    (router.query[queryParams.backUrl] as string | undefined) ||
    process.env.NEXT_PUBLIC_SIGN_IN_CALLBACK
  )
}

export const useComingFromEmail = () => {
  const router = useRouter()
  return router.query[queryParams.comingFromEmail] as string | undefined
}

export const useOrigin = () => {
  const router = useRouter()
  return (router.query[queryParams.origin] as string | undefined) || 'web'
}
