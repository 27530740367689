import * as React from 'react'
function SvgFolderBookmarksInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.75V9H4V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h8.25c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234zM12 10h8v7.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V10h3v6l2.5-2 2.5 2v-6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderBookmarksInverted
