import * as React from 'react'
function SvgBrandLinear() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.1 13.35l6.55 6.55a8.013 8.013 0 01-6.55-6.55zM4 11.572L12.428 20a8.01 8.01 0 001.463-.214l-9.677-9.677c-.116.48-.188.97-.214 1.463zm.634-2.72l10.515 10.514a7.988 7.988 0 001.1-.577L5.21 7.75c-.22.351-.413.719-.577 1.1zm1.29-2.066a8.006 8.006 0 1111.29 11.29L5.924 6.786z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandLinear
