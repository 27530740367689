import * as React from 'react'
function SvgUnseen() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUnseen
