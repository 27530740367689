import * as React from 'react'
import View from '@packages/common/src/components/View'
import Navigation from '@apps/accounts/src/components/Navigation'
import dynamic from 'next/dynamic'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import AlertProvider from '@apps/accounts/src/components/AlertProvider'
import Meta from '@apps/accounts/src/components/Meta'

// const HelpSection = dynamic(() => import('./HelpSection'), {
//   ssr: false,
// })

const AlertHub = dynamic(
  () => import('@apps/accounts/src/components/AlertHub'),
  {
    ssr: false,
  }
)

type Props = {
  title: string
  children: React.ReactNode
}

type AlertProps = {
  spacingTop?: number
  spacingBottom?: number
}

const styles = cmpStyles({
  box: {
    flexDirection: 'column',
    flex: 1,
    marginHorizontal: 'auto',
    marginBottom: 20,
    maxWidth: 'calc(100% - 40px)',
  },
  notification: ({ marginTop, marginBottom }) => ({
    marginTop,
    marginBottom,
  }),
})

const Page = ({ title, children }: Props) => (
  <AlertProvider>
    <Meta title={title} />

    <View style={styles.box}>
      <Navigation />
      {children}
    </View>
    {/*<HelpSection />*/}
  </AlertProvider>
)

Page.Alert = ({ spacingTop = 30, spacingBottom = 30 }: AlertProps) => (
  <View
    marginTop={spacingTop}
    marginBottom={spacingBottom}
    style={styles.notification}>
    <AlertHub spacingTop={0} spacingBottom={0} />
  </View>
)

export default Page
