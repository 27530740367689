import getAxios, { authHeader } from './axios'
import {
  OpenApiQuery,
  OpenApiQueryResponse,
  RequestBody,
  RequestMethod,
  RequestUrl,
  ResponseCode,
} from '@packages/common/src/providers/network/open-api-query'
import { Query } from '@packages/common/src/providers/network/query'

type RequestOptions = {
  signal?: AbortSignal
}

export const requestOpenApi = <
  Url extends RequestUrl,
  Method extends RequestMethod<Url>,
  Code extends ResponseCode<Url, Method>,
  Data extends RequestBody<Url, Method>
>(
  query: OpenApiQuery<Url, Method, Data, Code>,
  options?: RequestOptions
) => request<OpenApiQueryResponse<typeof query>>(query, options)

export const request = <Response>(query: Query, options?: RequestOptions) =>
  getAxios()
    .request<Response>({ ...query, ...options })
    .then(res => res.data)

export const setPermanentAuthHeader = (token: string) => {
  getAxios().defaults.headers.common.Authorization = authHeader(token)
}
