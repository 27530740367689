import * as React from 'react'
function SvgCloudOffline() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.193 12.47a4.123 4.123 0 00-1.435-1.405c.058-.293.117-.586.117-.909a3.28 3.28 0 00-.44-1.64 3.37 3.37 0 00-1.2-1.202 3.281 3.281 0 00-1.641-.439c-.498 0-.967.117-1.407.322-.468-.674-1.083-1.2-1.816-1.611A5.288 5.288 0 0010.97 5a4.99 4.99 0 00-2.52.674A4.994 4.994 0 006.574 7.46a4.953 4.953 0 00-.761 2.46c-.85.294-1.524.821-2.051 1.554-.528.732-.762 1.552-.762 2.431 0 .762.176 1.465.557 2.11a4.38 4.38 0 001.552 1.552 4.06 4.06 0 002.11.557H18c.674 0 1.29-.146 1.875-.498a3.579 3.579 0 001.377-1.348 3.847 3.847 0 00.498-1.904c0-.674-.205-1.318-.557-1.904zm-1.2 3.897c-.557.557-1.231.82-1.993.82H7.219a3.202 3.202 0 01-1.64-.439 2.964 2.964 0 01-1.202-1.201 3.203 3.203 0 01-.44-1.64c0-.821.264-1.524.82-2.14a3.182 3.182 0 012.022-1.113c-.029-.146-.029-.322-.029-.498 0-.761.176-1.465.557-2.11a4.3 4.3 0 011.552-1.523 4.085 4.085 0 012.11-.585c.879 0 1.67.263 2.373.732a4.376 4.376 0 011.523 1.904c.469-.498 1.026-.761 1.7-.761.644 0 1.2.234 1.67.703.468.468.703.996.703 1.64a2.25 2.25 0 01-.498 1.436c.673.117 1.23.44 1.699.967.44.527.674 1.113.674 1.816 0 .791-.293 1.465-.82 1.992zm-9.64-6.533a.5.5 0 00-.707.707l1.897 1.896-1.897 1.897a.5.5 0 00.708.707l1.896-1.896 1.896 1.896a.5.5 0 00.708-.707l-1.897-1.896 1.897-1.897a.5.5 0 00-.708-.707L12.25 11.73l-1.896-1.896z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCloudOffline
