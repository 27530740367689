import * as React from 'react'
function SvgItalic() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 7V6h5.5v1h-2l-3 10h2v1H7v-1h2l3-10h-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItalic
