import * as React from 'react'
function SvgFolderSharedLockedAlias() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5.333V6h1v7h-2v5.25c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 20H5.75c-.48 0-.89-.172-1.234-.516a1.856 1.856 0 01-.268-.335l.763-.764c.025.151.095.283.208.396A.723.723 0 005.75 19h12.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V13h-3v1h-2v-2h1v-1H5v2H4V7.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 6h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V8h5V6h1v-.667C16 4.053 17.25 4 18.5 4s2.5.052 2.5 1.333zM17 6h3v-.667C20 5 19.603 5 18.501 5 17.4 5 17 5 17 5.333V6zm-2 3H9.75a.723.723 0 01-.531-.219A.723.723 0 019 8.25v-.5a.723.723 0 00-.219-.531A.723.723 0 008.25 7h-2.5a.723.723 0 00-.531.219A.723.723 0 005 7.75V10h10V9zm1 6h1v1h-1v2h-2v-2h-1v2h-2v-2h-1v2H8v-2H7v-1h9zm-5-3h2v2h-2v-2zm5-5v5h5V7h-5zM3.935 21.387c-.76-.29-1.291-.59-1.597-.895-.366-.366-.624-.722-.773-1.066-.444-1.04-.01-2.217 1.303-3.53l.62-.619-.708-.707a.24.24 0 01-.072-.177.24.24 0 01.072-.177.24.24 0 01.177-.071h2.828a.24.24 0 01.177.072.24.24 0 01.072.176v2.829a.24.24 0 01-.072.176.24.24 0 01-.177.072.24.24 0 01-.177-.072l-.707-.707-.618.62c-.18.18-.337.347-.469.5a4.944 4.944 0 00-.366.485c-.112.17-.195.331-.25.485a1.834 1.834 0 00-.099.483 1.36 1.36 0 00.058.5c.05.165.134.337.249.517.115.18.265.362.451.548a6.594 6.594 0 00.426.384c.036.028.063.05.08.066a.146.146 0 01.045.093.114.114 0 01-.037.092c-.03.03-.07.04-.124.03a.441.441 0 01-.097-.024 7.724 7.724 0 01-.215-.083zM10 12v2H8v-2h2zm9-3h-1v2h1V9z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderSharedLockedAlias
