import * as React from 'react'
function SvgItemFeature() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5l7 6.707-7 6.707-7-6.707L12 5zm-5.36 6.707L12 16.78l5.36-5.073L12 6.63l-5.36 5.077z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeature
