import * as React from 'react'
function SvgArrowUp() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.684 11.922l-.43-.45a.452.452 0 01-.137-.331c0-.13.046-.241.137-.332l3.789-3.79a.452.452 0 01.332-.136c.13 0 .24.045.332.137l3.79 3.789a.452.452 0 01.136.332c0 .13-.046.24-.137.332l-.43.449a.42.42 0 01-.332.127.488.488 0 01-.332-.147L13.156 9.56v7.605c0 .13-.045.24-.136.332a.452.452 0 01-.332.137h-.626a.452.452 0 01-.332-.137.452.452 0 01-.136-.332V9.559l-2.246 2.343a.488.488 0 01-.332.147.42.42 0 01-.332-.127z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowUp
