import * as React from 'react'
function SvgWindowsShellMaximize() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7v10H7V7h10zm-1.001 1.001H8.001v7.998h7.998V8.001z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgWindowsShellMaximize
