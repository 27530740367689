import * as React from 'react'
function SvgMegaSupporter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.325 13.153c.118-.258.445-.497.727-.53a.682.682 0 00.571-.571c.034-.28.272-.608.529-.727a.69.69 0 00.369-.72c-.058-.278.068-.663.276-.854a.689.689 0 00.127-.801 1.017 1.017 0 010-.897.688.688 0 00-.127-.801c-.208-.193-.333-.577-.276-.854a.691.691 0 00-.369-.722 1.03 1.03 0 01-.529-.727.689.689 0 00-.571-.572 1.024 1.024 0 01-.727-.528.686.686 0 00-.722-.366 1.024 1.024 0 01-.854-.278.686.686 0 00-.8-.127 1.019 1.019 0 01-.898 0 .684.684 0 00-.801.125 1.021 1.021 0 01-.854.278.685.685 0 00-.72.367c-.119.256-.446.495-.728.527a.69.69 0 00-.572.573 1.023 1.023 0 01-.529.726.69.69 0 00-.366.722c.055.277-.07.662-.278.854a.69.69 0 00-.127.8c.139.247.139.652 0 .898a.69.69 0 00.127.802c.209.19.333.575.278.854a.687.687 0 00.366.72c.258.119.495.447.528.727.034.282.29.54.572.572.282.032.609.272.728.529a.688.688 0 00.72.366c.278-.055.663.069.854.278a.69.69 0 00.801.127c.246-.14.651-.14.898 0a.689.689 0 00.8-.127c.193-.21.576-.333.854-.278a.69.69 0 00.723-.365zM11.5 11.899a3.4 3.4 0 110-6.8 3.4 3.4 0 010 6.8zm-4.025 2.01l-1.243 7.049 3.128-.464L12.141 22l1.238-7.021a6.707 6.707 0 01-5.904-1.07zm7.986.048a6.741 6.741 0 01-.99.597l-.748 4.236 3.369-1.828-1.631-3.005z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMegaSupporter
