import * as React from 'react'
function SvgPage() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 18.25a.723.723 0 01-.219.531.723.723 0 01-.531.219H6.75a.723.723 0 01-.531-.219A.723.723 0 016 18.25V5.75c0-.208.073-.385.219-.531A.723.723 0 016.75 5h10.5c.254 0 .393.065.531.219.139.154.219.297.219.531v12.5zM17 6H7v12h10V6zm-2 4H9v1h6v-1zm-6 2h6v1H9v-1zm5 2H9v1h5v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPage
