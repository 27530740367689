import * as React from 'react'
function SvgItemFeatureSet() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.707 6l4-4 4 4-4 4-4-4zm-6 6l4-4 4 4-4 4-4-4zm20 0l-4 4-4-4 4-4 4 4zm-10 10l4-4-4-4-4 4 4 4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeatureSet
