import * as React from 'react'
function SvgProjectLink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15.5v-1l2-2h1a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1zm3-4.5h-1.5L11 9.5V8a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1zm3.5 1.5a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1h-2zm-1-4.5a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V8zm-7.707 3.312L6.334 8.854l.707-.708 3.666 3.666-3.666 3.666-.707-.707 2.459-2.459H2v-1h6.793z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgProjectLink
