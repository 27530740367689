import * as React from 'react'
function SvgArrowLeft() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.04 15.941l-.45.43a.452.452 0 01-.332.137.452.452 0 01-.332-.137l-3.79-3.789A.452.452 0 017 12.25c0-.13.046-.24.137-.332l3.789-3.79a.452.452 0 01.332-.136c.13 0 .24.046.332.137l.45.43a.42.42 0 01.126.332.488.488 0 01-.146.332l-2.344 2.246h7.605c.13 0 .241.045.332.136a.452.452 0 01.137.332v.626c0 .13-.046.24-.137.332a.452.452 0 01-.332.136H9.676l2.344 2.246c.09.091.14.202.146.332a.42.42 0 01-.127.332z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowLeft
