import * as React from 'react'
function SvgLockUnlocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8c-1.102 0-2 .898-2 2v2.5h4V10c0-1.102-.898-2-2-2zm3.75 4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75H10v-2H8.5V10c0-1.922 1.578-3.5 3.5-3.5s3.5 1.578 3.5 3.5v2.5h.25z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLockUnlocked
