import * as React from 'react'
function SvgFolderDraftsInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.54 5.554a1.5 1.5 0 00-2.176-.239l-5.173 4.434-.005.004a2.5 2.5 0 00-.868 1.105l-1.125 2.813 2.9-.58a2.5 2.5 0 001.124-.542l5.386-4.558a1.5 1.5 0 00.231-2.045l-.294-.392zm-7.293 5.676c.053-.133.124-.256.211-.367l.913 1.065c-.146.087-.307.15-.475.183l-1.089.218.44-1.1zm6.71-4.003l-4.81 4.07-.942-1.1 4.81-4.123a.5.5 0 01.725.08l.294.392a.5.5 0 01-.077.681zM15.692 7l-2.461 2H4V6.75c0-.48.172-.89.516-1.234A1.684 1.684 0 015.75 5h2.5c.48 0 .89.172 1.234.516.344.343.516.755.516 1.234V7h5.692zM4 10h8l-1.5 5 5.1-.85a3 3 0 001.564-.775L20 10.705v6.545c0 .48-.172.89-.516 1.234A1.684 1.684 0 0118.25 19H5.75c-.48 0-.89-.172-1.234-.516A1.684 1.684 0 014 17.25V10z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderDraftsInverted
