import * as React from 'react'
function SvgPanelBrowserInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.885 12c.02.089.03.18.03.273 0 .344-.15.672-.36.938l-2.305 2.836c-.445.547-1.297.953-2 .953h-8.5c-.457 0-.875-.178-1.188-.469.701-.856 2.35-2.873 2.571-3.172l.016-.022c.221-.3.746-1.01 1.21-1.183.26-.097.941-.154.941-.154h9.585zM17 11h-7c-.71 0-1.547.398-2 .953l-2.969 3.625A1.735 1.735 0 015 15.25v-7.5C5 6.79 5.79 6 6.75 6h2.5c.96 0 1.75.79 1.75 1.75V8h4.25c.961 0 1.75.79 1.75 1.75V11z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelBrowserInverted
