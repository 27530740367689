import React from 'react'
import Page from '@apps/accounts/src/components/Page'
import SignInPage from '@apps/accounts/src/features/Workspace/SignInPage'
import { getServerSidePropsWithAuth } from '@apps/accounts/src/providers/api/iron'
import {
  AuthWorkspace,
  validateTokens,
} from '@apps/accounts/src/features/Workspace/SignInPage/validated-workspaces'
import { useTranslation } from 'react-i18next'
import createSignInUrl from '@apps/accounts/src/utils/create-sign-in-url'
import { queryParams } from '@apps/accounts/src/pages/index.helpers'
import { isMobileSsr } from '@apps/accounts/src/utils/user-agent'
import { pathConstructor as phonesFallbackPathConstructor } from '@apps/accounts/src/pages/phones-fallback.helpers'
import * as url from 'url'

type Props = {
  workspaces: AuthWorkspace[]
}

const Index = ({ workspaces }: Props) => {
  const { t } = useTranslation()
  return (
    <Page title={t('sign-in.title')}>
      <Page.Alert />

      <SignInPage workspaces={workspaces} />
    </Page>
  )
}

export const getServerSideProps = getServerSidePropsWithAuth(
  async ({ req, query }) => {
    if (isMobileSsr(req)) {
      return {
        redirect: {
          destination: url.format(phonesFallbackPathConstructor.index()),
          permanent: false,
        },
      }
    }

    const forceSignIn = query[queryParams.forceSignIn] === '1'
    const tokens = Object.values(req.session.auth || {}).filter(
      value => typeof value === 'string'
    )
    const workspaces = await validateTokens(tokens as string[])

    if (workspaces.length && !forceSignIn) {
      const workspace = workspaces[0]
      return {
        redirect: {
          destination: createSignInUrl({
            token: workspace.token,
            workspaceId: workspace.workspace.id,
            userId: workspace.user.id,
          }),
          permanent: false,
        },
      }
    }

    return {
      props: {
        workspaces,
      },
    }
  }
)

export default Index
