import * as React from 'react'
function SvgLoaderCircleTiny() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.978 11.49a.478.478 0 01-.488.51c-.281 0-.507-.229-.535-.509a4.981 4.981 0 10-5.464 5.464c.28.028.509.254.509.535a.478.478 0 01-.51.488 6.001 6.001 0 116.488-6.487z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLoaderCircleTiny
