import * as React from 'react'
function SvgArrowRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.96 15.941l.45.43a.452.452 0 00.332.137c.13 0 .241-.046.332-.137l3.79-3.789A.452.452 0 0017 12.25c0-.13-.046-.24-.137-.332l-3.789-3.79a.452.452 0 00-.332-.136c-.13 0-.24.046-.332.137l-.45.43a.42.42 0 00-.126.332c.007.13.055.24.146.332l2.344 2.246H6.72c-.13 0-.241.045-.332.136a.452.452 0 00-.137.332v.626c0 .13.046.24.137.332a.452.452 0 00.332.136h7.605l-2.344 2.246a.488.488 0 00-.146.332.42.42 0 00.127.332z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArrowRight
