import * as React from 'react'
function SvgItemFeatureCounter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7l-5 5 5 5 5-5-5-5z" stroke="currentColor" />
    </svg>
  )
}
export default SvgItemFeatureCounter
