import * as React from 'react'
function SvgSendPaperRocket() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.724 12.533L19 4m-8.276 8.533L13.828 20 19 4m-8.276 8.533L4 9.333 19 4"
        stroke="currentColor"
      />
    </svg>
  )
}
export default SvgSendPaperRocket
