import React from 'react'
import { requestOpenApi } from '@packages/common/src/providers/network/client'
import validatorQuery from '@packages/common/src/providers/network/queries/auth-tokens/validator'

export type AuthWorkspace = {
  token: string
  user: { id: string; fullName: string; email: string }
  workspace: { id: string; name: string }
}

type ValidatedWorkspace = {
  valid: true
  user: { id: string; fullName: string; email: string }
  workspace: { id: string; name: string }
}

const validatedWorkspacesCtx = React.createContext<AuthWorkspace[]>([])

export const useValidatedWorkspaces = () =>
  React.useContext(validatedWorkspacesCtx)

type Props = { workspaces: AuthWorkspace[]; children: React.ReactNode }

export const ValidatedWorkspacesProvider = ({
  workspaces,
  children,
}: Props) => (
  <validatedWorkspacesCtx.Provider value={workspaces}>
    {children}
  </validatedWorkspacesCtx.Provider>
)

export const validateTokens = async (
  tokens: string[]
): Promise<AuthWorkspace[]> => {
  if (!tokens) return []

  const validation = await requestOpenApi(validatorQuery(tokens as string[]))
  const workspaces = Object.entries(validation.authTokens || {})
    .filter((entry): entry is [string, ValidatedWorkspace] => entry[1].valid)
    .map(([token, val]) => ({
      workspace: val.workspace,
      user: val.user,
      token,
    }))

  return workspaces
}
