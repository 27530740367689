import * as React from 'react'
import baseErrorToAlert from '@apps/accounts/src/utils/error-to-alert'
import i18n from 'i18next'
import { ApiError } from '@packages/common/src/providers/network/errors'
import Text from '@packages/common/src/components/Text'
import { Trans } from 'react-i18next'
import Link from '@apps/accounts/src/components/Link'
import { InlineAlert as InlineAlert } from '@packages/common/src/components/Alerts/Inline/type'
import { AlertConstructor } from '@packages/common/src/components/Alerts/common/types'

const errorToAlert = (error: unknown): AlertConstructor<InlineAlert> => {
  if (error instanceof ApiError && error.status === 401) {
    if (error.body?.error?.message === 'userSuspended') {
      return {
        accent: 'error',
        children: (
          <Text color="inherit">
            <Trans
              i18nKey="sign-in.account-suspended"
              components={{
                b: <Text weight="black" color="inherit" />,
              }}
            />
          </Text>
        ),
      }
    } else {
      return {
        accent: 'error',
        title: i18n.t('sign-in.form.validations.password.mismatch'),
        children: (
          <Text color="inherit">
            <Trans
              i18nKey="sign-in.forgot-password"
              components={{
                a: <Link to="/reset-password" />,
              }}
            />
          </Text>
        ),
      }
    }
  }

  return baseErrorToAlert(error)
}

export default errorToAlert
