import * as React from 'react'
function SvgShare() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6H7v3h3V6zm-5 4h15v4h-2v4h-3v-4h-1v4h-3v-4h-1v4H7v-4H5v-4zm6-4h3v3h-3V6zm7 0h-3v3h3V6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgShare
