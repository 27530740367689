import * as React from 'react'
function SvgPlusBigMedium() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5a.75.75 0 00-.75.75v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5A.75.75 0 0012 6.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusBigMedium
