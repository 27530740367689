import * as React from 'react'
function SvgLockSharingUnlocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.571 9.357V5.405c0-1.83-1.785-1.905-3.571-1.905-1.786 0-3.571.074-3.571 1.905v.952h1.428v-.952c0-.476.571-.476 2.145-.476 1.573 0 2.14 0 2.14.476v3.952H7v10h10v-10h-1.429zM8.43 10.786v7.143h7.142v-7.143H8.43zm4.285 2.857h-1.428V16.5h1.428v-2.857z"
        fill="currentColor"
      />
      <mask
        id="lock-sharing-unlocked_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={7}
        y={6}
        width={10}
        height={14}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.571 9.357v-.952c0-1.83-1.785-1.905-3.571-1.905-1.786 0-3.571.074-3.571 1.905v.952H7v10h10v-10h-1.429zm-1.428 0H9.857v-.952c0-.476.571-.476 2.145-.476 1.573 0 2.14 0 2.14.476v.952zm-5.714 1.429v7.143h7.142v-7.143H8.43zm4.285 5.714v-2.857h-1.428V16.5h1.428z"
          fill="#fff"
        />
      </mask>
    </svg>
  )
}
export default SvgLockSharingUnlocked
