import * as React from 'react'
function SvgBrandLinkedin() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.906 20.5H3.273V8.82h3.633V20.5zM5.11 7.219a2.028 2.028 0 01-1.484-.625A2.028 2.028 0 013 5.109c0-.573.208-1.067.625-1.484A2.028 2.028 0 015.109 3c.573 0 1.068.208 1.485.625.416.417.625.911.625 1.484s-.209 1.068-.625 1.485a2.028 2.028 0 01-1.485.625zM20.5 20.5h-3.633v-5.703c0-.938-.078-1.615-.234-2.031-.287-.704-.847-1.055-1.68-1.055s-1.42.312-1.758.937c-.26.47-.39 1.16-.39 2.07V20.5H9.21V8.82h3.476v1.602h.04c.26-.521.677-.95 1.25-1.29.624-.416 1.354-.624 2.187-.624 1.693 0 2.878.534 3.555 1.601.52.86.781 2.188.781 3.985V20.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandLinkedin
