import * as React from 'react'
function SvgLockLocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.5V10c0-1.102.898-2 2-2 1.102 0 2 .898 2 2v1.5h-4zm6.5.75a.75.75 0 00-.75-.75h-.25V10c0-1.922-1.578-3.5-3.5-3.5A3.514 3.514 0 008.5 10v1.5h-.25a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-4.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLockLocked
