import * as React from 'react'
function SvgGlobe() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 5.5C8.186 5.5 5 8.685 5 12.6c0 3.915 3.185 7.1 7.1 7.1 3.916 0 7.101-3.185 7.101-7.1 0-3.915-3.185-7.1-7.1-7.1zm6.057 7.1a6.03 6.03 0 01-1.255 3.683c-.222-.173-.457-.64-.236-1.126.224-.488.283-1.618.231-2.058-.049-.44-.277-1.498-.898-1.508-.62-.01-1.045-.214-1.413-.95-.764-1.529 1.434-1.822.67-2.669-.214-.238-1.319.978-1.48-.642-.011-.116.1-.29.248-.47a6.066 6.066 0 014.133 5.74zm-6.888-5.996c-.145.283-.527.397-.76.61-.506.457-.723.394-.995.833-.275.44-1.16 1.072-1.16 1.39 0 .318.448.692.67.62.224-.074.814-.07 1.16.051.347.123 2.895.246 2.083 2.4-.258.685-1.386.57-1.686 1.703-.045.166-.201.877-.21 1.11-.02.359.254 1.714-.093 1.714-.349 0-1.286-1.212-1.286-1.432 0-.22-.242-.99-.242-1.65 0-.658-1.123-.648-1.123-1.524 0-.79.609-1.184.472-1.563-.134-.377-1.203-.39-1.65-.437a6.074 6.074 0 014.82-3.825zm-1.073 11.743c.364-.193.4-.441.73-.454.378-.017.684-.147 1.11-.24.377-.083 1.052-.466 1.646-.515.502-.04 1.49.026 1.757.51a6.012 6.012 0 01-5.243.699z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgGlobe
