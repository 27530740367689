import * as React from 'react'
function SvgTickSmall() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35 14.424l-1.89-1.89 1.89-1.888 1.888 1.889 4.475-4.475 1.89 1.89-4.476 4.474-1.889 1.889-1.889-1.889z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgTickSmall
