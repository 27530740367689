import * as React from 'react'
function SvgBrandSpecific() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.793 4c1.023 0 1.989.58 2.605 1.423l-1.247.784c-.322-.436-.812-.784-1.386-.784-.658 0-1.414.479-1.694 1.597l-2.576 10.09C11.005 19.014 9.59 20 8.205 20c-1.023 0-1.99-.58-2.605-1.423l1.246-.784c.322.436.812.784 1.386.784.659 0 1.415-.479 1.695-1.597l2.576-10.09c.49-1.903 1.904-2.89 3.29-2.89zM10.64 8.152l-1.96 7.695H7.084l1.96-7.695h1.596zm4.313 7.695l1.96-7.695h-1.596l-1.96 7.695h1.596z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandSpecific
