import * as React from 'react'
function SvgFilesSurvey() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.906 12a5.5 5.5 0 015.5-5.5h2a5.5 5.5 0 010 11h-2a5.48 5.48 0 01-2.15-.436l-.29-.123-.236.206c-.954.834-1.98 1.201-3.3 1.313l.152-.197.007-.01c.532-.694 1.07-1.397 1.56-2.268l.166-.294-.21-.263A5.475 5.475 0 015.906 12zm5.5-6.5a6.5 6.5 0 00-5.291 10.276c-.409.68-.853 1.26-1.327 1.88l-.002.001c-.218.286-.444.58-.67.891-.29.4 0 .957.491.95 1.773-.021 3.22-.345 4.544-1.4.703.26 1.463.402 2.255.402h2a6.5 6.5 0 000-13h-2zm.52 7.89v.305h1.045v-.3c0-.23.039-.427.115-.59.08-.167.184-.313.31-.44.127-.127.26-.252.4-.375a5.49 5.49 0 00.43-.405 1.9 1.9 0 00.34-.52c.09-.2.135-.447.135-.74a1.96 1.96 0 00-.25-.985 1.771 1.771 0 00-.725-.7c-.32-.173-.711-.26-1.175-.26-.456 0-.856.092-1.2.275-.34.183-.605.443-.795.78-.186.333-.28.728-.28 1.185h1.02c0-.38.109-.683.325-.91.217-.23.527-.345.93-.345.357 0 .629.093.815.28.19.183.285.408.285.675 0 .27-.075.492-.225.665a4.403 4.403 0 01-.515.51 5.21 5.21 0 00-.46.445c-.15.163-.275.36-.375.59-.1.23-.15.517-.15.86zm-.015 2.02c.144.147.322.22.535.22a.707.707 0 00.535-.22.72.72 0 00.215-.52.73.73 0 00-.215-.52.7.7 0 00-.535-.225.71.71 0 00-.535.225.73.73 0 00-.215.52c0 .2.072.373.215.52z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesSurvey
