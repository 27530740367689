import * as React from 'react'
function SvgItemFeatureTemplateV2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.2c0-.11.09-.2.2-.2h10.6c.11 0 .2.09.2.2v1.6a.2.2 0 01-.2.2h-.6a.2.2 0 01-.2-.2V7h-4v11h.8c.11 0 .2.09.2.2v.6a.2.2 0 01-.2.2h-2.6a.2.2 0 01-.2-.2v-.6c0-.11.09-.2.2-.2h.8V7H8v.8a.2.2 0 01-.2.2h-.6a.2.2 0 01-.2-.2V6.2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemFeatureTemplateV2
