import * as React from 'react'
function SvgDropdownRight() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.317 17.157a.39.39 0 00.322-.146l4.213-4.187a.5.5 0 000-.71L10.64 7.93a.39.39 0 00-.322-.147.39.39 0 00-.322.147l-.733.732a.39.39 0 00-.146.322.39.39 0 00.146.323l3.135 3.164-3.135 3.164a.39.39 0 00-.146.322.39.39 0 00.146.322l.733.733a.39.39 0 00.322.146z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDropdownRight
