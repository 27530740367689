import * as React from 'react'
function SvgGlobe1() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.018 11.5a6.958 6.958 0 01.884-2.94c.304.093.729.212 1.261.33.26.058.544.116.854.17a10.254 10.254 0 00-.406 2.44H5.018zm2.319-3.39a16.01 16.01 0 01-.982-.25 7.01 7.01 0 013.77-2.606 10.046 10.046 0 00-1.848 3.04 19.143 19.143 0 01-.94-.184zm1.075 3.39c.042-.854.19-1.625.402-2.315.836.115 1.804.2 2.886.213V11.5H8.412zm.677-3.085c.764.1 1.64.17 2.611.183V5.006a7.078 7.078 0 00-.206.012 7.027 7.027 0 00-.265.24 9.263 9.263 0 00-2.14 3.157zM12.5 11.5V9.395a23.176 23.176 0 002.74-.217c.186.68.314 1.45.35 2.322H12.5zm0-2.905c.926-.02 1.761-.093 2.492-.19a8.095 8.095 0 00-1.064-1.98 6.977 6.977 0 00-1.137-1.22 5.318 5.318 0 00-.233-.183l-.058-.004v3.577zm3.89 2.905a11.256 11.256 0 00-.355-2.449c.29-.052.557-.106.802-.16.532-.119.957-.238 1.26-.332.498.88.81 1.878.885 2.941h-2.591zm.273-3.39c.392-.088.721-.175.982-.25a7.012 7.012 0 00-3.603-2.557c.173.19.351.405.53.647a8.93 8.93 0 011.226 2.33c.315-.054.604-.112.865-.17zM6.355 16.14a7.01 7.01 0 003.77 2.606 10.046 10.046 0 01-1.848-3.04 19.12 19.12 0 00-.94.184c-.392.088-.721.175-.982.25zm-.453-.7a6.961 6.961 0 01-.896-3.14h2.598c.027.974.181 1.854.413 2.64-.31.054-.595.112-.854.17a16.18 16.18 0 00-1.26.33zm3.188.145a9.264 9.264 0 002.139 3.156c.1.097.19.177.265.241.069.005.137.009.206.012v-3.592c-.971.012-1.847.084-2.61.183zm2.61-.983c-1.082.013-2.05.098-2.885.213a9.474 9.474 0 01-.41-2.515H11.7v2.302zm.8.803v3.577l.058-.004c.067-.05.146-.111.233-.184a6.975 6.975 0 001.137-1.219 8.093 8.093 0 001.063-1.98c-.73-.097-1.565-.17-2.491-.19zm2.74-.583a23.202 23.202 0 00-2.74-.217V12.3h3.096a10.506 10.506 0 01-.355 2.522zm.558.897a8.929 8.929 0 01-1.226 2.33 8.2 8.2 0 01-.53.648 7.012 7.012 0 003.603-2.557c-.26-.075-.59-.162-.982-.25-.261-.058-.55-.116-.865-.17zm2.3-.278a16.172 16.172 0 00-1.261-.332 19.901 19.901 0 00-.802-.16c.205-.777.339-1.657.362-2.649h2.597a6.96 6.96 0 01-.896 3.14zM12 20a8 8 0 100-16 8 8 0 000 16z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgGlobe1
