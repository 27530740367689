import * as React from 'react'
function SvgFolderInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 0018.25 7H10v-.25c0-.48-.172-.89-.516-1.234A1.684 1.684 0 008.25 5h-2.5c-.48 0-.89.172-1.234.516A1.684 1.684 0 004 6.75V9h16zm0 1H4v7.25c0 .48.172.89.516 1.234.343.344.755.516 1.234.516h12.5c.48 0 .89-.172 1.234-.516.344-.343.516-.755.516-1.234V10z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFolderInverted
