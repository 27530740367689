import * as React from 'react'
function SvgFilesProblems() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={11.5} cy={12} r={8} stroke="currentColor" />
      <path
        d="M10.583 8.497a.92.92 0 111.834 0L12 13.5h-1l-.417-5.003z"
        fill="currentColor"
      />
      <circle cx={11.5} cy={15.5} r={1} fill="currentColor" />
    </svg>
  )
}
export default SvgFilesProblems
