import * as React from 'react'
function SvgRightPanelOff() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7h4a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-4V7zm-1 0H5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h9V7zM3 7.5a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2v-10z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgRightPanelOff
