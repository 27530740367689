import * as React from 'react'
import View from '@packages/common/src/components/View'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import Logo from '@apps/accounts/src/components/Logo'

const styles = cmpStyles({
  box: {
    padding: 21,
    justifyContent: 'center',
  },
})

const Navigation = () => (
  <View style={styles.box}>
    <a href="https://specific.app">
      <Logo height={17} />
    </a>
  </View>
)

export default Navigation
