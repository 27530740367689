import * as React from 'react'
function SvgFilesDashboard() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 19V5h1v10.293l5.028-5.028 2.987 3.484 6.119-6.59.732.681-6.88 7.41-3.014-3.515L5 16.707V19h15v1H4v-1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesDashboard
