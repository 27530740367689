import * as React from 'react'
function SvgBrandFigma() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 6.368C6 4.508 7.446 3 9.23 3h5.54C16.555 3 18 4.508 18 6.368c0 1.177-.58 2.214-1.458 2.816A3.403 3.403 0 0118 12c0 1.86-1.446 3.368-3.23 3.368h-.07a3.145 3.145 0 01-2.171-.875v3.103c0 1.884-1.484 3.404-3.282 3.404C7.467 21 6 19.497 6 17.632c0-1.177.58-2.214 1.458-2.816A3.403 3.403 0 016 12c0-1.178.58-2.214 1.458-2.816A3.403 3.403 0 016 6.368zm5.471 3.367H9.23C8.03 9.735 7.058 10.75 7.058 12c0 1.246.965 2.257 2.158 2.265h2.255v-4.53zM12.53 12c0 1.25.972 2.265 2.171 2.265h.07c1.2 0 2.172-1.014 2.172-2.265 0-1.25-.972-2.265-2.171-2.265h-.07c-1.2 0-2.172 1.014-2.172 2.265zm-3.3 3.368h-.013c-1.193.008-2.158 1.018-2.158 2.264 0 1.246.985 2.265 2.189 2.265 1.223 0 2.224-1.035 2.224-2.301v-2.228H9.23zm0-11.265c-1.199 0-2.171 1.014-2.171 2.265 0 1.25.972 2.264 2.171 2.264h2.242V4.103H9.23zm3.3 4.53h2.242c1.199 0 2.171-1.015 2.171-2.265 0-1.251-.972-2.265-2.171-2.265h-2.242v4.53z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandFigma
