import * as React from 'react'
function SvgVideo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.348 15.196v-4.49l4.322 2.258-4.322 2.232zM12 7.5c-3.366 0-5.598.16-5.598.16-.313.036-1 .036-1.607.68 0 0-.491.481-.634 1.589-.17 1.294-.16 2.589-.16 2.589v1.214s-.01 1.295.16 2.59c.143 1.098.634 1.589.634 1.589.607.634 1.41.616 1.768.678 0 0 1.276.125 5.437.161 3.366 0 5.598-.17 5.598-.17.313-.035 1-.035 1.608-.67 0 0 .49-.49.633-1.589.17-1.294.161-2.589.161-2.589v-1.214s.009-1.295-.16-2.59c-.143-1.107-.634-1.589-.634-1.589-.608-.643-1.295-.643-1.608-.678 0 0-2.232-.161-5.598-.161z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgVideo
