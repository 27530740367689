import * as React from 'react'
function SvgAlertInfoSmall() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.282 4c1.006 0 1.51.685 1.51 1.47 0 .98-.875 1.887-2.013 1.887-.953 0-1.509-.564-1.482-1.495 0-.783.661-1.862 1.985-1.862zm-3.097 15.022c-.795 0-1.377-.49-.821-2.647l.912-3.825c.158-.611.185-.857 0-.857-.238 0-1.269.423-1.88.84L8 11.87c1.932-1.642 4.154-2.604 5.108-2.604.794 0 .926.957.53 2.426l-1.045 4.02c-.185.71-.106.955.08.955.238 0 1.019-.295 1.786-.907l.451.612c-1.88 1.913-3.932 2.649-4.725 2.649z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAlertInfoSmall
