import * as React from 'react'
function SvgCheckCircleInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 12.25c0 2.026-.507 3.901-1.52 5.625a11.367 11.367 0 01-4.105 4.105 10.905 10.905 0 01-5.625 1.52c-2.026 0-3.901-.507-5.625-1.52a11.368 11.368 0 01-4.105-4.105A10.905 10.905 0 011 12.25c0-2.026.507-3.901 1.52-5.625A11.368 11.368 0 016.625 2.52 10.905 10.905 0 0112.25 1c2.026 0 3.901.507 5.625 1.52a11.368 11.368 0 014.105 4.105 10.905 10.905 0 011.52 5.625zm-12.566 5.942l8.347-8.346a.604.604 0 00.227-.5.604.604 0 00-.227-.498l-.998-1.044a.717.717 0 00-.521-.226.717.717 0 00-.522.226l-6.804 6.805-3.176-3.175a.717.717 0 00-.522-.227.717.717 0 00-.521.226l-.998 1.044a.604.604 0 00-.227.499c0 .211.076.378.227.499l4.717 4.717c.121.152.288.227.5.227a.604.604 0 00.498-.227z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCheckCircleInverted
