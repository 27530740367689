import * as React from 'react'
import { ErrorMessage, useFormikContext } from 'formik'
import InputError from '@packages/common/src/components/InputError'
import { TestableProps } from '@packages/common/src/components/Stylo'

type Props = {
  name: string // validation message is usually outside of controlCtx
}

const FormValidationMessage = ({ name }: Props) => (
  <ErrorMessage name={name}>
    {msg => <InputError testID={`${name}-field-validation`}>{msg}</InputError>}
  </ErrorMessage>
)

export const FormGroupedValidationMessage = ({
  fields,
  testID,
}: {
  fields: string[]
} & TestableProps) => {
  const { getFieldMeta } = useFormikContext()

  const errorField = fields.find(field => {
    const meta = getFieldMeta(field)
    return meta.touched && meta.error
  })

  if (!errorField) return null

  const { error } = getFieldMeta(errorField)

  if (!error) return null

  return <InputError testID={testID}>{error}</InputError>
}

export default FormValidationMessage
