import {
  buildQuery,
  ResponseOf,
} from '@packages/common/src/providers/network/open-api-query'
import { authHeader } from '@packages/common/src/providers/network/axios'

export type ResponseWithEmail = ResponseOf<'/auth_tokens', 'post', 201>

export const withEmail = (email: string, password: string) =>
  buildQuery('/auth_tokens', 'post', 201, { data: { email, password } })

export const withToken = (token: string) =>
  buildQuery('/auth_tokens/new', 'post', 201, {
    headers: {
      Authorization: authHeader(token),
    },
  })
