import * as React from 'react'
function SvgPanelNews() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 9c.417 0 .77.146 1.063.437.291.292.437.646.437 1.063v3a.487.487 0 01-.14.36.487.487 0 01-.36.14H19v3.5c0 .417-.146.77-.438 1.063A1.447 1.447 0 0117.5 19h-11c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 015 17.5V14h-.5a.487.487 0 01-.36-.14.487.487 0 01-.14-.36v-3c0-.417.146-.77.438-1.063A1.447 1.447 0 015.5 9h1.219a2.608 2.608 0 01-.469-1.5c0-.688.23-1.276.687-1.766C7.396 5.244 7.943 5 8.578 5c.636 0 1.203.219 1.703.656C10.74 6.073 11.312 6.854 12 8c.688-1.146 1.26-1.927 1.719-2.344.5-.437 1.067-.656 1.703-.656.635 0 1.182.245 1.64.734.459.49.688 1.079.688 1.766 0 .542-.156 1.042-.469 1.5H18.5zm-5.875-.125L12.562 9h2.844c.375 0 .693-.146.953-.438.26-.291.391-.645.391-1.062 0-.417-.13-.77-.39-1.063-.261-.291-.568-.437-.922-.437-.292 0-.532.052-.72.156-.25.146-.536.427-.859.844-.323.417-.734 1.042-1.234 1.875zM8.594 6a1.23 1.23 0 00-.953.438c-.26.291-.391.645-.391 1.062 0 .417.13.77.39 1.062.261.292.568.438.922.438h2.876l-.063-.125c-.5-.833-.912-1.458-1.234-1.875-.323-.417-.61-.698-.86-.844A1.398 1.398 0 008.594 6zM10 18v-4H6v3.5c0 .146.047.266.14.36.094.093.214.14.36.14H10zm0-5v-3H5.5a.487.487 0 00-.36.14.487.487 0 00-.14.36V13h5zm3 5v-8h-2v8h2zm5-.5V14h-4v4h3.5a.486.486 0 00.36-.14.486.486 0 00.14-.36zm1-4.5v-2.5a.487.487 0 00-.14-.36.487.487 0 00-.36-.14H14v3h5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelNews
