import i18next from 'i18next'
import {
  ApiError,
  ClientNetworkError,
} from '@packages/common/src/providers/network/errors'
import ResponseValidationError from '@packages/common/src/providers/network/queries/ValidationError'
import { submissionErrorsHandler as submissionErrorsHandlerCommon } from '@packages/common/src/utils/forms'
import { FormikBag, FormikValues } from 'formik'
import {
  FormValidation,
  I18nKey,
  I18nServerErrors,
} from '@packages/common/src/providers/network/form-validation'

export const errorToFormValidations = <Values>(
  error: Error,
  mapServerErrorsIntoTranslations: I18nServerErrors = {}
): FormValidation<Values> => {
  if (
    !(error instanceof ApiError) ||
    error.status >= 500 ||
    typeof error.body?.validation !== 'object' ||
    !error.body?.validation
  ) {
    return undefined
  }

  const response = error.body as ResponseValidationError

  return Object.fromEntries(
    Object.entries(response.validation).map(([fieldName, errors]) => [
      fieldName,
      errors.map(({ validation, message }) => {
        const translationKey: I18nKey | undefined =
          mapServerErrorsIntoTranslations[fieldName]?.[validation]

        return translationKey ? i18next.t(translationKey, message) : message
      }),
    ])
  )
}

const throwTimeout = (error: unknown) => {
  /*
   Formik would catch the error and log a warning. But this isn't network
   error and we *want to* crash. It is an unexpected exception.
   This way we bail out of the formik event loop.
   */
  setTimeout(() => {
    throw error
  })
}

export const submissionErrorsHandler = <
  Props = void,
  Values extends FormikValues = any
>(
  mapServerErrorsIntoTranslations: I18nServerErrors = {},
  onNetworkError: (
    error: ClientNetworkError,
    bag: FormikBag<Props, Values>
  ) => void = () => undefined,
  onUnknownError = throwTimeout
) =>
  submissionErrorsHandlerCommon<Props, Values>((error, bag) => {
    if (error instanceof ClientNetworkError) {
      const validationErrors =
        error instanceof ApiError &&
        errorToFormValidations(error, mapServerErrorsIntoTranslations)

      if (validationErrors) {
        // formik (2.2.9) can't setErrors() for nested fields
        Object.entries(validationErrors).forEach(([field, fieldError]) => {
          // @ts-expect-error fixme
          bag.setFieldError(field, fieldError[0])
        })
      } else {
        onNetworkError(error, bag as FormikBag<Props, Values>)
      }
    } else {
      onUnknownError(error)
    }
  })
