import * as React from 'react'
function SvgInfo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.739 5c.903 0 1.354.615 1.354 1.319 0 .88-.784 1.693-1.805 1.693-.855 0-1.354-.506-1.33-1.341 0-.703.594-1.671 1.781-1.671zM10.96 18.478c-.713 0-1.235-.44-.736-2.375l.818-3.431c.142-.549.166-.77 0-.77-.214 0-1.138.38-1.686.754L9 12.063c1.733-1.474 3.727-2.337 4.583-2.337.713 0 .831.858.475 2.177l-.937 3.607c-.166.636-.095.856.071.856.214 0 .915-.264 1.604-.813l.404.548c-1.686 1.717-3.528 2.377-4.24 2.377z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInfo
