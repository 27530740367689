import * as React from 'react'
function SvgQuote() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.805 15.85c-.78 0-1.425-.285-1.935-.855-.48-.57-.72-1.44-.72-2.61 0-1.53.39-2.88 1.17-4.05.81-1.2 2.055-1.95 3.735-2.25l.18 1.17C9.345 7.765 8.4 8.65 8.4 9.91c0 .54.255.855.765.945 1.47.24 2.205 1.05 2.205 2.43 0 .81-.255 1.44-.765 1.89-.48.45-1.08.675-1.8.675zm7.02 0c-.78 0-1.425-.285-1.935-.855-.48-.57-.72-1.44-.72-2.61 0-1.53.39-2.88 1.17-4.05.81-1.2 2.055-1.95 3.735-2.25l.18 1.17c-1.89.51-2.835 1.395-2.835 2.655 0 .54.255.855.765.945 1.47.24 2.205 1.05 2.205 2.43 0 .81-.255 1.44-.765 1.89-.48.45-1.08.675-1.8.675z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgQuote
