import { UrlObject } from 'url'

export const queryParams = {
  backUrl: 'back-url',
  forceSignIn: 'force-sign-in',
  origin: 'origin',
  comingFromEmail: 'from-email',
  autofillEmail: 'email',
}

export const pathConstructor = {
  index: (): UrlObject => ({
    pathname: '/',
  }),
  forceSignIn: (): UrlObject => ({
    pathname: '/',
    query: {
      [queryParams.forceSignIn]: '1',
    },
  }),
}
