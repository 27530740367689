import React from 'react'
import useAccountSelectorWorkspaces from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector/use-account-selector-workspaces'
import {
  useComingFromEmail,
  usePreselectedEmail,
} from '@apps/accounts/src/features/Workspace/SignInPage/query-hooks'

const canFastAutoSignInToDesktop = () => {
  const email = usePreselectedEmail()
  const comingFromEmail = useComingFromEmail()
  const workspaces = useAccountSelectorWorkspaces(comingFromEmail)

  const workspaceIdRef = React.useRef(
    (() =>
      email
        ? workspaces.find(({ user }) => user.email === email)?.workspace.id
        : undefined)()
  )

  return workspaceIdRef.current
}

export default canFastAutoSignInToDesktop
