import * as React from 'react'
function SvgSharingAccessTeam() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm1.5 0a3 3 0 11-6 0 3 3 0 016 0zm6.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm1.5 0a3 3 0 11-6 0 3 3 0 016 0zm5 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 1.5a3 3 0 100-6 3 3 0 000 6zm-16 .25c-.99 0-1.747.424-2.292 1.03-.525.584-.848 1.326-1.051 2.005a10.09 10.09 0 00-.356 1.86 10.839 10.839 0 00-.05.784v.05l-.001.014v.007h1.5v-.041l.005-.142a9.337 9.337 0 01.338-2.102c.172-.571.412-1.079.73-1.432.299-.332.667-.533 1.177-.533.52 0 .892.203 1.19.534.32.352.558.859.728 1.43a9.22 9.22 0 01.332 2.24v.042L7 18.5l.75.004v-.021a5.143 5.143 0 00-.005-.226 10.707 10.707 0 00-.39-2.47c-.201-.68-.523-1.424-1.05-2.008A3.015 3.015 0 004 12.75zm5.708 1.03c.545-.606 1.302-1.03 2.292-1.03.995 0 1.756.422 2.304 1.029.528.585.85 1.328 1.052 2.008a9.97 9.97 0 01.348 1.861 10.758 10.758 0 01.045.785V18.504L15 18.5l-.75-.004v-.042c0-.03 0-.079-.003-.141a9.255 9.255 0 00-.035-.523 8.477 8.477 0 00-.293-1.577c-.17-.57-.409-1.077-.727-1.43A1.518 1.518 0 0012 14.25c-.51 0-.878.201-1.177.533-.318.353-.558.86-.73 1.432a9.337 9.337 0 00-.343 2.243v.042h-1.5V18.428l.007-.175a10.831 10.831 0 01.4-2.469c.203-.678.526-1.42 1.051-2.005zM20 12.75c-.99 0-1.747.424-2.292 1.03-.525.584-.848 1.326-1.051 2.005a10.83 10.83 0 00-.406 2.644v.05l-.001.014v.007h1.5v-.041l.005-.142c.006-.125.016-.305.038-.524a8.59 8.59 0 01.3-1.578c.172-.571.412-1.079.73-1.432.299-.332.667-.533 1.177-.533.52 0 .892.203 1.19.534.32.352.558.859.728 1.43.167.562.251 1.136.293 1.576a9.255 9.255 0 01.039.664v.042l.75.004.75.004v-.021a3.847 3.847 0 00-.005-.226 10.758 10.758 0 00-.04-.609 9.97 9.97 0 00-.35-1.861c-.201-.68-.523-1.424-1.05-2.008A3.015 3.015 0 0020 12.75z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSharingAccessTeam
