import * as React from 'react'
function SvgUserFeedbackInterview() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 5.5V7h-3V5.5a1.5 1.5 0 013 0zM9.5 7V5.5a2.5 2.5 0 015 0V7H16v1H8V7h1.5zM8 5H6.75a.723.723 0 00-.531.219A.723.723 0 006 5.75v13.5c0 .208.073.385.219.531A.723.723 0 006.75 20h10.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V5.75c0-.234-.08-.377-.219-.531-.138-.154-.277-.219-.531-.219H16v1h1v13H7V6h1V5zm2.666 5.902c-.15.225-.166.555-.166 1.098h-1v-.058c0-.455-.002-1.09.334-1.594.387-.582 1.1-.848 2.166-.848 1.066 0 1.778.266 2.166.848.336.503.335 1.14.334 1.594V12c0 .77-.527 1.236-1.01 1.5-.325.18-.689.301-.99.386V15h-1v-1.89l.379-.095c.342-.086.78-.198 1.13-.39.351-.194.491-.394.491-.625 0-.543-.016-.873-.166-1.098-.113-.168-.4-.402-1.334-.402s-1.221.234-1.334.402zM12 17.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgUserFeedbackInterview
