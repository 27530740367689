import { useFela } from 'react-fela'
import * as React from 'react'
import { Style } from '../providers/style/types'

const defaultStyle = {
  display: 'flex',
}

export type TestableProps = {
  testID?: string
}

export type Props = TestableProps & {
  as?: React.ComponentType<any> | string
  style?: Style
  children?: any
  className?: string
  nativeProps?: Record<string, any>
  nativeRef?: React.Ref<any>
  [key: string]: any // accept any properties
}

const Stylo: React.FC<Props> = ({
  as: AsComponent = 'div',
  style = {},
  children,
  nativeProps,
  nativeRef,
  className = '',
  testID,
  ...restProps
}) => {
  const { css } = useFela(restProps)
  // @ts-ignore
  const generatedClassName = css(defaultStyle, style)

  return (
    <AsComponent
      className={
        className ? `${className} ${generatedClassName}` : generatedClassName
      }
      ref={nativeRef}
      {...nativeProps}
      data-testid={
        process.env.NODE_ENV === 'production' &&
        process.env.APP_ENV !== 'testing'
          ? undefined
          : testID
      }>
      {children}
    </AsComponent>
  )
}

export default Stylo
