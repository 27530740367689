import * as React from 'react'
import Stylo from '@packages/common/src/components/Stylo'
import { controlContext } from './Control'
import { Style } from '@packages/common/src/providers/style/types'

export type Props = {
  style?: Style
  autoComplete?: string
  value?: string
  pattern?: string
  type?: 'text' | 'email' | 'number' | 'password'
  step?: string | number
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>
}

const UnstyledSingleLineInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete,
      value,
      type = 'text',
      pattern,
      onKeyDown,
      onKeyPress,
      onBlur,
      onChange,
      onPaste,
      style,
      step,
      ...props
    },
    ref
  ) => {
    const ctx = React.useContext(controlContext)

    return (
      <Stylo
        as="input"
        ctx={ctx}
        style={style}
        testID={`${ctx.name}-field`}
        nativeRef={ref}
        nativeProps={{
          value,
          onBlur,
          onChange,
          onKeyDown,
          onKeyPress,
          onPaste,
          type,
          pattern,
          autoComplete,
          step,
          id: ctx.id,
          defaultValue: ctx.defaultValue,
          spellCheck: ctx.spellCheck,
          placeholder: ctx.placeholder,
          maxLength: ctx.maxLength,
          autoFocus: ctx.autoFocus,
          name: ctx.name,
          disabled: ctx.disabled,
          readOnly: ctx.readOnly,
        }}
        {...props}
      />
    )
  }
)

export default UnstyledSingleLineInput
