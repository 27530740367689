import * as React from 'react'
function SvgPlayVideoOutline() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16.502l7.969-4.691L7 7.395v9.107zM5 5.698V18.25a1 1 0 001.507.862l10.985-6.467a1 1 0 00-.023-1.737L6.485 4.823A1 1 0 005 5.698z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlayVideoOutline
