import * as React from 'react'
function SvgPlusBigBold() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12.206a.755.755 0 00-.755-.755h-4.196V7.255a.755.755 0 00-.755-.755h-.588a.755.755 0 00-.755.755v4.196H6.755a.755.755 0 00-.755.755v.588c0 .417.338.755.755.755h4.196v4.196c0 .417.338.755.755.755h.588a.755.755 0 00.755-.755v-4.196h4.196a.755.755 0 00.755-.755v-.588z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPlusBigBold
