import * as React from 'react'
import useAccountSelectorFeasibility from '@apps/accounts/src/features/Workspace/SignInPage/AccountSelector/use-account-selector-feasibility'

type Method = 'form' | 'select'

const signInMethodGetterCtx = React.createContext<Method>('form')
const signInMethodSetterCtx = React.createContext((_: Method) => {
  //
})

export const useSignInMethod = () => React.useContext(signInMethodGetterCtx)

export const useSignInMethodSwitch = () =>
  React.useContext(signInMethodSetterCtx)

export const SignInMethodProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isAccountSelectorFeasible = useAccountSelectorFeasibility()
  const [value, setValue] = React.useState<Method>(
    isAccountSelectorFeasible ? 'select' : 'form'
  )

  React.useEffect(() => {
    if (!isAccountSelectorFeasible && value === 'select') {
      setValue('form')
    }
  }, [isAccountSelectorFeasible, value])

  return (
    <signInMethodGetterCtx.Provider value={value}>
      <signInMethodSetterCtx.Provider value={setValue}>
        {children}
      </signInMethodSetterCtx.Provider>
    </signInMethodGetterCtx.Provider>
  )
}
