import * as React from 'react'
import Stylo, { TestableProps } from './Stylo'
import Text, { Props as TextProps } from './Text'
import Spinner from './Spinner'
import { Style } from '../providers/style/types'
import focusSelector from '@packages/common/src/themes/focus-selector'
import cmpStyles from '../providers/style/cmp-styles'

export type Props = TestableProps & {
  as: 'a' | 'button' | 'input' | 'span'
  scale?: -1 | 0
  fontWeight?: TextProps['weight']
  children?: React.ReactNode
  accent?: 'primary' | 'secondary' | 'error' | 'warning'
  isLoading?: boolean
  isReadOnly?: boolean
  style?: Style
  focusIntent?: 'keyboard' | 'any'
  align?: 'left' | 'center' | 'right'
  borderStyle?: 'dashed'
  nativeProps?: {
    onClick?: React.MouseEventHandler
    onAuxClick?: React.MouseEventHandler
    download?: any
    href?: string
    rel?: string
    target?: string
    type?: string
    readOnly?: boolean
    disabled?: boolean
    autoFocus?: boolean
  }
}

const styles = cmpStyles({
  facade: ({
    isReadOnly,
    scale,
    accent,
    focusIntent,
    align,
    borderStyle = 'solid',
    theme: {
      borderRadius,
      components: { Button: ButtonTheme },
    },
  }) => ({
    position: 'relative',
    display: 'flex',
    borderRadius: borderRadius.normal,
    borderWidth: 1,
    borderStyle,
    borderColor: 'transparent',
    textAlign: align,
    justifyContent: align,
    alignItems: 'center',
    textDecoration: 'none !important',
    paddingTop: scale < 0 ? 1 : 5,
    paddingBottom: scale < 0 ? 1 : 5,
    paddingLeft: scale < 0 ? 17 : 21,
    paddingRight: scale < 0 ? 17 : 21,
    cursor: 'pointer',
    pointerEvents: isReadOnly ? 'none' : undefined,
    // @ts-ignore
    ...ButtonTheme[accent],
    ':focus':
      focusIntent === 'any'
        ? // @ts-ignore
          ButtonTheme[accent][focusSelector]
        : undefined,
  }),
  text: ({ scale, isLoading }) => {
    const translate = scale < 0 ? -6 : -8

    return {
      color: 'inherit',
      transform: `translateX(${isLoading ? translate : 0}px)`,
      transition: `transform 150ms linear`,
      flexGrow: 1,
    }
  },
  loadingWrapper: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: ({ isLoading }) => ({
    pointerEvents: 'none',
    opacity: isLoading ? 1 : 0,
    alignSelf: 'center',
    position: 'absolute',
    right: 2,
    transition: `opacity 150ms linear`,
  }),
})

type SpinnerProps = {
  isLoading: boolean
  scale: number
}

const EnhancedSpinner = ({ isLoading, scale }: SpinnerProps) => {
  const [isPaused, setIsPaused] = React.useState(!isLoading)
  const isUnmounted = React.useRef(false)

  React.useEffect(
    () => () => {
      isUnmounted.current = true
    },
    []
  )

  React.useEffect(() => {
    if (isLoading) {
      setIsPaused(false)
    } else {
      setTimeout(() => {
        if (!isUnmounted.current) {
          setIsPaused(true)
        }
      }, 150)
    }
  }, [isLoading])

  return (
    <Stylo style={styles.spinner} isLoading={isLoading}>
      <Spinner scale={scale} isPaused={isPaused} />
    </Stylo>
  )
}

const ButtonFacade = ({
  as,
  scale = 0,
  fontWeight = 'black',
  accent = 'primary',
  isLoading,
  isReadOnly,
  children,
  testID,
  nativeProps,
  style,
  focusIntent,
  align = 'center',
  borderStyle,
}: Props) => (
  <Stylo
    testID={testID}
    as={as}
    scale={scale}
    accent={accent}
    isReadOnly={isReadOnly}
    focusIntent={focusIntent}
    borderStyle={borderStyle}
    style={style ? [styles.facade, style] : styles.facade}
    align={align}
    nativeProps={nativeProps}>
    {typeof children === 'string' ? (
      <Text
        scale={scale}
        weight={fontWeight}
        style={styles.text}
        isLoading={isLoading}>
        {children}
      </Text>
    ) : (
      children
    )}
    <EnhancedSpinner isLoading={!!isLoading} scale={scale ? -2 : 0} />
  </Stylo>
)

export default React.memo(ButtonFacade)
