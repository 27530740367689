import * as React from 'react'
function SvgLink() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.523 12.07a4.022 4.022 0 001.063 1.828c.375.375.802.667 1.281.875a.406.406 0 00.313 0 .286.286 0 00.187-.218c.063-.167.1-.334.11-.5.01-.167-.068-.282-.235-.344a2.826 2.826 0 01-.875-.594 2.892 2.892 0 01-.844-2.078c0-.802.282-1.495.844-2.078l2.5-2.469a2.755 2.755 0 012.063-.875 2.83 2.83 0 012.078.86c.573.572.86 1.265.86 2.078 0 .812-.292 1.5-.876 2.062l-2.344 2.375c-.083.083-.114.193-.093.328.02.136.03.281.03.438 0 .156.079.265.235.328.156.062.287.042.39-.063l2.595-2.593c.52-.521.87-1.13 1.047-1.828a4.192 4.192 0 000-2.094 3.902 3.902 0 00-1.047-1.828c-.521-.521-1.13-.87-1.828-1.047a4.192 4.192 0 00-2.094 0 3.902 3.902 0 00-1.828 1.047l-2.47 2.468c-.52.521-.874 1.13-1.062 1.829a3.967 3.967 0 000 2.093zm-.421 8.282a3.902 3.902 0 001.828-1.047l2.468-2.47c.521-.52.875-1.13 1.063-1.827a3.967 3.967 0 000-2.094 4.023 4.023 0 00-1.063-1.828 4.124 4.124 0 00-1.28-.875.407.407 0 00-.313 0 .286.286 0 00-.188.219 1.724 1.724 0 00-.11.5c-.01.166.069.28.235.343.333.146.625.344.875.594.563.583.844 1.276.844 2.078 0 .802-.281 1.495-.844 2.078l-2.5 2.47a2.756 2.756 0 01-2.062.874 2.831 2.831 0 01-2.078-.86 2.83 2.83 0 01-.86-2.077c0-.813.292-1.5.875-2.063l2.344-2.375c.083-.083.115-.193.094-.328a2.88 2.88 0 01-.032-.438c0-.156-.078-.265-.234-.328-.156-.062-.286-.041-.39.063L5.18 13.555c-.521.52-.87 1.13-1.047 1.828a4.192 4.192 0 000 2.094 3.902 3.902 0 001.047 1.828c.52.52 1.13.87 1.828 1.047a4.192 4.192 0 002.094 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgLink
