import * as React from 'react'
function SvgListCheck() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.844 12.04a.245.245 0 01.156.226v2.484A2.25 2.25 0 0114.75 17h-6.5A2.25 2.25 0 016 14.75v-6.5A2.25 2.25 0 018.25 6h6.5c.313 0 .625.063.914.195.07.032.125.102.14.18a.245.245 0 01-.07.227l-.382.382a.27.27 0 01-.18.079c-.024 0-.047-.008-.07-.016A1.36 1.36 0 0014.75 7h-6.5C7.562 7 7 7.563 7 8.25v6.5c0 .688.563 1.25 1.25 1.25h6.5c.688 0 1.25-.563 1.25-1.25v-1.984c0-.063.023-.125.07-.172l.5-.5a.25.25 0 01.18-.078c.031 0 .063.007.094.023zm1.663-3.987a.75.75 0 00-1.014-1.106l-5.47 5.015L10.03 9.97a.75.75 0 10-1.06 1.06l2.364 2.365a.95.95 0 001.314.029l5.859-5.371z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgListCheck
