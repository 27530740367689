import * as React from 'react'
function SvgWindowsShellRestore() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 6H8v2H6v10h10v-2h2V6H8.5zm7.5 9h1V7H9v1h7v7zm-1.001-5.999H7.001v7.998h7.998V9.001z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgWindowsShellRestore
