import * as React from 'react'
function SvgGlobeLocked() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5.833V6.5h1v6h-3.884c.056.359.085.726.085 1.1 0 3.915-3.185 7.1-7.1 7.1C7.185 20.7 4 17.515 4 13.6c0-3.915 3.185-7.1 7.1-7.1 1.44 0 2.78.43 3.9 1.17V6.5h1v-.667c0-1.28 1.25-1.333 2.5-1.333s2.5.052 2.5 1.333zM17 6.5h3v-.667c0-.333-.397-.333-1.499-.333C17.4 5.5 17 5.5 17 5.833V6.5zm-2 2.472a6.061 6.061 0 00-1.976-1.113c-.149.18-.259.355-.249.47.113 1.125.679.884 1.087.71.18-.076.329-.14.394-.067.348.386.08.657-.241.983-.384.389-.845.855-.43 1.687.37.735.794.94 1.414.949.62.01.85 1.068.898 1.508.052.44-.007 1.57-.23 2.058-.223.485.014.953.235 1.126a6.03 6.03 0 001.155-4.783H15V8.972zm-5.185-.971c.181-.11.364-.22.454-.397a6.074 6.074 0 00-4.82 3.825c.07.008.156.014.251.021.509.039 1.286.098 1.399.416.064.178-.037.36-.161.585-.14.253-.311.56-.311.977 0 .47.322.685.621.885.26.173.502.335.502.64 0 .419.097.882.169 1.22.04.196.073.35.073.43 0 .22.937 1.432 1.286 1.432.248 0 .179-.695.126-1.222-.02-.208-.039-.39-.034-.493.01-.232.166-.943.211-1.109.172-.648.614-.888 1.01-1.103.295-.16.565-.307.676-.6.702-1.862-1.107-2.206-1.835-2.345-.114-.021-.201-.038-.248-.055-.346-.12-.936-.125-1.16-.051-.222.072-.67-.302-.67-.62 0-.184.295-.472.597-.767.221-.217.447-.437.563-.623.157-.254.296-.34.483-.455a3.17 3.17 0 00.512-.378c.088-.08.197-.147.306-.213zm-.62 11.346c.152-.08.247-.17.33-.248.116-.11.209-.198.401-.206.248-.01.466-.07.707-.137.126-.035.257-.072.403-.104.142-.03.326-.104.532-.186.341-.137.744-.297 1.114-.328.502-.04 1.49.026 1.757.51a6.012 6.012 0 01-5.243.699zM16 7.5v4h5v-4h-5zm3 3v-2h-1v2h1z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgGlobeLocked
