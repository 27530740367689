import React from 'react'

export type AvatarLoadingStatus = 'idle' | 'loading' | 'loaded' | 'error'

const avatarStatusCtxGetter = React.createContext<AvatarLoadingStatus>('idle')
const avatarStatusCtxSetter = React.createContext<
  (_: AvatarLoadingStatus) => void
>(() => {
  //
})

export const useAvatarStatus = () => React.useContext(avatarStatusCtxGetter)

export const useAvatarStatusSetter = () =>
  React.useContext(avatarStatusCtxSetter)

export const AvatarStatusProvider = ({
  children,
  initState = 'idle',
}: {
  children: React.ReactNode
  initState?: 'idle' | 'loading'
}) => {
  const [status, setStatus] = React.useState<AvatarLoadingStatus>(initState)

  return (
    <avatarStatusCtxGetter.Provider value={status}>
      <avatarStatusCtxSetter.Provider value={setStatus}>
        {children}
      </avatarStatusCtxSetter.Provider>
    </avatarStatusCtxGetter.Provider>
  )
}
