import * as React from 'react'
function SvgItemTagAdd() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2h2v3h3v2H6v3H4V7H1V5h3V2zm1.266 14.096V12h1v4.096a1 1 0 001 1h10.16a1 1 0 00.778-.372l3.409-4.216a1 1 0 000-1.257l-3.409-4.217a1 1 0 00-.777-.371H11v-1h6.427a2 2 0 011.555.743l3.408 4.216a2 2 0 010 2.514l-3.408 4.217a2 2 0 01-1.555.743H7.267a2 2 0 01-2-2zm12.23-3.19a1.047 1.047 0 01-1.054-1.04c0-.575.472-1.04 1.054-1.04.582 0 1.054.465 1.054 1.04 0 .574-.472 1.04-1.054 1.04z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgItemTagAdd
