import * as React from 'react'
function SvgBrandFacebook() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.008 22v-8.945H7V9.5h3.008V6.687c0-1.484.417-2.636 1.25-3.457.833-.82 1.94-1.23 3.32-1.23 1.12 0 2.031.052 2.735.156V5.32h-1.875c-.704 0-1.185.157-1.446.469-.208.26-.312.677-.312 1.25V9.5H17l-.469 3.555H13.68V22h-3.672z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBrandFacebook
