import * as React from 'react'
function SvgLeadUnassigned() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={12}
        cy={12.5}
        r={11.5}
        stroke="currentColor"
        strokeDasharray="3 2"
      />
      <circle cx={12} cy={10.5} r={3.5} stroke="currentColor" />
      <path
        d="M17 22.5c0-4.418-1.548-8-5-8s-5 3.582-5 8"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default SvgLeadUnassigned
