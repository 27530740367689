import * as React from 'react'
function SvgSpecificationsInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5a2 2 0 00-2 2v1h6V5H5zm5 0v3h5V5h-5zm6 0v3h6V7a2 2 0 00-2-2h-4zm6 4h-6v10h4a2 2 0 002-2V9zm-7 10V9h-5v10h5zm-6 0V9H3v8a2 2 0 002 2h4z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSpecificationsInverted
