import * as React from 'react'
function SvgPanelBrowser() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.914 12.273c0 .118-.07.22-.14.305l-2.297 2.836c-.266.328-.813.586-1.227.586h-8.5c-.172 0-.414-.055-.414-.273 0-.118.07-.22.14-.313l2.297-2.836C9.04 12.258 9.586 12 10 12h8.5c.172 0 .414.055.414.273zM10 11c-.71 0-1.547.398-2 .953l-2 2.461V7.75A.75.75 0 016.75 7h2.5a.75.75 0 01.75.75v.5c0 .414.336.75.75.75h4.5a.75.75 0 01.75.75V11h-6zm9.914 1.273c0-.187-.039-.367-.117-.53-.235-.5-.766-.743-1.297-.743H17V9.75C17 8.79 16.211 8 15.25 8H11v-.25C11 6.79 10.21 6 9.25 6h-2.5C5.79 6 5 6.79 5 7.75v7.5c0 .96.79 1.75 1.75 1.75h8.5c.703 0 1.555-.406 2-.953l2.305-2.836c.21-.266.36-.594.36-.938z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelBrowser
