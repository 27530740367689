import * as React from 'react'
function SvgCommentCountTwo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.65 7.5A1.65 1.65 0 0019 5.85H7.5A1.65 1.65 0 005.85 7.5v1.35H5a1.65 1.65 0 00-1.65 1.65v6c0 .911.739 1.65 1.65 1.65h1.35v3.65l4.867-3.65H16a1.65 1.65 0 001.65-1.65v-1.35H19a1.65 1.65 0 001.65-1.65v-6zm-3 6.35H19a.35.35 0 00.35-.35v-6a.35.35 0 00-.35-.35H7.5a.35.35 0 00-.35.35v1.35H16c.911 0 1.65.739 1.65 1.65v3.35zm-13-3.35a.35.35 0 01.35-.35h11a.35.35 0 01.35.35v6a.35.35 0 01-.35.35h-5.217L7.65 19.2v-2.35H5a.35.35 0 01-.35-.35v-6z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCommentCountTwo
