import * as React from 'react'
function SvgFullScreen() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.841 13.557l1.112 1.112 2.167-2.168 1.236 1.236-2.168 2.167 1.112 1.112c.16.154.195.332.108.532-.088.206-.24.31-.456.31H6.494a.475.475 0 01-.347-.148.475.475 0 01-.147-.347v-3.458c0-.216.1-.368.301-.456.211-.087.391-.051.54.108zm6.896-2.201l2.167-2.168 1.112 1.112a.475.475 0 00.532.108c.206-.088.31-.24.31-.456V6.494a.475.475 0 00-.148-.347.475.475 0 00-.347-.147h-3.458c-.216 0-.368.103-.456.309-.087.2-.051.378.108.532l1.112 1.112-2.168 2.167 1.236 1.236z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFullScreen
