import * as React from 'react'
function SvgMediaEmbedInsertCenter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H6v2h12V4zM6 8h12v8H6V8zm0 10h12v2H6v-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgMediaEmbedInsertCenter
