import * as React from 'react'
import { Field, FieldProps, FieldAttributes, FastField } from 'formik'
import Control, {
  Props as ControlProps,
} from '@packages/common/src/components/TextInput/pure/Control'

type Props = Omit<ControlProps, 'withError'> & {
  validate?: FieldAttributes<any>['validate']
  memoField?: boolean
}

const FormControl = ({ name, validate, memoField, ...props }: Props) => {
  const FieldComponent = memoField ? FastField : Field
  return (
    <FieldComponent name={name} validate={validate}>
      {({ field, meta }: FieldProps) => (
        <Control
          {...props}
          name={field.name}
          withError={meta.touched && !!meta.error}
        />
      )}
    </FieldComponent>
  )
}

export default FormControl
