import * as React from 'react'
function SvgFilter() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm.95.5H20V6h-3.05a2.5 2.5 0 00-4.9 0H4v1h8.05a2.5 2.5 0 004.9 0zM20 11h-9.05a2.5 2.5 0 00-4.9 0H4v1h2.05a2.5 2.5 0 004.9 0H20v-1zM8.5 13a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm4.55 3H4v1h9.05a2.5 2.5 0 004.9 0H20v-1h-2.05a2.5 2.5 0 00-4.9 0zm3.95.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilter
