import * as React from 'react'
function SvgPanelSharingInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.82 10.195c.521.521.886 1.13 1.094 1.828a4.39 4.39 0 01.11 2.11 4.232 4.232 0 01-.891 1.906c-.104.125-.552.583-1.344 1.375l-1.313 1.313a4.29 4.29 0 01-1.968 1.14c-.75.198-1.5.198-2.25 0a4.29 4.29 0 01-1.969-1.14 4.29 4.29 0 01-1.14-1.97 4.337 4.337 0 010-2.25 4.291 4.291 0 011.14-1.968l1.969-1.969c.125-.125.265-.156.422-.094a.343.343 0 01.234.344c0 .396.052.792.156 1.188.021.125-.02.24-.125.344L6.54 13.788c-.52.5-.781 1.115-.781 1.844 0 .729.255 1.349.765 1.86.51.51 1.13.765 1.86.765.729 0 1.344-.26 1.844-.781l2.374-2.344c.5-.5.75-1.115.75-1.844 0-.73-.25-1.354-.75-1.875a2.475 2.475 0 00-.718-.5c-.167-.083-.24-.208-.219-.375.042-.375.198-.698.469-.969l.125-.125c.146-.125.292-.146.437-.062.417.208.792.479 1.125.812zm4.907-4.906a4.29 4.29 0 011.14 1.969c.198.75.198 1.5 0 2.25a4.29 4.29 0 01-1.14 1.969l-1.97 1.968c-.124.125-.265.156-.421.094a.342.342 0 01-.234-.344c0-.396-.053-.791-.157-1.187-.02-.125.021-.24.125-.344l1.407-1.438c.52-.5.78-1.114.78-1.843 0-.73-.254-1.35-.765-1.86a2.532 2.532 0 00-1.86-.765c-.728 0-1.343.26-1.843.781l-2.375 2.344c-.5.5-.75 1.114-.75 1.844 0 .729.25 1.354.75 1.874.208.209.448.375.719.5.167.084.24.209.219.375a1.581 1.581 0 01-.47.97l-.124.124c-.146.125-.292.146-.438.063a4.303 4.303 0 01-1.125-.813 4.28 4.28 0 01-1.093-1.828 4.39 4.39 0 01-.11-2.11 4.232 4.232 0 01.89-1.905c.105-.125.553-.584 1.345-1.375l1.312-1.313a4.291 4.291 0 011.969-1.14c.75-.198 1.5-.198 2.25 0a4.29 4.29 0 011.969 1.14z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgPanelSharingInverted
