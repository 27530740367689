import * as React from 'react'
function SvgFilesSourcesInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.325 12.12a.5.5 0 01.055.705L12 16.768l-3.38-3.943a.5.5 0 11.76-.65l2.12 2.473V6.5a.5.5 0 111 0v8.148l2.12-2.473a.5.5 0 01.705-.055zM8.71 18.517A6 6 0 017.815 9.2a.5.5 0 00-.697-.717 7 7 0 109.602-.153.5.5 0 10-.675.739 6 6 0 01-7.336 9.448z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgFilesSourcesInverted
