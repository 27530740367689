import * as React from 'react'
function SvgProductUserInsights() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 11a2.5 2.5 0 100 5 2.5 2.5 0 100-5zm0 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-9.5-1.5a2.5 2.5 0 105 0 2.5 2.5 0 10-5 0zm4 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM5 16a2.5 2.5 0 10.001-4.999A2.5 2.5 0 005 16zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm14.625 5h-1.25C17.065 17 16 18.122 16 19.5c0 .275.225.5.5.5s.5-.225.5-.5c0-.828.619-1.5 1.375-1.5h1.25c.756 0 1.375.672 1.375 1.5 0 .275.225.5.5.5s.5-.225.5-.5c0-1.378-1.066-2.5-2.375-2.5zm-14 0h-1.25C3.065 17 2 18.122 2 19.5c0 .275.225.5.5.5s.5-.225.5-.5c0-.828.619-1.5 1.375-1.5h1.25C6.381 18 7 18.672 7 19.5c0 .275.225.5.5.5s.5-.225.5-.5C8 18.122 6.934 17 5.625 17zm7 0h-1.25C10.065 17 9 18.122 9 19.5c0 .275.225.5.5.5s.5-.225.5-.5c0-.828.619-1.5 1.375-1.5h1.25c.756 0 1.375.672 1.375 1.5 0 .275.225.5.5.5s.5-.225.5-.5c0-1.378-1.066-2.5-2.375-2.5zM4 5.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v4.663c.36.108.7.261 1 .472V5.5c0-.827-.673-1.5-1.5-1.5h-15C3.673 4 3 4.673 3 5.5v5.135c.3-.21.64-.363 1-.472V5.5z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgProductUserInsights
