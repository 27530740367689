import * as React from 'react'
function SvgInputFieldError() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.293 8.293a1 1 0 000 1.414l2.596 2.596L8.293 14.9a1 1 0 101.414 1.414l2.596-2.596 2.597 2.596a1 1 0 001.414-1.414l-2.596-2.597 2.596-2.596A1 1 0 0014.9 8.293l-2.597 2.596-2.596-2.596a1 1 0 00-1.414 0z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgInputFieldError
