import * as React from 'react'
function SvgBoldItalic() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.424 8.435c0-.58-.185-1.005-.554-1.275-.363-.27-.844-.404-1.441-.404-.117 0-.255.006-.413.017-.159.006-.282.012-.37.018l-1.09 4.693h.59c1.007 0 1.804-.278 2.39-.835.592-.562.888-1.3.888-2.214zm-.686 5.915c0-.674-.216-1.19-.65-1.547-.434-.364-1.075-.545-1.925-.545-.117 0-.264.006-.44.017-.17.006-.287.012-.35.018l-1.073 4.64c.006.217.167.402.483.554a2.4 2.4 0 001.055.229c.78 0 1.456-.314 2.03-.94.58-.628.87-1.436.87-2.426zM14.501 6c1.506 0 2.61.188 3.313.563.71.369 1.064.937 1.064 1.705 0 .51-.114.958-.343 1.344a3.07 3.07 0 01-.887.976c-.37.27-.797.495-1.284.677-.48.181-.978.325-1.494.43l-.035.167c.404.041.814.13 1.23.264.422.129.783.296 1.081.501.323.217.578.48.765.791.188.31.281.688.281 1.134 0 1.283-.539 2.259-1.617 2.927-1.072.662-2.657.993-4.755.993H5l.15-.642c.146-.006.363-.026.65-.061.293-.041.51-.085.65-.132a1.05 1.05 0 00.519-.343 1.3 1.3 0 00.255-.536l2.012-8.719c.03-.123.05-.226.062-.308.012-.082.017-.143.017-.184 0-.246-.126-.437-.377-.571-.247-.141-.65-.252-1.213-.334L7.874 6h6.627z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgBoldItalic
