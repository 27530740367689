import * as React from 'react'
function SvgArchive1() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4a.5.5 0 01.5.5v6.793l1.646-1.647a.5.5 0 01.708.708l-2.5 2.5-.354.353-.354-.353-2.5-2.5a.5.5 0 01.708-.708L12 11.293V4.5a.5.5 0 01.5-.5zm-2.125 3.5H8.453a1.45 1.45 0 00-.63.146 1.107 1.107 0 00-.483.44l-3.076 4.19a1.459 1.459 0 00-.264.849v4.219c0 .39.137.722.41.996.274.273.606.41.996.41H19.47c.39 0 .722-.137.996-.41.273-.274.41-.606.41-.996v-4.219c0-.313-.088-.596-.264-.85l-3.076-4.19a1.107 1.107 0 00-.483-.439 1.45 1.45 0 00-.63-.146h-1.797v.938h1.797a.38.38 0 01.351.205l2.608 3.544h-4.014l-.937 1.876h-3.985l-.937-1.876H5.494l2.608-3.544a.38.38 0 01.351-.206h1.922V7.5zm9.43 5.757c.088.088.133.2.133.337v3.75a.456.456 0 01-.132.337.456.456 0 01-.337.131H5.406a.456.456 0 01-.337-.131.456.456 0 01-.131-.337v-3.75c0-.137.043-.25.131-.337a.456.456 0 01.337-.132h3.516L9.859 15h5.157l.937-1.875h3.516c.136 0 .249.044.337.132z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgArchive1
