import * as React from 'react'
function SvgChatGptInverted() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 6h10a2 2 0 012 2v6a2 2 0 01-2 2H8.5L5 20V8a2 2 0 012-2z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgChatGptInverted
