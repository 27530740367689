import * as React from 'react'
function SvgDownload2() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 4.5a.75.75 0 00-1.5 0v9.69l-2.72-2.72a.75.75 0 00-1.06 1.06l4 4 .53.53.53-.53 4-4a.75.75 0 10-1.06-1.06l-2.72 2.72V4.5zM6 19.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgDownload2
